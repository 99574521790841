import React from 'react';
import Images from '../components/images';
import JellyBoxLoader from '../views/JellyBoxLoader';

export default class PitchViewLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div className="pitch-view">
                <div className="singlefield">
                    <i className="sponsor-space"></i>
                    <i className="sponsor-space right"></i>
                    <i className="goal"></i>
                    <JellyBoxLoader />
                </div>
                {/* <div className="bench-view"> </div> */}
            </div>
        )
    }
}