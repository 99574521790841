/* eslint-disable eqeqeq */
import React from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import _ from "lodash";
import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import DeadlineClock from '../views/DeadlineClock';

import { MyContext } from '../context';

export default class ConfirmTransfer extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            PlayerList: [],
            TransferPlayerData: [],
            posting: false
        };
    }
    

    CancelWildcard = (status) => {
        this.props.ApplyWildcardAction(status);
        this.props.modalHide('ConfirmTransferModalShow');
    }
    ConsumeFreeTransfers = (val) => {
        let TransferPlayerOut = this.props.TransferPlayerOut;
        let ConsumeFreeransfer = 0;
        if (val >= TransferPlayerOut.length) {
            ConsumeFreeransfer = TransferPlayerOut.length
        } else {
            ConsumeFreeransfer = val
        }
        
        if((this.props.team_info.free_hit_booster_status.is_applied == 1 && Number(this.props.team_info.free_hit_booster_status.applied_week) == Number(this.props.next_week)) || this.props.team_info.wildcard_status.wildcard_used_in_current_week == 1) return this.props.locale.MyTeam.unlimited;
        return ConsumeFreeransfer;
    }

    transferSubmit = () => {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "lineup_master_id": this.props.team_info.lineup_master_id,
            "transfer": this.state.TransferPlayerData,
            "apply_wildcard": this.props.apply_wildcard ? '1' : '0'
        }
        this.setState({ posting: true });
        WSManager.Rest(API.PROCESS_PLAYER_TRANSFER, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.props.history.push('/my-team');
            }
            this.setState({ posting: false });
        })
    }

    componentDidMount() {
        
        let PlayerList = this.state.PlayerList
        let TransferPlayerIn = this.props.TransferPlayerIn;
        let TransferPlayerOut = this.props.TransferPlayerOut;
        let TransferPlayerData = [];

        let positionArr = [NC.GOALKEEPER, NC.DEFENDER, NC.MIDFIELDER, NC.FORWARDER];


        for (let i = 0; i < positionArr.length; i++) {
            let element = positionArr[i];
            let filterArrIn = _.filter(TransferPlayerIn, function (o) { return element == o.position; });
            let filterArrOut = _.filter(TransferPlayerOut, function (o) { return element == o.position; });

            if (filterArrOut.length == 0 || filterArrIn.length == 0) {
                continue;
            }

            _.map(filterArrIn, (Player, idx) => {
                let player_obj = {
                    "out_player_id": filterArrOut[idx].player_uid,
                    "out_player_pos": filterArrOut[idx].position,
                    "out_player_sal": filterArrOut[idx].salary,
                    "out_player_team_id": filterArrOut[idx].player_team_id,

                    "in_player_id": Player.player_uid,
                    "in_player_pos": Player.position,
                    "in_player_sal": Player.salary,
                    "in_player_team_id": Player.player_team_id
                }
                TransferPlayerData.push(player_obj)
                PlayerList.push({
                    "in": Player,
                    "out": filterArrOut[idx]
                })
            })

        }
        this.setState({
            PlayerList: PlayerList,
            TransferPlayerData: TransferPlayerData,
        });
    }


    render() {
        const {
            posting,
            PlayerList
        } = this.state;

        const {
            team_info,
            TransfersCast,
            _availableBudget,
            LineupsList,
            TransferPlayerIn,
            next_week_start_time,
            next_week,
            // apply_wildcard
        } = this.props;

        const DeadlineClockProps = {
            date: next_week_start_time,
            gameweek: next_week
        }
        return (
            <MyContext.Consumer>

                {(context) => (

                    <Modal
                        show={this.props.ConfirmTransferModalShow}
                        onHide={() => this.props.modalHide('ConfirmTransferModalShow')}
                        dialogClassName="custom-modal confirm-transfers-modal"
                    >
                        <Modal.Header closeButton className='custom-modal-header'>
                            <div className="title">{context.state.locale.confirm_transfer.hero_title}</div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="deadline-clock-wrapper">
                                <DeadlineClock {...DeadlineClockProps}  {...context}  />
                            </div>
                            <div className="transfer-count-wrap">
                                <div className="title">{context.state.locale.confirm_transfer.transfer_count_text}</div>
                                <div className="content">
                                    <span className="text">{TransferPlayerIn.length} </span>
                                    {context.state.locale.confirm_transfer.player_text}
                            </div>
                            </div>

                            <div className="transfer-table-header">
                                <div className="cell">
                                    <i className="icon-nextArrow"></i>{context.state.locale.confirm_transfer.transfer_out}
                            </div>
                                <div className="cell right">
                                    <i className="icon-nextArrow"></i>{context.state.locale.confirm_transfer.transfer_in}
                            </div>
                            </div>
                            <div className="transfer-table">
                                {
                                    _.map(PlayerList, (item, idx) => {
                                        return (
                                            <div className="row" key={idx}>
                                                <div className="cell">
                                                    <figure className="thumb">
                                                        <img src={item['out'].jersey} alt="" />
                                                    </figure>
                                                    <div className="overflow">
                                                        <div className="name">{item['out'].full_name}</div>
                                                        <div className="team-name">{item['out'].team_abbreviation}</div>
                                                    </div>
                                                </div>
                                                <div className="cell right">
                                                    <figure className="thumb">
                                                        <img src={item['in'].jersey} alt="" />
                                                    </figure>
                                                    <div className="overflow">
                                                        <div className="name">{item['in'].full_name}</div>
                                                        <div className="team-name">{item['in'].team_abbreviation}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="transfer-disclaimer">{context.state.locale.confirm_transfer.transfer_disclaimer_start} {next_week} {context.state.locale.confirm_transfer.transfer_disclaimer_end} </div>
                            <div className="transfer-table-header">
                                <div className="cell full-width">
                                {context.state.locale.confirm_transfer.transfer_table_header}
                            </div>
                            </div>
                            <div className="transfer-point-tbl">
                                {/* {
                                    !apply_wildcard &&
                                    <Row>
                                        <Col sm={8}>{context.state.locale.confirm_transfer.free_transfers_used}</Col>
                                        <Col sm={4} className='text-right text-black'>{this.ConsumeFreeTransfers(team_info.available_transfer)}</Col>
                                    </Row>
                                }
                                {
                                    !apply_wildcard &&
                                    <Row>
                                        <Col sm={8}>{context.state.locale.confirm_transfer.additional_transfers_used}</Col>
                                        <Col sm={4} className='text-right text-black'>{TransfersCast(team_info.available_transfer)} {context.state.locale.PlayerCard.pts}</Col>
                                    </Row>
                                }
                                {
                                    apply_wildcard &&
                                    <div className="alert alert-warning text-center">
                                        {context.state.locale.confirm_transfer.transfer_wildcard_text_start} <br /> {context.state.locale.confirm_transfer.transfer_wildcard_text_end} <br />
                                        <Button className='btn-outline-danger btn-sm m-t-sm' onClick={() => this.CancelWildcard(apply_wildcard)}>{context.state.locale.confirm_transfer.cancel_wildcard}</Button>
                                    </div>
                                } */}
                                <Row>
                                    <Col sm={8}>{context.state.locale.confirm_transfer.free_transfers_used}</Col>
                                    <Col sm={4} className='text-right text-black'>{this.ConsumeFreeTransfers(team_info.available_transfer)}</Col>

                                </Row>
                                <Row>
                                    <Col sm={8}>{context.state.locale.confirm_transfer.additional_transfers_used}</Col>
                                    <Col sm={4} className='text-right text-black'>{TransfersCast(team_info.available_transfer)} {context.state.locale.PlayerCard.pts}</Col>
                                </Row>

                                <Row>
                                    <Col sm={8}>{context.state.locale.confirm_transfer.available_budget}</Col>
                                    <Col sm={4} className='text-right text-black'>{context.state.locale.confirm_transfer.currency_label} {_availableBudget(LineupsList)}{NC.SALARY_UNIT}</Col>
                                </Row>
                            </div>

                        </Modal.Body>
                        <Modal.Footer className='custom-modal-footer'>
                            <Button bsStyle="primary" disabled={posting} onClick={() => this.transferSubmit()}>{context.state.locale.confirm_transfer.confirm_transfers}</Button>
                        </Modal.Footer>
                    </Modal>

                )}
            </MyContext.Consumer>
        );
    }
}