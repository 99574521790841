import validator from 'validator';

export default class Validation {
    static validate(type, value) {
        let isValidate = null;     
        if (value.length > 0) {
            switch (type) {
                case 'required':
                    isValidate = validator.isEmpty(value) ? 'error' : 'success';
                    break;
                case 'email':
                    isValidate = validator.isEmail(value) ? 'success' : 'error';
                    break;
                case 'password':
                    isValidate = value.length >= 8 ? 'success' : 'error';
                    break;
                case 'first_name':
                case 'last_name':
                    isValidate = validator.isAlpha(value, 'ar') || validator.isAlpha(value) ? 'success' : 'error';
                    break;
                case 'team_name':
                    isValidate = value.length >= 4 ? 'success' : 'error';
                    break;
                case 'user_name':
                    isValidate = validator.blacklist(value, '_.-') &&  value.length >=3 && (value.match(/^[a-zA-Z]*[a-zA-Z0-9]*[a-zA-Z0-9_.-]*$/) || (validator.isAlphanumeric(value, 'ar') || validator.isAlphanumeric(value))) ? 'success' : 'error';
                    break;
                case 'phone_no':
                    isValidate = value.length >= 3 ? 'success' : 'error';
                    // isValidate = value.match(/([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})/gm) ? 'success' : 'error';
                    // isValidate = value.match(/^(?:(\+)?([0-9]{1,3})?[-.● ]?)?\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.● ]?([0-9]{4})$/) ? 'success' : 'error';
                    break;
                default:
                    break;
            }            
        }
        return isValidate;
    }
}