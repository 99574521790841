/* eslint-disable jsx-a11y/anchor-is-valid, no-sequences, eqeqeq */
import React from 'react';
import { Row, Col, Button, Modal, FormGroup } from 'react-bootstrap';
import FloatingLabel from 'floating-label-react';
import { notify } from 'react-notify-toast';
import { inputStyle } from '../helper/input-style';
import Validation from './../helper/validation';
import GoogleLogin from 'react-google-login';
import Images from '../components/images';
import WSManager from "../helper/WSManager";
import { API, Auth } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import { SocialLogin } from './../components';
import { MyContext } from '../context';
import { gapi } from 'gapi-script';
const md5 = require('md5');

export default class Login extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            email: '',
            password: '',
            formValid: false,
            posting: false,
            isShowPassword: false,
            showVerifyText : false,
        };
    }



    getValidationState(type, value) {
        return Validation.validate(type, value)
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, this.validateForm);
    }
    validateForm() {
        this.setState({ formValid: Validation.validate('email', this.state.email) == 'success' && Validation.validate('password', this.state.password) == 'success' });
    }
    refreshPage() {

        window.location.reload();
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ posting: true });
        let param = {
            "email": this.state.email,
            "password": md5(this.state.password),
            "device_type": NC.deviceType,
            "device_id": NC.deviceID,
            "remember_me": this.state.remember_me
        }
        WSManager.Rest(API.login, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {

                // setTimeout(() => {
                    Auth.setAuth(responseJson.data.session_key)
                    WSManager.setProfile(responseJson.data.user_profile);
                    WSManager.setMultiLeg(true);
                    this.setState({ posting: false });
                    this.props.loggedInStatusUpdate();
                    this.props.modalHide('LoginModalShow');
                    this.refreshPage();    
                // }, 2000);
                
            } 
            else if(responseJson.response_code === NC.verifyError && responseJson.data.email_verified == 0){
                this.setState({
                    showVerifyText : true
                })

            }
            else {
                this.setState({ posting: false });
            }
        })
    }
    showPassword = () => {
        this.setState({
            isShowPassword: !this.state.isShowPassword
        })
    }

    onFacebookSuccess = (user) => {
        if (user) {
            let param = {
                "email": user._profile.email,
                "facebook_id": user._profile.id,
                "facebook_access_token": user._token.accessToken,
                "password": '',
                "device_type": NC.deviceType,
                "device_id": NC.deviceID,
            }

            WSManager.Rest(API.login, param).then((responseJson) => {
                if (responseJson.response_code === NC.successCode) {
                    Auth.setAuth(responseJson.data.session_key)
                    WSManager.setProfile(responseJson.data.user_profile);
                    WSManager.setMultiLeg(true);
                    this.setState({ posting: false });
                    this.props.loggedInStatusUpdate();
                    this.props.modalHide('LoginModalShow');
                    this.refreshPage();
                } else {
                    this.setState({ posting: false });
                }
            })
        }
    }

    onLoginFailure(err) {
        console.error(err)
    }
componentDidMount(){
    gapi.load('client:auth2',()=>{
        gapi.auth2.init({clientId:NC.GPLUS_ID})
    })
}
    responseGoogle = (googleUser, isSuccess) => {
        // console.log("Google Auth Response", googleUser,isSuccess);
        if (googleUser) {
            var id_token = googleUser.getAuthResponse().id_token;
            var googleId = googleUser.getId();


            let param = {
                "email": googleUser.profileObj.email,
                "google_id": googleId,
                "google_access_token": id_token,
                "password": '',
                "device_type": NC.deviceType,
                "device_id": NC.deviceID,
            }

            WSManager.Rest(API.login, param).then((responseJson) => {
                if (responseJson.response_code === NC.successCode) {
                    Auth.setAuth(responseJson.data.session_key)
                    WSManager.setProfile(responseJson.data.user_profile);
                    WSManager.setMultiLeg(true);
                    this.setState({ posting: false });
                    this.props.loggedInStatusUpdate();
                    this.props.modalHide('LoginModalShow');
                    this.refreshPage();
                    // setTimeout(() => {
                    //     this.refreshPage();
                    // }, 500);
                } else {
                    this.setState({ posting: false });
                }
            })
        }

    }

    /**
     * RESEND VERIFICATION FUNCTION 
     */

     resendVerificationMail = ()=>{
            this.setState({ posting: true });
            let param = {
                "email": this.state.email,
            }
            WSManager.Rest(API.resend_verification_code, param).then((responseJson) => {
                if (responseJson.response_code === NC.successCode) {
                   this.setState({
                       showVerifyText : false,
                       posting : false
                   })
                   notify.show(responseJson.message, "success", 5000);
                }
                else {
                    this.setState({ posting: false });
                }
            })
        
     }

    render() {
        const {
            email,
            password,
            formValid,
            posting,
            isShowPassword
        } = this.state;
        const inputStyleUpdate = {
            ...inputStyle,
            input: { ...inputStyle.input, background: 'transparent' }
        }
        return (
            <MyContext.Consumer>
                {(context) => (

                    <Modal
                        show={this.props.LoginModalShow}
                        onHide={() => this.props.modalHide('LoginModalShow')}
                        dialogClassName="custom-modal"
                    >
                        <Modal.Header closeButton className='custom-modal-header'>
                            <div className="header-link"><span>{context.state.locale.Login.not_a_member}</span> <span className='link' onClick={() => (this.props.modalHide('LoginModalShow'), this.props.modalShow('SignupModalShow'))}>{context.state.locale.Login.sign_up_label}</span></div>
                            <div className="title">{context.state.locale.Login.hero_title}</div>
                        </Modal.Header>
                        <form onSubmit={this.onSubmit}>
                            <Modal.Body>
                                {/* SOCIAL(s) */}
                                {
                                    !NC.SocialLoginDisable &&
                                    <div className="socail-region">
                                        <div className="title">{context.state.locale.Login.social_text}</div>
                                        <div className="content">
                                            <div className="social-item">
                                                <SocialLogin {...this.props} onFacebookSuccess={this.onFacebookSuccess} />
                                                <img src={Images.FACEBOOK_IC} alt="" />
                                                <div className="label">{context.state.locale.Login.facebook}</div>
                                            </div>

                                            <div className="social-item">
                                                <GoogleLogin
                                                    style={{ opacity: 0 }}
                                                    clientId={NC.GPLUS_ID}
                                                    buttonText="GOOGLE"
                                                    scope="email"
                                                    autoLoad={false}
                                                    icon={false}
                                                    fetchBasicProfile={false}
                                                    className="google-login-btn"
                                                    onSuccess={(user) => this.responseGoogle(user, true)}
                                                    onFailure={(user) => this.responseGoogle(user, false)}
                                                >
                                                    <i className="icon-g-plus gplus"></i>
                                                    <div className="label gplus">GOOGLE+</div>
                                                </GoogleLogin>
                                                <img src={Images.GOOGLE_IC} alt="" />
                                                <div className="label">{context.state.locale.Login.google}</div>
                                            </div>
                                        </div>
                                        {/* <div className="sap">{context.state.locale.Login.or_login}</div> */}
                                    </div>
                                }
                                {/* NATIVE FORM */}
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('email', email)}>
                                            <FloatingLabel
                                                styles={inputStyleUpdate}
                                                id='email'
                                                name='email'
                                                placeholder={context.state.locale.Login.email_placeholder}
                                                type='email'
                                                value={email}
                                                autoFocus={true}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                                
                                  {
                                      this.state.showVerifyText && <Row>
                                        <div className="title-resend">{context.state.locale.Login.verify_email}<div className="title-resend-bold" onClick={()=>{this.resendVerificationMail()}}><b><u>{context.state.locale.Login.resend_link}</u></b></div></div>
                                        <Row>
                                            <div className="title-resend mT10 mB10">{context.state.locale.Login.reend_dis}</div>
                                        </Row>
                                    </Row>
                                  }  
                                
                                
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('password', password)}>
                                            <FloatingLabel
                                                styles={inputStyleUpdate}
                                                id='password'
                                                name='password'
                                                placeholder={context.state.locale.Login.password_placeholder}
                                                type={isShowPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={this.handleChange}
                                            />
                                            <i className={`password-eye ${isShowPassword ? 'icon-eye-alt' : 'icon-eye-cancel'}`} onClick={() => this.showPassword()}></i>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className='text-center'>
                                        <a className='text-bold' onClick={() => (this.props.modalHide('LoginModalShow'), this.props.modalShow('ForgotModalShow'))}>{context.state.locale.Login.forgot_password}</a>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer className='custom-modal-footer'>
                                <Button bsStyle="primary" disabled={!formValid || posting} type='submit'>{context.state.locale.Login.submit_label}</Button>
                            </Modal.Footer>
                        </form>
                    </Modal>

                )}
            </MyContext.Consumer>
        );
    }
}