import React, { Component } from "react";
import packageJson from "../package.json";
import moment from "moment";

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

class WithClearCache extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {

        }
    }
    render()
    {
        return <ClearCacheComponent {...this.props}></ClearCacheComponent>
    }
}

class ClearCacheComponent extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            isLatestBuildDate: false
        }
    }
    componentDidMount() 
    {
        fetch("/meta.json", {
          headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
          }
          })
            .then((response) => response.json())
            .then((meta) => {
              const latestVersionDate = meta.buildDate;
              const currentVersionDate = packageJson.buildDate;

              // console.log('latestVersionDate, currentVersionDate', latestVersionDate, currentVersionDate);
    
              const shouldForceRefresh = buildDateGreaterThan(
                latestVersionDate,
                currentVersionDate
              );
              // console.log('shouldForceRefresh', shouldForceRefresh);
              if (shouldForceRefresh) {
                this.setState({isLatestBuildDate: false}, () => {
                    this.refreshCacheAndReload();
                })
              } else {
                this.setState({isLatestBuildDate: true})
              }
            });
    }

    refreshCacheAndReload = () => {

      // console.log('refreshCacheAndReload caches', caches);
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then((names) => {
            // console.log('refreshCacheAndReload names', names);
            for (const name of names) {
              // console.log('refreshCacheAndReload name', name);
              caches.delete(name);
            }
          });
        }
        // delete browser cache and hard reload
        window.location.reload()
    };


    render()
    {
    const { isLatestBuildDate } = this.state
    return (
        <React.Fragment>
            {isLatestBuildDate ? <div>{this.props.children}</div> : null}
        </React.Fragment>
        );
    }

}

// function withClearCache(Component) {
//   function ClearCacheComponent(props) {
//     const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

//     useEffect(() => {
//       fetch("/meta.json")
//         .then((response) => response.json())
//         .then((meta) => {
//           const latestVersionDate = meta.buildDate;
//           const currentVersionDate = packageJson.buildDate;

//           const shouldForceRefresh = buildDateGreaterThan(
//             latestVersionDate,
//             currentVersionDate
//           );
//           if (shouldForceRefresh) {
//             setIsLatestBuildDate(false);
//             refreshCacheAndReload();
//           } else {
//             setIsLatestBuildDate(true);
//           }
//         });
//     }, []);

//     const refreshCacheAndReload = () => {
//       if (caches) {
//         // Service worker cache should be cleared with caches.delete()
//         caches.keys().then((names) => {
//           for (const name of names) {
//             caches.delete(name);
//           }
//         });
//       }
//       // delete browser cache and hard reload
//       window.location.reload(true);
//     };

//     return (
//       <React.Fragment>
//         {isLatestBuildDate ? <Component {...props} /> : null}
//       </React.Fragment>
//     );
//   }

//   return ClearCacheComponent;
// }

export default WithClearCache;