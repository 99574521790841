import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import TagManager from 'react-gtm-module'
// const tagManagerArgs = {
//     gtmId: 'GTM-W46V3QD',
//     events: {'gtm.start': new Date().getTime(), event: 'gtm.js'},
//     dataLayerName: 'dataLayer'
// }
// TagManager.initialize(tagManagerArgs)


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: registration => {
    console.log(registration)
      const waitingServiceWorker = registration.waiting
  
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", event => {
          if (event.target.state === "activated") {
            window.location.reload()
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    }
  });
