/* eslint-disable no-unused-vars */
import React, { Component ,useEffect} from 'react';

import { MyProvider } from './context'
import Main from './Main'

import Offline from 'offline-js';

import "./assets/scss/style.scss";
import firebase from 'firebase';
import {config} from './firebaseConfig';
import ReactGA from 'react-ga'; 

import WithClearCache from './ClearCache'

import Img from './components/images';

// import TagManager from 'react-gtm-module'

import { ForMaintainance} from "./config";

ReactGA.initialize('UA-177410945-1');
ReactGA.pageview(window.location.pathname);

let createHistory = require("history").createBrowserHistory

const history = createHistory();
firebase.initializeApp(config);



const ClearCacheComponent = () => <WithClearCache><App /></WithClearCache>



class App extends Component {

    componentWillMount() {
        window.Offline.on('requests:flush', function () {
            history.go();
        });
    }
    componentDidMount() {
        if(process.env.NODE_ENV !== "development") {
            console.warn = () => {};
            console.error = () => {};
            console.log = () => {};
            console.info = () => {};
        }

      }

    render() {

 
        return (
            <MyProvider>
                {
                    ForMaintainance 
                    ?
                        <div Style = {'width: 100%; height: 100%; align-items: center; justify-content: center; display: flex;'}>
                            <img src = {Img.maintainancePage} alt = {''}></img>
                        </div>
                    :
                        <Main />
                }
            </MyProvider>
        );
    }
}

// export default App;
export default ClearCacheComponent;
