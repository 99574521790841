/* eslint-disable no-sequences */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { MyContext } from '../context';

export default class LeagueModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    // Lifecycle
    // ------------------------------
    componentWillMount() {

    }
    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.LeagueModalShow}
                        onHide={() => this.props.modalHide('LeagueModalShow')}
                        dialogClassName="custom-modal league-modal-bg"
                    >
                        <Modal.Header closeButton className='custom-modal-header'>
                            <div className="title">{context.state.locale.LeagueModal.hero_title}</div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="league-nav-item pull-right">
                                <div className="title">{context.state.locale.LeagueModal.join_private_league}</div>
                                <div className="content">{context.state.locale.LeagueModal.join_private_league_text}</div>
                                <Button bsStyle='primary' onClick={() => (this.props.modalHide('LeagueModalShow'), context.modalShow('JoinLeagueCodeShow'))}>{context.state.locale.LeagueModal.join_private_league_btn}</Button>
                            </div>
                            <div className="league-nav-item">
                                <div className="title">{context.state.locale.LeagueModal.create_private_league}</div>
                                <div className="content">{context.state.locale.LeagueModal.create_private_league_text}</div>
                                <Button bsStyle='primary' onClick={() => this.props.history.push('/leagues/create-league')}>{context.state.locale.LeagueModal.create_private_league_btn}</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
            </MyContext.Consumer>
        )
    }
}