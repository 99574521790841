import React from 'react';
import { Modal } from 'react-bootstrap';
import { MyContext } from '../context';
import FixturesModal from '../views/FixturesModal';
export default class FixtureModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        var settings = {
            rtl: false,
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            centerMode: false,
            variableWidth: false,
            centerPadding: '0px',
            initialSlide: 0
        };
        const FixturesModalProps = {
            ...this.props,
            settings
        }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.FixtureModalShow}
                        onHide={() => this.props.modalHide('FixtureModalShow')}
                        dialogClassName="custom-modal custom-bg"
                    >
                        <Modal.Header closeButton className='custom-modal-header'>
                            <div className="title">{context.state.locale.Fixtures.hero_title}</div>
                        </Modal.Header>

                        <form onSubmit={this.onSubmit}>
                            <Modal.Body className='no-padding'>
                                <FixturesModal {...FixturesModalProps}/>
                            </Modal.Body>
                        </form>
                    </Modal>
                )}
            </MyContext.Consumer>
        )
    }
}