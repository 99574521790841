/* eslint-disable eqeqeq, no-sequences, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content, react/no-direct-mutation-state, no-redeclare */
import React, { Fragment } from 'react'
import Images from '../components/images'
import * as NC from '../helper/NetworkingConstants'
import _ from 'lodash'

import { MyContext } from '../context'
import { Image } from 'react-bootstrap'
function swapStyles(dndPickedPlayer, item, list) {
  var swapStyle = {}
  // var className = ''

  if (dndPickedPlayer == '') return swapStyle
  if (dndPickedPlayer != null) {
    if (dndPickedPlayer.player_uid == item.player_uid) {
      if (
        _.isUndefined(dndPickedPlayer.substitute) ||
        dndPickedPlayer.substitute == false
      ) {
        swapStyle.style = {
          opacity: 0,
          transition: 'opacity 100ms',
        }
      } else {
        swapStyle.style = {
          pointerEvents: 'all',
        }
      }
      swapStyle.className =
        item.is_bench_player == '1'
          ? 'transfer-in swap-item-opacity'
          : 'transfer-out swap-item-opacity'
    } else if (item.is_bench_player != dndPickedPlayer.is_bench_player) {
      if (
        dndPickedPlayer.position.toUpperCase() == NC.GOALKEEPER &&
        item.position.toUpperCase() == NC.GOALKEEPER
      ) {
        swapStyle.className =
          item.is_bench_player == '1' ? 'transfer-in' : 'transfer-out'
      } else if (
        dndPickedPlayer.position.toUpperCase() != NC.GOALKEEPER &&
        item.position.toUpperCase() != NC.GOALKEEPER &&
        (dndPickedPlayer.position.toUpperCase() == NC.DEFENDER ||
          item.position.toUpperCase() == NC.DEFENDER)
      ) {
        var filterArr = list.filter(function (obj) {
          return (
            obj.position.toUpperCase() == NC.DEFENDER &&
            obj.is_bench_player != '1'
          )
        })

        var filterArrFW = list.filter(function (obj) {
          return (
            obj.position.toUpperCase() == NC.FORWARDER &&
            obj.is_bench_player != '1'
          )
        })

        if (
          dndPickedPlayer.position.toUpperCase() == NC.DEFENDER &&
          dndPickedPlayer.is_bench_player == '1' &&
          filterArr.length < 4
        ) {
          swapStyle.className =
            item.is_bench_player == '1' ? 'transfer-in' : 'transfer-out'
        } else if (
          dndPickedPlayer.position.toUpperCase() == NC.DEFENDER &&
          dndPickedPlayer.is_bench_player != '1' &&
          filterArr.length < 4 &&
          item.position.toUpperCase() == NC.DEFENDER
        ) {
          swapStyle.className =
            item.is_bench_player == '1' ? 'transfer-in' : 'transfer-out'
        } else if (
          dndPickedPlayer.position.toUpperCase() == NC.DEFENDER &&
          dndPickedPlayer.is_bench_player != '1' &&
          filterArr.length < 4 &&
          item.position.toUpperCase() != NC.DEFENDER
        ) {
          swapStyle.style = {
            opacity: 0.9,
            pointerEvents: 'none',
          }
        } else if (
          dndPickedPlayer.position.toUpperCase() != NC.DEFENDER &&
          dndPickedPlayer.is_bench_player == '1' &&
          filterArr.length < 4 &&
          item.position.toUpperCase() == NC.DEFENDER
        ) {
          swapStyle.style = {
            opacity: 0.9,
            pointerEvents: 'none',
          }
        } else if (filterArrFW.length < 2) {
          if (
            dndPickedPlayer.position.toUpperCase() == NC.FORWARDER &&
            dndPickedPlayer.is_bench_player != '1' &&
            item.position.toUpperCase() != NC.FORWARDER
          ) {
            swapStyle.style = {
              opacity: 0.9,
              pointerEvents: 'none',
            }
          } else if (
            dndPickedPlayer.position.toUpperCase() != NC.FORWARDER &&
            dndPickedPlayer.is_bench_player == '1' &&
            item.position.toUpperCase() == NC.FORWARDER
          ) {
            swapStyle.style = {
              opacity: 0.9,
              pointerEvents: 'none',
            }
          } else {
            swapStyle.className =
              item.is_bench_player == '1' ? 'transfer-in' : 'transfer-out'
          }
        } else {
          swapStyle.className =
            item.is_bench_player == '1' ? 'transfer-in' : 'transfer-out'
        }
      } else if (
        dndPickedPlayer.position.toUpperCase() != NC.GOALKEEPER &&
        item.position.toUpperCase() != NC.GOALKEEPER
      ) {
        var filterArr = list.filter(function (obj) {
          return (
            obj.position.toUpperCase() == NC.FORWARDER &&
            obj.is_bench_player != '1'
          )
        })
        if (
          dndPickedPlayer.position.toUpperCase() == NC.FORWARDER &&
          dndPickedPlayer.is_bench_player == '1' &&
          filterArr.length < 2
        ) {
          swapStyle.className =
            item.is_bench_player == '1' ? 'transfer-in' : 'transfer-out'
        } else if (
          dndPickedPlayer.position.toUpperCase() == NC.FORWARDER &&
          dndPickedPlayer.is_bench_player != '1' &&
          filterArr.length < 2 &&
          item.position.toUpperCase() == NC.FORWARDER
        ) {
          swapStyle.className =
            item.is_bench_player == '1' ? 'transfer-in' : 'transfer-out'
        } else if (
          dndPickedPlayer.position.toUpperCase() == NC.FORWARDER &&
          dndPickedPlayer.is_bench_player != '1' &&
          filterArr.length < 2 &&
          item.position.toUpperCase() != NC.FORWARDER
        ) {
          swapStyle.style = {
            opacity: 0.9,
            pointerEvents: 'none',
          }
        } else if (
          dndPickedPlayer.position.toUpperCase() != NC.FORWARDER &&
          dndPickedPlayer.is_bench_player == '1' &&
          filterArr.length < 2 &&
          item.position.toUpperCase() == NC.FORWARDER
        ) {
          swapStyle.style = {
            opacity: 0.9,
            pointerEvents: 'none',
          }
        } else {
          swapStyle.className =
            item.is_bench_player == '1' ? 'transfer-in' : 'transfer-out'
        }
      } else {
        swapStyle.style = {
          opacity: 0.9,
          pointerEvents: 'none',
        }
      }
    } else if (item.is_bench_player == dndPickedPlayer.is_bench_player) {
      if (
        dndPickedPlayer.position.toUpperCase() != NC.GOALKEEPER &&
        item.position.toUpperCase() != NC.GOALKEEPER
      ) {
        swapStyle.className =
          item.is_bench_player == '1' ? 'transfer-in' : 'transfer-out'
      }
    } else {
      swapStyle.style = {
        opacity: 0.9,
        pointerEvents: 'none',
      }
    }
  }
  return swapStyle
}

const PlayerCardList = ({
  dndPickedPlayer,
  drag,
  drop,
  dragStart,
  dragEnd,
  list,
  position,
  is_bench_player,
  FieldviewThis,
}) => (
  <MyContext.Consumer>
    {(context) => (
      <ul className="player-grid">
        {_.map(list, (item, index) => {
          let EmptyCard = (
            <li key={index}>
              <div
                className="player-grid-item"
                onClick={() => FieldviewThis.props.PositionSelectChange(item)}
              >
                <div className="jersey">
                  <img src={Images.JERSEY} alt="" />
                </div>
                <div className="grid-item-detail">
                  <div className="name">{item.display_position}</div>
                </div>
              </div>
            </li>
          )
          let FilledCard = (
            <li key={index}>
              <div
                style={swapStyles(dndPickedPlayer, item, list).style}
                id={item.player_uid}
                onDrop={drop}
                onDragStart={dragStart}
                onDragOver={drag}
                onDragEnd={dragEnd}
                draggable={
                  !FieldviewThis.props.isFullFieldViewMode &&
                  FieldviewThis.props.isSwapingEnabled
                }
                className={`player-grid-item filled ${
                  swapStyles(dndPickedPlayer, item, list).className
                } 
                        ${
                          !_.isEmpty(FieldviewThis.props.team_info) &&
                          !_.isUndefined(FieldviewThis.props.SelectedWeek) &&
                          FieldviewThis.props.team_info.captain_booster_status
                            .applied_week >= FieldviewThis.props.SelectedWeek &&
                          FieldviewThis.props.team_info.captain_booster_status
                            .is_applied == 1 &&
                          FieldviewThis.props.SelectedWeek ==
                            Number(
                              FieldviewThis.props.team_info
                                .captain_booster_status.applied_week,
                            ) &&
                          item.player_role == 1
                            ? 'captian-booster-aaply'
                            : ''
                        }`}
              >
                {FieldviewThis.props.is_editable == 1 &&
                  FieldviewThis.props.user_join_count == 0 && (
                    <span
                      className="player-grid-remove"
                      onClick={() =>
                        FieldviewThis.props.RemovePlayerFromLineup(item)
                      }
                    ></span>
                  )}

                {FieldviewThis.props.user_join_count == 1 &&
                  FieldviewThis.props.isFullFieldViewMode && (
                    <span
                      className="player-grid-remove"
                      onClick={() =>
                        FieldviewThis.props.RemovePlayerFromLineup(item)
                      }
                    ></span>
                  )}

                {item.player_role == 1 && <span className="captain"></span>}
                {item.player_role == 2 && (
                  <span className="vice-captain"></span>
                )}
                {item.is_substitute == 1 && (
                  <i className="icn-substitute-arrow" />
                )}
                {item.injury_status == 1 && <i className="icn-injury" />}

                {item.is_rule_violate == 1 ? (
                  <div
                    className="eliminate-player"
                    onClick={() =>
                      FieldviewThis.props.playerCardModalInit(
                        'PlayerCardModalShow',
                        item,
                        FieldviewThis.props.isMyTeamScreen,
                      )
                    }
                  >
                    {<div className="player-position">{'ELIMINATED'}</div>}
                  </div>
                ) : // <div Style = {'position: absolute; top: 0; left: 4px; width: 16px; height: 16px;'}>
                //     <img src = {Images.transferError} alt='' />
                // </div>
                item.is_club_remove == 1 || item.is_club_change == 1 ? (
                  <div
                    Style={
                      'position: absolute; top: 0;, left: 4px; width: 16px; height: 16px;'
                    }
                  >
                    <img src={Images.transferWarning} alt="" />
                  </div>
                ) : null}
                {/* {console.log('item.is_eliminate====>', item.is_eliminate)} */}

                {item.is_eliminate == 0 ? (
                  <div
                    style={
                      !_.isNull(dndPickedPlayer)
                        ? dndPickedPlayer.substitute
                          ? {}
                          : { pointerEvents: 'none' }
                        : {}
                    }
                    className="jersey"
                    onClick={() =>
                      FieldviewThis.props.playerCardModalInit(
                        'PlayerCardModalShow',
                        item,
                        FieldviewThis.props.isMyTeamScreen,
                      )
                    }
                  >
                    <img
                      style={{ pointerEvents: 'none' }}
                      src={item.jersey}
                      alt=""
                    />

                    {/* May 2019 */}
                    {process.env.NODE_ENV == 'development' && (
                      <div className="player-position">
                        {item.display_position}
                      </div>
                    )}
                    {/* End */}
                  </div>
                ) : (
                  <div
                    Style={
                      'position: relative; display: block; z-index: 1; height: 54px; cursor: pointer; background-color: #000000; opacity: 0.95; width: 100%; padding: 12px; border-radius: 8px;'
                    }
                    onClick={() =>
                      FieldviewThis.props.playerCardModalInit(
                        'PlayerCardModalShow',
                        item,
                        FieldviewThis.props.isMyTeamScreen,
                      )
                    }
                  >
                    {/* <img style={{ pointerEvents: 'none' }} src={item.jersey} alt="" /> */}
                    {/* May 2019 */}
                    <div>
                      {
                        <div
                          className="player-position"
                          style={{ color: '#E42054', fontSize: 10 }}
                        >
                          {'ELIMINATED'}
                        </div>
                      }
                    </div>
                    {/* End */}
                  </div>
                )}
                <div
                  style={dndPickedPlayer ? { pointerEvents: 'none' } : {}}
                  className="grid-item-detail"
                  data-value={
                    item.is_bench_player == 1
                      ? context.state.locale.PitchView.sub_in
                      : context.state.locale.PitchView.sub_out
                  }
                >
                  <div className="name">
                    {/* {FieldviewThis.props.playerNameSort(item.full_name)} */}
                    {item.full_name}
                  </div>
                  <div className="value">
                    {FieldviewThis.props.isMyTeam && item.against_team}
                    {FieldviewThis.props.isPoint && (
                      <Fragment>
                        {item.team_abbr_lang}
                        {' | '}
                      </Fragment>
                    )}
                    {!FieldviewThis.props.isMyTeam &&
                      (FieldviewThis.props.isMyTeamScreen
                        ? context.state.locale.confirm_transfer.currency_label +
                          ' ' +
                          item.salary
                        : item.score +
                          ' ' +
                          context.state.locale.PitchView.pts)}
                  </div>
                </div>
              </div>
              {item.is_bench_player == 1 &&
                !FieldviewThis.props.isFullFieldViewMode && (
                  // process.env.NODE_ENV == 'development' && item.is_bench_player == 1 && !FieldviewThis.props.isFullFieldViewMode &&

                  <div className="player-order-number">
                    {item.bench_order != 1 &&
                      `${_.cloneDeep(item.bench_order) - 1}.`}{' '}
                    {item.display_position}
                  </div>
                )}
            </li>
          )

          let Card = item.player_uid ? FilledCard : EmptyCard
          if (
            item.is_bench_player == is_bench_player &&
            !FieldviewThis.props.isFullFieldViewMode
          ) {
            return position != undefined
              ? item.position == position
                ? Card
                : ''
              : Card
          }
          if (FieldviewThis.props.isFullFieldViewMode) {
            return position != undefined
              ? item.position == position
                ? Card
                : ''
              : Card
          }
        })}
      </ul>
    )}
  </MyContext.Consumer>
)

export default class PitchViewField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dndPickedPlayer: null,
      lineups: this.props.lineups,
    }
    this.swap = this.swap.bind(this)
  }

  dragEnd = (event) => {
    this.state.dndPickedPlayer = null
    this.setState({ dndPickedPlayer: null })
    this.cancelDefaultBehavior(event)
  }

  dragStart = (event) => {
    event.dataTransfer.setData('text', event.target.id)
    var tmpPicked = ''
    _.map(this.state.lineups, (item, index) => {
      if (item.player_uid == event.target.id) {
        item.substitute = false
        item.substitute_allow = false
        tmpPicked = item
      }
    })
    this.state.dndPickedPlayer = tmpPicked
    this.setState({ dndPickedPlayer: tmpPicked })
  }

  drop = (event) => {
    if (event.target.id) {
      this.swap(event.dataTransfer.getData('text'), event.target.id)
      // event.dataTransfer.clearData()
    }

    this.state.dndPickedPlayer = null
    this.setState({ dndPickedPlayer: null })
    this.cancelDefaultBehavior(event)
  }

  drag = (event) => {
    this.cancelDefaultBehavior(event)
  }

  cancelDefaultBehavior(e) {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  swap = (title1, title2) => {
    let pos2 = this.state.lineups.findIndex((object) => {
      return object.player_uid === title2
    })
    let pos1 = this.state.lineups.findIndex((object) => {
      return object.player_uid === title1
    })
    let samplearray = this.state.lineups
    let temp = this.state.lineups[pos1]
    let temp2 = this.state.lineups[pos2]

    if (
      pos2 >= 0 &&
      pos1 >= 0 &&
      ((temp.is_bench_player == '1' && temp2.is_bench_player == '0') ||
        (temp.is_bench_player == '0' && temp2.is_bench_player == '1'))
    ) {
      temp.is_bench_player = temp.is_bench_player == '1' ? '0' : '1'
      temp2.is_bench_player = temp2.is_bench_player == '1' ? '0' : '1'

      let clone_temp = _.cloneDeep(temp.bench_order)
      let clone_temp2 = _.cloneDeep(temp2.bench_order)

      temp.bench_order = clone_temp2
      temp2.bench_order = clone_temp

      temp.player_role = '0'
      temp2.player_role = '0'
      temp.captain = '0'
      temp2.captain = '0'

      samplearray[pos1] = temp2
      samplearray[pos2] = temp
      this.state.lineups = samplearray
      this.setState({ lineups: samplearray })
      this.props.LineupUpdate(samplearray, true)
    } else if (
      pos2 >= 0 &&
      pos1 >= 0 &&
      temp.is_bench_player == '1' &&
      temp2.is_bench_player == '1'
    ) {
      if (temp.position != NC.GOALKEEPER && temp2.position != NC.GOALKEEPER) {
        let clone_temp = _.cloneDeep(temp.bench_order)
        let clone_temp2 = _.cloneDeep(temp2.bench_order)

        temp.bench_order = clone_temp2
        temp2.bench_order = clone_temp

        samplearray[pos1] = temp2
        samplearray[pos2] = temp
        this.state.lineups = samplearray
        this.setState({ lineups: samplearray })
        this.props.LineupUpdate(samplearray, true)
      }
    }
  }

  SubstituteStart = (Player) => {
    if (Player.substitute_allow == true) {
      let substitute_player = _.filter(this.state.lineups, (o) => {
        return o.substitute == true
      })[0]
      this.swap(substitute_player.player_uid, Player.player_uid)
      _.map(this.state.lineups, (item, index) => {
        item.substitute = false
        item.substitute_allow = false
      })
      this.setState({ dndPickedPlayer: null })
    } else {
      var tmpPicked = ''
      _.map(this.state.lineups, (item, index) => {
        item.substitute = false
        item.substitute_allow = true
        if (item.player_uid == Player.player_uid) {
          item.substitute = true
          item.substitute_allow = false
          tmpPicked = item
        }
      })
      this.setState({ dndPickedPlayer: tmpPicked })
    }
    this.props.modalHide('PlayerCardModalShow')
  }
  SubstituteEnd = (Player) => {
    _.map(this.state.lineups, (item, index) => {
      item.substitute = false
      item.substitute_allow = false
    })
    this.setState({ dndPickedPlayer: null })
    this.props.modalHide('PlayerCardModalShow')
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lineup_lastupdate !== this.props.lineup_lastupdate) {
      console.log('lineup_lastupdate', nextProps.lineup_lastupdate)
      this.setState({
        lineups: nextProps.lineups,
      })
    }
  }
  render() {
    const {
      // is_editable,
      // SelectedFormation,
      // show_transfer,
      // user_join_count,
      // team_info,
      // SelectedWeek,
      isFullFieldViewMode,
      DrawerRosterTrigger,
    } = this.props
    const { lineups } = this.state
    const sortLineups = _.sortBy(lineups, 'bench_order')

    const PlayerCardListProps = {
      dndPickedPlayer: this.state.dndPickedPlayer,
      drag: this.drag,
      drop: this.drop,
      dragStart: this.dragStart,
      dragEnd: this.dragEnd,
      list: lineups,
      FieldviewThis: this,
      is_bench_player: 0,
    }
    return (
      <MyContext.Consumer>
        {(context) => (
          <div className="pitch-view">
            <div
              className={`singlefield ${
                isFullFieldViewMode ? 'full-singlefield' : ''
              }`}
            >
              <i className="sponsor-space"></i>
              <i className="sponsor-space right"></i>
              <i className="goal"></i>

              <span
                className="roster-drawer-trigger d-block d-sm-block d-md-block d-lg-none"
                onClick={() => DrawerRosterTrigger(false)}
              >
                {isFullFieldViewMode ? (
                  <span>
                    <i className="icon-tshirt"></i>
                    <div className="label">
                      {context.state.locale.PitchView.all_players}
                    </div>
                  </span>
                ) : (
                  <span>
                    <div className="label my-info-label">My Info</div>
                  </span>
                )}
              </span>

              <PlayerCardList
                {...PlayerCardListProps}
                position={NC.GOALKEEPER}
              />

              <PlayerCardList {...PlayerCardListProps} position={NC.DEFENDER} />
              <PlayerCardList
                {...PlayerCardListProps}
                position={NC.MIDFIELDER}
              />
              <PlayerCardList
                {...PlayerCardListProps}
                position={NC.FORWARDER}
              />
            </div>
            {!isFullFieldViewMode && (
              <div
                className={`bench-view ${
                  !_.isEmpty(this.props.team_info) &&
                  !_.isUndefined(this.props.SelectedWeek) &&
                  Number(
                    this.props.team_info.bench_booster_status.applied_week,
                  ) == Number(this.props.SelectedWeek) &&
                  this.props.team_info.bench_booster_status.is_applied == 1 &&
                  this.props.team_info.bench_booster_status.applied_week ==
                    this.props.SelectedWeek
                    ? 'booster-active'
                    : ''
                }`}
              >
                <PlayerCardList
                  {...PlayerCardListProps}
                  list={sortLineups}
                  is_bench_player={1}
                />
              </div>
            )}
          </div>
        )}
      </MyContext.Consumer>
    )
  }
}
