/* eslint-disable eqeqeq */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Images from '../components/images';
import { MyContext } from '../context';

export default class ThankyouCreateTeamModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    // Lifecycle
    // ------------------------------
    componentWillMount() {

    }
    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.ThankyouCreateTeamModalShow}
                        dialogClassName="custom-modal custom-bg create-team-modal-bg">
                        <Modal.Body>
                            <div className="thankyou-create-team-wrap">
                                <figure className="site-logo">
                                    <img style={{width:100,height:70,objectFit:'contain'}} src={context.state.language == 'en' ? Images.WHISTLE : Images.WHISTLE} alt=""/>
                                </figure>
                                <div className="title">{context.state.locale.Thankyou.hero_title}</div>
                                <div className="content">{context.state.locale.Thankyou.create_team}</div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='custom-modal-footer'>
                            <Button bsStyle="primary" onClick={() => {this.props.history.push('/home');window.location.reload()}}>{context.state.locale.Thankyou.go_to_dashboard}</Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </MyContext.Consumer>
        )
    }
}