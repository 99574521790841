/* eslint-disable eqeqeq */
import React from 'react'
import { MyContext } from '../context';
import Images from '../components/images';
import { Col, Button, Modal, FormGroup } from 'react-bootstrap';
import FloatingLabel from 'floating-label-react';
import { inputStyle } from '../helper/input-style';
import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import { notify } from 'react-notify-toast';

export default class JoinLeagueCode extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            code: '',
            posting: false
        };
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }


    checkEligibility(code) {
        let param = {
            "league_id": WSManager.getMultiLegData().league_id,
            "join_code": code
        }
        this.setState({posting: true})
        WSManager.Rest(API.CHECK_ELIGIBILITY, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let data = responseJson.data;
                if (!_.isNull(data.global_lineup_master_id)) {
                    let join_game_param = {
                        // "league_id": NC.leagueId,
                        // "sports_id": NC.sportsId,
                        "sports_id" : WSManager.getMultiLegData().sports_id,
                        "league_id" : WSManager.getMultiLegData().league_id,
                        "contest_id": data.contest_id,
                        "lineup_master_id": data.global_lineup_master_id
                    }
                    WSManager.Rest(API.JOIN_CONTEST, join_game_param).then((responseJson) => {
                        if (responseJson.response_code === NC.successCode) {
                            notify.show(responseJson.message, "success", 5000);
                        }
                        this.setState({posting: false});
                        this.props.modalHide('JoinLeagueCodeShow');
                    })
                }

            } else if (responseJson.response_code === NC.AUTHENTICATE_REQUIRE_CODE) {
                notify.show(NC.MSZ_AUTHENTICATE_USER, "error", 1000);
            } else {
                this.setState({posting: false});
            }
        })
    }

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.JoinLeagueCodeShow}
                        onHide={() => this.props.modalHide('JoinLeagueCodeShow')}
                        dialogClassName="custom-modal">
                        <Modal.Body>
                            <div className="thank-content">
                                <figure className="graphic">
                                    <img style={{width:100,height:70,objectFit:'contain'}} src={context.state.language == 'en' ? Images.DARK_KSAF_EN_LOGO : Images.DARK_KSAF_AR_LOGO} alt="" />
                                </figure>
                                <div className="title">{context.state.locale.JoinLeagueCode.hero_title}</div>
                                <div className="m-t m-b text-italic">{context.state.locale.JoinLeagueCode.description}</div>
                                <Col xs={11} className="auto-margin m-t-20">
                                    <FormGroup
                                        className='input-label-center'
                                        controlId="formBasicText"
                                    >
                                        <FloatingLabel
                                            styles={inputStyle}
                                            id='EnterLeagueCode'
                                            name='code'
                                            placeholder={context.state.locale.JoinLeagueCode.placeholder}
                                            type='text'
                                            value={this.state.code}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </Col>

                            </div>
                        </Modal.Body>
                        <ul className='player-card-footer'>
                            <li><Button bsStyle="default" disabled={!(this.state.code != '' && this.state.code.length >= 4) || this.state.posting} type='button' onClick={() => this.state.code.trim() == '' ? notify.show(NC.MSZ_NO_LEAGUE_CODE, "error", 2000) : this.checkEligibility(this.state.code)}>{context.state.locale.JoinLeagueCode.hero_title}</Button></li>
                        </ul>
                        {/* <Nav className='player-card-footer'>
                            <NavItem onClick={() => this.state.code.trim() == '' ? notify.show(NC.MSZ_NO_LEAGUE_CODE, "error", 2000) : this.checkEligibility(this.state.code)}>{context.state.locale.JoinLeagueCode.hero_title}</NavItem>
                        </Nav> */}
                    </Modal>
                )}
            </MyContext.Consumer>
        );
    }
}