import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FixturesModal from './FixturesModal';

import { MyContext } from '../context';
import {Helmet} from "react-helmet";
import AdsContainer from '../components/AdsContainer';

export default class Fixtures extends React.Component {

    // Lifecycle
    // ------------------------------
    constructor(props) {
        super(props);
        this.state = {
            posting: false,
            SelectedWeek: ''
        }
    }
 
    
    render() {

        var settings = {
            rtl: false,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 3,
            centerMode: false,
            variableWidth: false,
            centerPadding: '0px',
            initialSlide: 0,
            afterChange: current => this.fixturModalRef.onSlideChange(current),
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        };
        const FixturesModalProps = {
            ...this.props,
            settings
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="container main-container">
                        <AdsContainer />
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.fixtures_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.fixtures_description} />
                        </Helmet>
                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={8}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="league-heading m-v">
                                            <div className="title">{context.state.locale.Fixtures.hero_title}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <FixturesModal {...FixturesModalProps} ref={fixtursM => (this.fixturModalRef = fixtursM)}/>
                            </Col>
                        </Row>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}