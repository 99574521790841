/* eslint-disable eqeqeq */
import React from 'react';
import { Button } from 'react-bootstrap';
import _ from "lodash";
import { MyContext } from '../context';


export default class FieldControls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const {
            LineupsList,
            // LineupsListClone,
            user_join_count,
            is_editable,
            // SelectedBooster,

            // team_info,
            isSelectAnyPlayer,
            modalShow,
            ConfirmMyteam,
            // ApplyBooster,
            // LineupProccessInit,

            isSwapped
        } = this.props
        return (
            <MyContext.Consumer>
                {(context) => (
                    <span>
                        {
                            !_.isEmpty(LineupsList) && is_editable == 1 && user_join_count == 0 &&
                            <div className="field-control-footer">
                                <div className="control-info">{context.state.locale.FieldControls.description}</div>
                                <Button bsStyle="primary" disabled={(isSelectAnyPlayer(LineupsList) != LineupsList.length)} onClick={() => modalShow('CreateTeamModalShow')}>{context.state.locale.FieldControls.save}</Button>
                            </div>
                        }
                        {
                            !_.isEmpty(LineupsList) && user_join_count == 1 &&
                            <div className="field-control-footer">
                                <Button bsStyle="primary" disabled={
                                    !isSwapped
                                } onClick={() => ConfirmMyteam()}>{context.state.locale.FieldControls.confirm}</Button>
                                
                                {/* <Button bsStyle="primary" disabled={
                                    (SelectedBooster == '' || team_info.booster_applied == 1) ? _.isEqual(LineupsListClone, LineupsList) : false
                                } onClick={() => ConfirmMyteam()}>{context.state.locale.FieldControls.confirm}</Button> */}

                                {/* <Button bsStyle="primary" disabled={
                                    (SelectedBooster == '' || team_info.booster_applied == 1) ? _.isEqual(LineupsListClone, LineupsList) : false
                                } onClick={() => (ApplyBooster(), LineupProccessInit())}>{context.state.locale.FieldControls.confirm}</Button> */}
                            </div>
                        }
                    </span>
                )}
            </MyContext.Consumer>
        )
    }
}