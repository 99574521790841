import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import Images from '../components/images'
import { MyContext } from '../context';

export default class ThankyouForgot extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false
        };
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleHide = () => {
        this.setState({ show: false });
    }

    getValidationState() {
        const length = this.state.FirstName.length;
        if (length > 10) return 'success';
        else if (length > 5) return 'warning';
        else if (length > 0) return 'error';
        return null;
    }

    handleChange = (e) => {
        this.setState({ value: e.target.value });
    }

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.ThankyouForgotModalShow}
                        onHide={() => this.props.modalHide('ThankyouForgotModalShow')}
                        dialogClassName="custom-modal"
                    >
                        <Modal.Body>
                            <div className="modal-header no-padding border-0">
                                <button className="close thanks-close" onClick={() => this.props.modalHide('ThankyouForgotModalShow')}><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="thank-content">
                                <figure className="graphic">
                                    <img src={Images.THANKYOU_IC} alt="" />
                                </figure>
                                <div className="title">{context.state.locale.Thankyou.hero_title}</div>
                                <div className="text-muted max-w-200">{context.state.locale.Thankyou.forgot_pwd}</div>

                            </div>

                        </Modal.Body>
                        <Modal.Footer className='custom-modal-footer'>
                            <Button bsStyle="primary" onClick={() => this.props.modalHide('ThankyouForgotModalShow')}>{context.state.locale.Thankyou.ok_text}</Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </MyContext.Consumer>
        );
    }
}