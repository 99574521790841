/* eslint-disable eqeqeq */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { MyContext } from '../context';
import * as qs from 'query-string';
export default class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount = () => {
        const { location } = this.props;
        const parsed = qs.parse(location.search);
        this.setState((state, props) => {
            return {
                isMobile: parsed.isMobile
            }
        });
    }

    render() {
        const {
            isMobile
        } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (
                    context.state.language == 'en' ?
                        <div className={`container main-container ${isMobile ? 'no-margin-t' : ''}`}>
                            <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                                <title>{context.state.locale.MetaTag.privacypolicy_title}</title>
                                <meta name="description" content={context.state.locale.MetaTag.privacypolicy_description} />
                            </Helmet>
                            <Row className="">
                                <Col lg={9} className="auto-margin">
                                    <div className="league-heading m">
                                        <div className="title">PRIVACY POLICY</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={9} className="auto-margin">
                                    <div className="static-page-wrap">
                                        <p>In this Privacy Policy, terms such as "we", "us, "our" or similar expressions shall mean Goal Fantasy Football, hereafter known as “Goal”</p>

                                        <b>1. Background</b>
                                        <p>Goal is a fantasy sport service provider. We are responsible for deciding how we use the personal data that we collect about you and we will ensure that the personal data we hold about you is, at all times:</p>
                                        <ol className='no-margin-t'>
                                            <li>used fairly, lawfully, and transparently;</li>
                                            <li>collected for limited, specific purposes only;</li>
                                            <li>adequate, relevant to and limited to what is necessary for those purposes;</li>
                                            <li>kept accurate and up-to-date;</li>
                                            <li>not kept for longer than is necessary; and</li>
                                            <li>held securely.</li>
                                        </ol>

                                        <b>2. Personal Data We Collect About You</b>
                                        <p>Personal data means any information about an individual from which that person can be identified. We may collect and use various types of your personal data:</p>
                                        <ul className='no-margin-t'>
                                            <li>Registration Data: To access and use some elements of the Services you must create account with Goal, by providing us with the information which is identified on the relevant registration page: name, email, date of birth, and country. We will also collect a username and password. Please note that some elements of the Services will not be available if you do not provide the required information. We may also ask or allow you to submit other optional information, including your gender, phone number.</li>
                                            <li>Identity Data: We also ask you to provide identification information when we provide other elements of the Services: name, age, gender and social media handles/ usernames.</li>
                                            <li>Contact Data: When we want to communicate with you, or you with us, we use your email address, telephone numbers, social media handles/ usernames and location (country/ place of residence).</li>
                                            <li>Media Data: We use images from photographs user provided by you for our use.</li>
                                            <li>User Data: We collect your usage and preference details related to your use of the Services such as language, game-play statistics, scores, rankings, time spent playing, game profile, preferences (including favorite Clubs), survey responses, feedback and other data that you provide to us as part of your account.</li>
                                            <li>Marketing and/ or Communications Data: We retain your preferences in receiving marketing messages from us and our Clubs and commercial partners, and your communication preferences.</li>
                                            <li>Technical Data: Certain data is automatically generated and collected by us when you use the Services such as your IP address, MAC address and other device identifiers; your clickstream to, through and from the Services (including date and time); pages you viewed or searched for; length of visits to certain pages; page interaction information (such as scrolling, clicks, and mouse-overs); methods used to browse away from the page.</li>
                                        </ul>
                                        <b>3. How We Collect Your Personal Data</b>
                                        <p>You provide us with your personal data when you:</p>
                                        <ul className='no-margin-t'>
                                            <li>access, use or play the Services;</li>
                                            <li>create a Goal account for the Services;</li>
                                            <li>request information, marketing and other communications to be sent to you;</li>
                                        </ul>
                                        <p>We may also receive personal data as part of the legal processes we undertake to protect our Services and our intellectual property, such as our brand or media rights, or those of our licensees/commercial partners.
                                            </p>
                                        <p>We collect the Technical Data automatically as you interact with our Services by using cookies and other similar web technologies.</p>
                                        <p>We also use third party tools to help us manage and analyze our social media presence, and report on comments, mentions and other content that is posted about us on social media sites and other public channels and forums. These third parties’ activities, and their information collection and sharing practices, are subject to the terms of the relevant social media site, channel or forum. We will use this information in accordance with this Privacy Policy.</p>
                                        <p>We use a third party service, Google Analytics, to collect standard internet log information and details of visitor behavior patterns. We do this to find out things such as the number of visitors to certain parts of the Services. This information is only used by us in a way which does not identify you. We do not make, and do not allow Google to make, any attempt to find out the identities of anyone visiting our website.</p>

                                        <b>4. How and Why We Use Your Personal Data</b>
                                        <p>Accordingly, we use your personal data for the following purposes:</p>

                                        <ul className='no-margin-t'>
                                            <li>it is necessary for us to be able to perform an agreement with you.</li>
                                            <li>it is necessary for our legitimate interests (and your interests and fundamental rights do not override those interests);</li>
                                            <li>if we have your consent (which you can withdraw at any time); or</li>
                                            <li>to comply with a legal obligation ie. rules laid down by courts, statute or regulation.</li>
                                        </ul>
                                        {/* <b>Accordingly, we lawfully use your personal data in the following ways:</b> */}
                                        <p>In order to maximize the user experience, we use collected data in the following ways:</p>

                                        <ul className='no-margin-t'>
                                            <li>Delivering the Services: We use the Registration Data, User Data and Communications Data so that we can deliver the Services to you in an effective, efficient and accurate way. Without it we would not be able to deliver a tailored service to you or respond to issues with these Services that are identified by us or you, or ensure you get the most out of your experience. Therefore, we use this data on the basis that it is necessary for us to be able to perform our agreement with you (i.e. the terms and conditions of the relevant Services) and for our legitimate interests of delivering the Services in this way.</li>

                                            <li>Operating the Services: We use the Technical Data in order to operate and administer the Services including as necessary for testing, analysis, maintenance, support, reporting and hosting of data. Therefore we use this data on the basis that it is necessary for our legitimate interests of operating the Services in this way. We also use Technical Data together with certain Identity Data and Contact Data to assist in security and fraud prevention, system integrity (such as preventing hacking, cheats and spam) and/ or to facilitate our response to a legal process. Therefore we use this data on the basis that it is necessary both for our legitimate interests in protecting the Services in this way and in order that we can comply with a legal obligation.</li>

                                            <li>Marketing communications: We use the Identity Data and Contact Data to inform you of news, offers, events, competitions and promotions by specified media (including, if requested, by way of calendar notifications) which may be of interest to you and/ or we provide such data to our Clubs and/ or our official commercial partners so that they can do this. We give you the option of providing opt-in consent to receive different kinds of direct marketing communications from us or these third parties or deciding not to do so.

    OPT-ING OUT: You can withdraw your consent and opt-out of marketing communications from us at any time by following the instructions provided to you in the relevant communication (for example, the 'unsubscribe' link in an email). We may still need to send service emails to you from time to time.


If you have chosen to receive communications directly from a particular Club or commercial partner, you will be consenting to us passing the data to them to use in accordance with their privacy policy so you should contact them directly if you no longer wish to receive their communications.</li>

                                            <li>Media coverage: We use the Media Data together with Identity and Contact Data for Goal -related publishing and media coverage.</li>

                                            <li>Other purposes: We could have to use your personal data which we hold to protect your or someone else's vital interests for example to make contact in rare emergency situations. We could also have to use your personal data in connection with legal and regulatory matters such as our maintenance of business records, compliance with external reporting requirements and internal policies and procedures and responses to requests by government, law enforcement, regulators, courts, rights holders or other third parties including in respect of the use or misuse of intellectual property, such as our brand or media rights, or those of our licensees/commercial partners or their parties. Therefore we use this data on the basis that it is necessary both for our legitimate interests in protecting, defending and enforcing rights and interests in this way and also so that we can comply with legal obligations.
We will only use your personal data for the purposes for which we collected it as described above, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</li>
                                        </ul>
                                        <b>What if you do not want to share your personal data?</b>
                                        <p>Unless otherwise specified above, generally we collect your personal data on a voluntary basis. However, please note that if you decline to provide certain mandatory personal data, you may not be able to access certain Services and we may be unable to fully respond to any inquiries you make.</p>
                                        <b>5. Disclosure of Your Personal Data</b>
                                        <p>We may disclose or share your personal data in the following circumstances:</p>
                                        <ul className='no-margin-t'>
                                            <li>Third Party Service Providers. We engage third party businesses to provide services to us or to you on our behalf, such as support for the internal operations of our Services (and related services), communications, data storage and delivering communications (including calendar notifications).

                                                In providing their services, they may access, receive, maintain or otherwise use personal data on our behalf. Our service providers only use your personal data in accordance with our strict instructions to provide the relevant services and are not permitted to use your personal data for their own purposes, unless authorized by us to do so. Where this is the case you will be notified by us and provided with their privacy policies so you can understand how they will treat your personal data.
                                                </li>
                                            <li>Commercial Partners. We may also disclose your personal data to our commercial partners where you have consented or requested that we do so. For example, when you enter a competition or sweepstake which is a joint promotion, or you request to receive certain marketing communications. </li>
                                            <li>Publicity and media. We may disclose your personal data publicly via the media, social media or on the Services. </li>

                                            <li>Clubs. Where you allow us to, we will share your personal data with your favorite Clubs to help our Clubs interact with their fans. This Privacy Policy applies to our use of your personal data only, and you should refer to the relevant privacy policy of each relevant Club to understand how they use your personal data. </li>

                                            <li>Legally Required. We may also disclose your personal data if we believe we are required to do so by law, or that doing so is reasonably necessary to comply with legal processes or in the event of a re-organization of the legal or ownership structure of the Goal.
Notwithstanding anything else in this Privacy Policy, we may share aggregate or de-identified information with third parties for research, marketing, analytics and other purposes, provided such information does not identify a particular individual and the individual cannot be re-identified.</li>
                                        </ul>
                                        <b>6. Security of Your Personal Data</b>
                                        <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed (a Data Security Breach). In addition, we limit access to your personal data to those employees, contractors and other third parties who have a business need to know. They will only use your personal data on our instructions and they are subject to a duty of confidentiality.
    We have put in place procedures to deal with any suspected Data Security Breach and will notify you and any applicable regulator where we are legally required to do so.
Where we have given you or you have chosen a password which enables you to access certain Services, you are responsible for using reasonable care in keeping this password confidential.</p>

                                        <b>7. Links to Other Sites</b>
                                        <p>The Services may contain links to other websites, applications and environments that are not owned or controlled by us (the Other Sites). The owners and operators of those Other Sites are responsible for their collection or use of your personal data and you should check their respective privacy policies. Unless specifically referred to otherwise, this Privacy Policy applies to the Services only and not the Other Sites.</p>

                                        <b>8. Data Retention</b>
                                        <p>We will only keep your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any accounting, reporting or other legal requirements, in accordance with our Data Retention Policy.
    To determine the appropriate retention period, we review - in addition to the purposes of use and how we can achieve them - other relevant factors such as the nature and scope of the personal data, the potential risks to data subjects from a Data Security Breach, and the applicable legal requirements, for example the limitation period for which legal claims can be made in court.
For example, all non-activated Goal accounts are deleted after 21 days and all non-authorized child accounts are deleted immediately if a parent withholds consent and after 21 days if a child’s account is not verified.</p>

                                        <b>9.  Your Rights</b>
                                        <p>You have certain rights (depending on the circumstances) in connection with your personal data, which include:</p>
                                        <ul className='no-margin-t'>
                                            <li>Request access to your personal data (commonly known as a “data subject access request”). This enables you to receive a copy of the personal information we hold about you and to check that we are using it lawfully, provided always that this does not adversely affect the rights and freedoms of other people</li>
                                            <li>Request correction of the personal data that we hold about you. Where any of the information we hold about you is incorrect or incomplete we will act promptly to rectify this, including where you have requested us to do so. Users of the Goal website can update their information any time via their account preferences;</li>
                                            <li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to use it. You also have the right to ask us to delete or remove your personal data where you have exercised your right to object to our consent.</li>

                                            <li>Object to use of your personal data where we are relying on our legitimate interests (see above) and there is something about your particular situation which makes you want to object to our use on this ground.</li>

                                            <li>Withdraw your consent to our use of your personal data where we do so in reliance on your consent. Once we have received notification that you have withdrawn your consent, we will no longer use your personal data for the purpose or purposes you originally agreed to, unless we have another legitimate basis for doing so in law;</li>
                                            <li>Request the restriction of use of your personal data. This enables you to ask us to suspend the use of personal data about you, for example if you want us to establish its accuracy or the reason for using it; and</li>
                                            <li>Request the transfer of the personal data you have provided, on the basis of consent or for a contract with us, to you or a third party where technically feasible.
    We are committed to respecting your rights. You may action your rights (as may be applicable) by contacting us using the details provided below and we will comply with your requests within a reasonable period unless we have a lawful reason not to do so.
    Requests should be made in writing and to ensure that personal data is dealt with carefully and confidentially we will require the requestor to provide verification of their identity and all applications must be accompanied by copies of at least two official documents, which show your name, date of birth and current address (for example, driving license, birth/ adoption certificate, passport, recent utility bill).
    In responding to such requests, we will explain the impact of any objections, restrictions or deletions requested.
We will not charge you a fee to exercise your rights unless your request is clearly unfounded or excessive, in which case we may charge you a reasonable fee. Alternatively, we may refuse to comply with the request in such circumstances.</li>
                                        </ul>
                                        <b>10. Contact Us</b>
                                        <p>If you have any questions about this Privacy Policy or how we handle your personal data, please contact us at</p>
                                        <p>Send us a direct message on twitter <a target='_blank' rel="noopener noreferrer" href="https://twitter.com/Goal?lang=en">@Goal</a> and we’ll get back to you. </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div className={`container main-container ${isMobile ? 'no-margin-t' : ''}`}>
                            <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                                <title>{context.state.locale.MetaTag.privacypolicy_title}</title>
                                <meta name="description" content={context.state.locale.MetaTag.privacypolicy_description} />
                            </Helmet>
                            <Row className="">
                                <Col lg={9} className="auto-margin">
                                    <div className="league-heading m">
                                        <div className="title">سياسة الخصوصية</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={9} className="auto-margin">
                                    <div className="static-page-wrap">
                                        <p>في سياسة الخصوصية نستخدم مصطلحات مثل "نحن"، "لنا"  او تعابير مماثلة ونقصد بها  موقع او تطبيق الحرّيف.</p>

                                        <b>1.  الخلفية</b>
                                        <p>يقدم الحرّيف خدمة لعبة دوري كرة القدم الخيالي. أننا مسؤولون عن تقرير كيفية استخدامنا للبيانات الشخصية التي نجمعها عنك، ونضمن أن البيانات الشخصية التي نحتفظ بها هي في كل الأوقات:</p>
                                        <ul className='no-margin-t'>
                                            <li> تستخدم بشكل عادل وشرعي وشفاف </li>

                                            <li>جمعت لأغراض محدودة ومحددة فقط</li>

                                            <li>ملائمة وضرورية ومحدودة لما هو ضروري لتلك الأغراض </li>

                                            <li> تبقى دقيقة وحديثة </li>

                                            <li> لا يتم الاحتفاظ بها لفترة أطول من اللازم </li>

                                            <li>حفظت بشكل آمن</li>
                                        </ul>
                                        {/* <p>سنكون مسؤولين وقادرين على إثبات التزامنا بالتزاماتنا بموجب قانون حماية البيانات ، وتعد سياسة الخصوصية هذه إحدى الطرق التي نقوم بها.</p>
                                        <p>لقد عيّننا أحد قادة حماية البيانات للإشراف على الالتزام بسياسة الخصوصية وأنشطة الامتثال لحماية البيانات. كما قام فريق عمل حماية البيانات بإنشاء فريق مخصص لحماية البيانات لتقديم الدعم اللازم.</p> */}

                                        <b>2. البيانات الشخصية التي نجمعها عنك</b>

                                        <p>البيانات الشخصية تعني أي معلومات عن شخص يمكن تحديد هوية هذا الشخص من خلالها. قد نجمع ونستخدم أنواعًا مختلفة من بياناتك الشخصية:</p>

                                        <p>-   بيانات التسجيل: للوصول إلى بعض عناصر الخدمات واستخدامها، يجب إنشاء حساب مع Goal ، عن طريق تزويدنا بالمعلومات المحددة في صفحة التسجيل ذات الصلة مثل الاسم والبريد الإلكتروني وتاريخ الميلاد والبلد. سنقوم أيضا بجمع اسم مستخدم وكلمة مرور. يرجى ملاحظة أن بعض عناصر الخدمات لن تكون متاحة إذا لم تقدم المعلومات المطلوبة. قد نطلب منك أيضًا أو نسمح لك بتقديم معلومات اختيارية أخرى ، بما في ذلك الجنس، ورقم الهاتف.</p>

                                        <p>-   بيانات الهوية: نطلب منك أيضًا تقديم معلومات تحديد الهوية عندما نوفر عناصر أخرى من الخدمات: الاسم والعمر والجنس او أسماء المستخدمين على الشبكات الاجتماعية.</p>

                                        <p>-   بيانات الاتصال: عندما نريد التواصل معك ، أو تريد أنت ان تتواصل معنا، سنستخدم عنوان بريدك الإلكتروني او أرقام الهاتف.</p>

                                        <p>-   بيانات الصور: نحن نستخدم الصور الفوتوغرافية التي زودنا بها المستخدم لاستخدامنا.</p>
                                        <p>-   بيانات المستخدم: نقوم بتجميع تفاصيل الاستخدام والتفاصيل المتعلقة باستخدامك للخدمات مثل اللغة وإحصائيات تشغيل الألعاب والنتائج والتصنيفات والوقت المستغرق في اللعب وملف تعريف الألعاب والتفضيلات (بما في ذلك الأندية المفضلة) واستجابات الاستبيانات والتعليقات وغيرها من البيانات التي تقدم لنا كجزء من حسابك.</p>
                                        <p>-   بيانات التسويق أو الاتصالات: نحتفظ بتفضيلاتك في تلقي رسائل تسويقية منا ونوادينا وشركائنا التجاريين، وتفضيلات الاتصال الخاصة بك.</p>
                                        <p>-   معلومات تقنية: يتم إنشاء وجمع بيانات معينة تلقائيًا من جانبنا عند استخدامك للخدمات مثل عنوان IP وعنوان MAC ومعرفات الأجهزة الأخرى؛ دخولك من وإلى الخدمات ومن خلالها (بما في ذلك التاريخ والوقت)؛ الصفحات التي شاهدتها أو بحثت عنها، طول الزيارات لصفحات معينة؛ معلومات تفاعل الصفحة (مثل التمرير والنقرات والماوس اوفر)؛ الأساليب المستخدمة للتصفح بعيدًا عن الصفحة.</p>



                                        {/* <ul className='no-margin-t'>
                                            <li>-   بيانات التسجيل:   للوصول إلى بعض عناصر الخدمات واستخدامها ، يجب إنشاء حساب مع Goal ، عن طريق تزويدنا بالمعلومات المحددة في صفحة التسجيل ذات الصلة: الاسم والبريد الإلكتروني وتاريخ الميلاد والبلد. سنقوم أيضا بجمع اسم مستخدم وكلمة مرور. يرجى ملاحظة أن بعض عناصر الخدمات لن تكون متاحة إذا لم تقدم المعلومات المطلوبة. قد نطلب منك أيضًا أو تسمح لك بتقديم معلومات اختيارية أخرى ، بما في ذلك الجنس ، ورقم الهاتف.</li>
                                            <li>-   بيانات الهوية:   نطلب منك أيضًا تقديم معلومات تحديد الهوية عندما نوفر عناصر أخرى من الخدمات: الاسم والعمر والجنس ومقابض / أسماء المستخدمين على الشبكات الاجتماعية</li>
                                            <li>-   بيانات الاتصال:   عندما نريد التواصل معك ، أو أنت معنا ، نستخدم عنوان بريدك الإلكتروني ، أرقام الهاتف ، مقابض / أسماء المستخدمين على الشبكات الاجتماعية والموقع (البلد / مكان الإقامة).</li>
                                            <li>-   بيانات الوسائط:   نحن نستخدم الصور من الصور الفوتوغرافية التي يطلبها المستخدم لاستخدامنا.</li>
                                            <li>-   بيانات المستخدم:   نقوم بتجميع تفاصيل الاستخدام والتفاصيل المتعلقة باستخدامك للخدمات مثل اللغة وإحصائيات تشغيل الألعاب والنتائج والتصنيفات والوقت المستغرق في اللعب وملف تعريف الألعاب والتفضيلات (بما في ذلك الأندية المفضلة) واستجابات الاستبيانات والتعليقات وغيرها من البيانات تقدم لنا كجزء من حسابك.</li>
                                            <li>-   بيانات التسويق و / أو الاتصالات:   نحن نحتفظ بتفضيلاتك في تلقي رسائل تسويقية منا ونوادينا وشركائنا التجاريين ، وتفضيلات الاتصال الخاصة بك.</li>
                                            <li>-   معلومات تقنية:   يتم إنشاء وجمع بيانات معينة تلقائيًا من جانبنا عند استخدامك للخدمات مثل عنوان IP وعنوان MAC ومعرفات الأجهزة الأخرى ؛ دخولك من وإلى الخدمات ومن خلالها (بما في ذلك التاريخ والوقت) ؛ الصفحات التي شاهدتها أو بحثت عنها ؛طول الزيارات لصفحات معينة ؛ معلومات تفاعل الصفحة (مثل التمرير والنقرات والماوس فوق) ؛ الأساليب المستخدمة للتصفح بعيدًا عن الصفحة.</li>
                                        </ul> */}


                                        <b>3. كيف نجمع بياناتك الشخصية</b>

                                        <p>انك تزودنا ببياناتك الشخصية عندما:</p>

                                        <p>- تريد الوصول إلى الخدمات أو اللعب</p>
                                        <p>- تريد إنشاء حساب Goal لتلعب</p>
                                        <p>- تطلب معلومات او تسويق او طلبات اخرى لترسل اليك</p>
                                        <p>قد نتلقى أيضًا بيانات شخصية كجزء من العمليات القانونية التي نتعهد بها لحماية خدماتنا وملكيتنا الفكرية، مثل علامتنا التجارية أو حقوقنا الإعلامية، أو تلك الخاصة بالشركاء التجاريين.</p>
                                        <p>نقوم بتجميع البيانات الفنية تلقائيًا أثناء تفاعلك مع خدماتنا من خلال استخدام ملفات تعريف الارتباط وتقنيات الويب المشابهة الأخرى.</p>
                                        <p>كما نستخدم أدوات الطرف الثالث لمساعدتنا في إدارة وتحليل حضور وسائل الإعلام الاجتماعية لدينا، والإبلاغ عن التعليقات، والإشارات، والمحتويات الأخرى المنشورة عنا على مواقع التواصل الاجتماعي وغيرها من القنوات والمنتديات العامة. تخضع أنشطة الأطراف الثالثة هذه، وممارسات جمع المعلومات ومشاركتها، لشروط موقع أو قناة أو منتدى وسائل الإعلام الاجتماعية ذات الصلة. سنستخدم هذه المعلومات وفقًا لسياسة الخصوصية هذه.</p>
                                        <p>نحن نستخدم خدمة طرف ثالث، Google Analytics، لجمع معلومات قياسية عن سجل الإنترنت وتفاصيل أنماط سلوك الزوار. نحن نفعل ذلك لاكتشاف أشياء مثل عدد الزائرين لأجزاء معينة من الخدمات. هذه المعلومات تستخدم فقط من قبلنا. نحن لا نسمح لشركة Google، بأي شكل من الاشكال ان تكشف عن هويات أي شخص يزور موقعنا على الويب.</p>

                                        {/* <ul className='no-margin-t'>
                                            <li>- الوصول إلى الخدمات أو استخدامها أو تشغيلها ؛ </li>
                                            <li>- إنشاء حساب Goal للخدمات ؛ </li>
                                            <li>- إرسال معلومات الطلب والتسويق والاتصالات الأخرى إليك ؛ </li>
                                        </ul>
                                        <p>
                                            قد نتلقى أيضًا بيانات شخصية كجزء من العمليات القانونية التي نتعهد بها لحماية خدماتنا وملكيتنا الفكرية ، مثل علامتنا التجارية أو حقوقنا الإعلامية ، أو تلك الخاصة بالمرخص لهم / الشركاء التجاريين.
                                            نقوم بتجميع البيانات الفنية تلقائيًا أثناء تفاعلك مع خدماتنا من خلال استخدام ملفات تعريف الارتباط وتقنيات الويب المشابهة الأخرى .
                                            كما نستخدم أدوات الطرف الثالث لمساعدتنا في إدارة وتحليل حضور وسائل الإعلام الاجتماعية لدينا ، والإبلاغ عن التعليقات ، والإشارات ، والمحتويات الأخرى المنشورة عنا على مواقع التواصل الاجتماعي وغيرها من القنوات والمنتديات العامة. تخضع أنشطة الأطراف الثالثة هذه ، وممارسات جمع المعلومات ومشاركتها ، لشروط موقع أو قناة أو منتدى وسائل الإعلام الاجتماعية ذات الصلة. سنستخدم هذه المعلومات وفقًا لسياسة الخصوصية هذه.
                نحن نستخدم خدمة طرف ثالث ، Google Analytics ، لجمع معلومات قياسية عن سجل الإنترنت وتفاصيل أنماط سلوك الزوار. نحن نفعل ذلك لاكتشاف أشياء مثل عدد الزائرين لأجزاء معينة من الخدمات. هذه المعلومات تستخدم فقط من قبلنا بطريقة لا تعرفك.نحن لا نجعل ، ولا نسمح لشركة Google ، بأي محاولة لاكتشاف هويات أي شخص يزور موقعنا على </p> */}

                                        <b>4. كيف ولماذا نستخدم بياناتك الشخصية</b>
                                        <p>سنستخدم بياناتك الشخصية على الأسس التالية:</p>


                                        <ul className='no-margin-t'>
                                            <li>من الضروري بالنسبة لنا أن نكون قادرين على إجراء اتفاق معك </li>

                                            <li>من الضروري لمصالحنا المشروعة (ولا تتعارض مصالحك وحقوقك الأساسية بتلك المصالح</li>

                                            <li>إذا كان لدينا موافقتك (التي يمكنك الانسحاب منها في أي وقت)</li>

                                            <li>الامتثال لالتزام قانوني بناءً على القواعد التي تحددها المحاكم أو النظام الأساسي أو القانون الالكتروني.</li>
                                        </ul>

                                        <p>لتقديم افضل خدمة لعملائنا، نستخدم البيانات بالاشكال التالية:</p>
                                        <p>تقديم الخدمات: نستخدم بيانات التسجيل وبيانات المستخدم وبيانات الاتصالات حتى نتمكن من تقديم الخدمات لك بطريقة فعالة ودقيقة. وبدون ذلك، لن نتمكن من تقديم خدمة مخصصة لك أو الرد على المشكلات المتعلقة بهذه الخدمات التي يتم تحديدها من جانبنا أو من جانبك، أو التأكد من حصولك على أقصى استفادة من تجربتك. لذلك، نستخدم هذه البيانات على أساس أنه من الضروري بالنسبة لنا أن نكون قادرين على تنفيذ اتفاقنا معك (أي شروط وأحكام الخدمات ذات الصلة) ومصالحنا المشروعة لتقديم الخدمات بهذه الطريقة.</p>
                                        <p>تشغيل الخدمات:   نحن نستخدم البيانات الفنية من أجل تشغيل وإدارة الخدمات بما في ذلك عند الضرورة الاختبار والتحليل والصيانة والدعم والإبلاغ واستضافة البيانات. لذلك نستخدم هذه البيانات على أساس أنها ضرورية لمصالحنا المشروعة لتشغيل الخدمات بهذه الطريقة. كما نستخدم البيانات الفنية مع بيانات الهوية وبيانات الاتصال الخاصة للمساعدة في الحماية ومنع الاحتيال، وسلامة النظام (مثل منع القرصنة، والغش، والبريد المزعج) أو تسهيل استجابتنا للعملية القانونية.</p>
                                        <p> لذلك، نستخدم هذه البيانات على أساس أنه من الضروري بالنسبة لمصالحنا المشروعة في حماية الخدمات بهذه الطريقة، حتى نتمكن من الامتثال للالتزام قانوني.</p>
                                        <p>التسويق: نستخدم بيانات الهوية وبيانات الاتصال لإعلامك بالأخبار والعروض والأحداث والمسابقات والعروض الترويجية حسب الوسائط المحددة (بما في ذلك، إذا طُلب ذلك، من خلال إشعارات التقويم) التي قد تهمك أو نقدم هذه البيانات لنادينا أو شركائنا التجاريين الرسميين حتى يتمكنوا من القيام بذلك. نمنحك خيار الموافقة على قبول تلقي أنواع مختلفة من الاتصالات التسويقية المباشرة منا أو من هذه الأطراف الثالثة أو اتخاذ قرار بعدم القيام بذلك.</p>
                                        <p>OPT-ING OUT: يمكنك سحب موافقتك وإلغاء الاشتراك من الاتصالات التسويقية منا في أي وقت عن طريق اتباع الإرشادات المقدمة لك بوسائل اتصال التابعة لنا (على سبيل المثال، رابط "إلغاء الاشتراك" في رسالة بريد إلكتروني). قد نحتاج إلى إرسال رسائل بريد إلكتروني للدعم وتحسين الخدمة من وقت لآخر.</p>
                                        <p>إذا كنت قد اخترت تلقي اتصالات مباشرة من نادي أو شريك تجاري معين ، فستوافق على تمرير البيانات إلينا لاستخدامها وفقًا لسياسة الخصوصية الخاصة به، والتي نقدم لها روابط على موقعنا الإلكتروني، لذلك يجب عليك الاتصال بهم مباشرة إذا لم تعد ترغب في تلقي اتصالاتهم.</p>
                                        <p>تغطية إعلامية: نحن نستخدم  الصور وغيرها من وسائل الإعلام مع الهوية وبيانات الاتصال للنشر والتغطية الإعلامية المرتبطة بالحرّيف.</p>
                                        <p>أغراض أخرى: يمكن أن نستخدم بياناتك الشخصية التي نحتفظ بها لحماية المصالح الحيوية الخاصة بك أو لشخص آخر على سبيل المثال لإجراء اتصال في حالات الطوارئ النادرة. يمكننا أيضًا استخدام بياناتك الشخصية فيما يتعلق بالمسائل القانونية والتنظيمية مثل الحفاظ على سجلات العمل، والامتثال لمتطلبات إعداد التقارير الخارجية والسياسات والإجراءات الداخلية والاستجابات لطلبات الحكومات وأجهزة إنفاذ القانون والهيئات التنظيمية والمحاكم وأصحاب الحقوق أو أطراف ثالثة أخرى بما في ذلك فيما يتعلق باستخدام أو إساءة استخدام الملكية الفكرية، مثل علامتنا التجارية أو حقوقنا الإعلامية، أو حقوق مرخصينا / الشركاء التجاريين أو أطرافهم. لذلك ، نستخدم هذه البيانات على أساس أنه من الضروري بالنسبة لمصالحنا المشروعة في حماية الحقوق والمصالح والدفاع عنها وتطبيقها بهذه الطريقة ، وكذلك حتى نتمكن من الامتثال للالتزامات القانونية.</p>
                                        <p>لن نستخدم بياناتك الشخصية إلا للأغراض التي جمعناها كما هو موضح أعلاه، ما لم نعتبر بشكل معقول أننا نحتاج الى استخدامها لسبب آخر، وهذا السبب متوافق مع الغرض الأصلي. إذا احتجنا إلى استخدام بياناتك الشخصية لغرض غير مرتبط، فسوف نخطرك وسنشرح الأساس القانوني الذي يسمح لنا بالقيام بذلك.</p>
                                        <p>ماذا لو كنت لا ترغب في مشاركة بياناتك الشخصية؟</p>
                                        <p>ما لم يتم النص على خلاف ذلك، فإننا نجمع بياناتك الشخصية بصفة عامة. ومع ذلك، يرجى ملاحظة أنه إذا رفضت تقديم بعض البيانات الشخصية الإلزامية، فقد لا تتمكن من الوصول إلى خدمات معينة وقد لا نتمكن من الرد بشكل كامل على أي استفسارات تقوم بها.</p>

                                        <b>5. الكشف عن البيانات الشخصية الخاصة بك</b>
                                        <p>قد نكشف عن بياناتك الشخصية أو نشاركها في الحالات التالية:</p>
                                        <p>مزودي خدمات كطرف الثالث: ننخرط في أعمال مع طرف ثالث لتقديم خدمات لنا أو لك نيابة عنا، مثل دعم العمليات الداخلية لخدماتنا (والخدمات ذات الصلة)، والاتصالات، وتخزين البيانات وتقديم الاتصالات (بما في ذلك ارسال إشعارات).</p>
                                        <p>عند تقديم خدماتهم، يجوز لهم الوصول إلى البيانات الشخصية أو استقبالها أو الاحتفاظ بها أو استخدامها بطريقة أخرى نيابة عنا. يستخدم مقدمو خدماتنا بياناتك الشخصية فقط وفقًا لتعليماتنا الصارمة لتقديم الخدمات ذات الصلة ولا يُسمح لنا باستخدام بياناتك الشخصية لأغراضهم الخاصة، ما لم يكن مصرحًا لنا بذلك. وفي هذه الحالة ، سيتم إعلامك من جانبنا وتقديمها بسياسات الخصوصية الخاصة بهم حتى تتمكن من فهم كيفية تعاملهم مع بياناتك الشخصية.</p>
                                        <p>شركائنا التجاريون: قد نكشف أيضًا عن بياناتك الشخصية لشركائنا التجاريين حيث وافقت أو طلبت منا القيام بذلك. على سبيل المثال، عندما تدخل مسابقة أو ترويجًا مشتركًا، أو تطلب تلقي بعض الاتصالات التسويقية.</p>
                                        <p>الدعاية ووسائل الإعلام:  قد نكشف عن بياناتك الشخصية بشكل عام عبر وسائل الإعلام أو الشبكات الاجتماعية أو الخدمات.</p>
                                        <p>الأندية: عندما تسمح لنا بذلك، سنقوم بمشاركة بياناتك الشخصية مع النوادي المفضلة لديك لمساعدة نوادينا على التفاعل مع معجبيهم. تنطبق سياسة الخصوصية هذه على استخدامنا لبياناتك الشخصية فقط، ويجب عليك الرجوع إلى سياسة الخصوصية ذات الصلة الخاصة بكل نادي ذي صلة لفهم كيفية استخدامهم لبياناتك الشخصية.</p>
                                        <p>مطلوب قانونيا: قد نقوم أيضًا بالإفصاح عن بياناتك الشخصية إذا اعتقدنا أنه مطلوب منا القيام بذلك بموجب القانون، أو أن القيام بذلك ضروري بشكل معقول للامتثال للعمليات القانونية أو في حالة إعادة تنظيم البنية القانونية أو الملكية الخاصة بـ Goal</p>
                                        <p>بغض النظرعن أي شيء آخر في سياسة الخصوصية هذه، يجوز لنا مشاركة معلومات مجمعة أو غير محددة مع أطراف ثالثة لأغراض الأبحاث والتسويق والتحليلات وغيرها من الأغراض، شريطة ألا تحدد هذه المعلومات شخصًا معينًا ولا يمكن إعادة تعريف الفرد.</p>


                                        <b>6. أمن البيانات الشخصية الخاصة بك</b>
                                        <p>لقد وضعنا تدابير أمنية مناسبة لمنع فقدان بياناتك الشخصية أو استخدامها أو الوصول إليها بطريقة غير مصرح بها أو تغييرها أو الكشف عنها (اختراق أمن البيانات). بالإضافة إلى ذلك، فإننا نحصر الوصول إلى بياناتك الشخصية إلى هؤلاء من الموظفين والمتعاقدين وغيرهم من الأطراف الثالثة الذين يحتاجون إلى معرفتها للتمكن من القيام بأعمالهم. سوف يستخدمون بياناتك الشخصية فقط بناء على تعليماتنا ويخضعون للسرية.</p>
                                        <p>لقد وضعنا إجراءات للتعامل مع أي خرق لأمان بيانات مشتبه به، وسنعلمك وأي جهة قانونية معمول بها حيث نطالب قانونًا بذلك.</p>
                                        <p>عندما نقدم لك أو عندما تختار كلمة مرور تمكنك من الوصول إلى خدمات معينة، فأنت مسؤول عن استخدامها بعناية للحفاظ على سرية كلمة المرور هذه.</p>

                                        <b>7. روابط لمواقع أخرى</b>
                                        <p>قد تحتوي الخدمات على روابط لمواقع وتطبيقات أخرى غير مملوكة لنا أو خاضعة لسيطرتنا (مواقع أخرى). إن مالكي ومشغلي تلك المواقع هم المسؤولون عن جمع أو استخدام بياناتك الشخصية ويجب عليك مراجعة سياسات الخصوصية الخاصة بكل منهم. ما لم تتم الإشارة إلى خلاف ذلك على وجه التحديد، تنطبق سياسة الخصوصية هذه على الخدمات فقط وليس على المواقع الأخرى.</p>

                                        <b>8. الاحتفاظ بالبيانات</b>
                                        <p>سنحتفظ فقط ببياناتك الشخصية طوال المدة اللازمة لتحقيق الأغراض التي قمنا بجمعها من أجلها، بما في ذلك لأغراض تلبية أي متطلبات محاسبية أو تقارير أو غيرها من المتطلبات القانونية، وفقًا لسياسة الاحتفاظ بالبيانات الخاصة بنا.</p>
                                        <p>لتحديد فترة الاستبقاء المناسبة، نقوم بمراجعة - بالإضافة إلى أغراض الاستخدام وكيف يمكننا تحقيقها - عوامل أخرى ذات صلة مثل طبيعة ونطاق البيانات الشخصية، والمخاطر المحتملة لموضوعات البيانات من خرق أمن البيانات، والمتطلبات القانونية المعمول بها، على سبيل المثال فترة التقادم التي يمكن تقديم دعاوى قانونية ضدها في المحكمة.</p>
                                        <p>على سبيل المثال ، يتم حذف جميع حسابات Goal التي لم يتم تفعيلها بعد 21 يومًا ويتم حذف جميع الحسابات الفرعية غير المصرح بها على الفور في حالة احتجاز أحد الوالدين بعد 21 يومًا إذا لم يتم التحقق من حساب الطفل.</p>
                                  

                                        <b>9.  حقوقك	</b>

                                        <p>لديك حقوق معينة (وفقًا للظروف) فيما يتعلق ببياناتك الشخصية، والتي تشمل:</p>
                                        <p>-   طلب الدخول الى بياناتك الشخصية (المعروفة باسم "طلب الوصول إلى موضوع البيانات"). يتيح لك هذا الحصول على نسخة من المعلومات الشخصية التي نحتفظ بها عنك والتحقق من أننا نستخدمها بشكل قانوني، شريطة أن لا يؤثر ذلك بشكل سلبي على حقوق وحريات الأشخاص الآخرين.</p>
                                        <p>
                                            -   طلب التصحيح لبيانات الشخصية التي نحتفظ بها عنك. عندما تكون أي من المعلومات التي نحتفظ بها عنك غير صحيحة أو غير كاملة ، فسوف نتصرف على الفور لتصحيح ذلك، بما في ذلك المكان الذي طلبت منا القيام بذلك. يمكن لمستخدمي موقع Goal تحديث معلوماتهم في أي وقت من خلال تفضيلاتهم.
-   طلب محو من البيانات الشخصية الخاصة بك. يمكّنك هذا من مطالبتنا بحذف أو إزالة البيانات الشخصية في حالة عدم وجود سبب وجيه لمواصلة استخدامها. لديك أيضًا الحق في مطالبتنا بحذف بياناتك الشخصية أو إزالتها حيث مارست حقك في الاعتراض على موافقتنا .</p>
                                        <p>-   بحسب ما سيتم استخدام من بياناتك الشخصية، أننا نعتمد على مصالحنا المشروعة (انظر أعلاه) وهناك شيء يتعلق بوضعك الخاص مما يجعلك ترغب في الاعتراض على استخدامنا على هذا الأساس.</p>
                                        <p> -   سحب موافقتك لاستخدامنا لبياناتك الشخصية حيث نقوم بذلك اعتماداً على موافقتك. بمجرد تلقينا إخطارًا بأنك قد سحبت موافقتك، لن نستخدم بياناتك الشخصية بعد الآن للغرض أو الأغراض التي وافقت عليها أصلاً، ما لم يكن لدينا أساس مشروع آخر للقيام بذلك في القانون.</p>
                                        <p>-  اطلب القيد من استخدام البيانات الشخصية الخاصة بك. يمكّنك هذا من مطالبتنا بتعليق استخدام البيانات الشخصية عنك، على سبيل المثال إذا كنت تريد منا إثبات دقتها أو سبب استخدامها.
-   طلب النقل للبيانات الشخصية التي قدمتها، على أساس الموافقة أو التعاقد معنا، لك أو لطرف ثالث حيثما كان ذلك مجديًا من الناحية التقنية.</p>
                                        <p>نحن ملتزمون باحترام حقوقك. يجوز لك عمل حقوقك (حسب الاقتضاء) عن طريق الاتصال بنا باستخدام التفاصيل الواردة أدناه، وسنلتزم بطلباتك في غضون فترة معقولة ما لم يكن لدينا سبب قانوني لعدم القيام بذلك.</p>
                                        <p>ينبغي تقديم الطلبات كتابةً وضمان التعامل مع البيانات الشخصية بعناية وسرية ، وسوف نطلب من مقدم الطلب تقديم التحقق من هويته ويجب أن تكون جميع الطلبات مصحوبة بنسخ من اثنتين من الوثائق الرسمية على الأقل، والتي تُظهر اسمك وتاريخ الميلاد والعنوان الحالي (على سبيل المثال ، رخصة القيادة ، شهادة الميلاد / التبني ، جواز السفر ، فاتورة مصدقة حديثة).</p>
                                        <p>في الرد على هذه الطلبات، سنوضح تأثير أي اعتراض أو قيود أو عمليات حذف مطلوبة.</p>
                                        <p>لن نفرض عليك أي رسوم لممارسة حقوقك إلا إذا كان طلبك غير واضح أو مفرط، وفي هذه الحالة قد نقوم بتحصيل رسوم معقولة منك. بدلاً من ذلك، قد نرفض الامتثال للطلب في مثل هذه الظروف.</p>                                       

                                        
                                        <b>10. اتصل بنا</b>
                                        
                                        <p>تواصل معنا بالخاص على حساب التويتر الرسمي وسنقوم بالرد على استفساراتك. <a target='_blank' rel="noopener noreferrer" href="https://twitter.com/Goal?lang=ar">Goal@</a></p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                )
                }
            </MyContext.Consumer>
        )
    }
}