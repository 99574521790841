/* eslint-disable no-unused-vars, eqeqeq, no-sequences*/
import React, { Fragment } from 'react';
import { Row, Col, Button, Modal, Badge, Tab, Nav, NavItem } from 'react-bootstrap';

import Images from '../components/images';
import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import moment from 'moment';
import Moment from 'react-moment';

import { MyContext } from '../context';
import Slider from "react-slick";
import RosterLoader from '../views/RosterLoader';

let momentLib;
export default class PlayerCard extends React.Component {


    // Methods
    // ------------------------------
    handleTabSelect = (SelectedPlayerCardView) => {
        // alert(`selected ${SelectedPlayerCardView}`);
        this.setState({ SelectedPlayerCardView });
    }
    getUtcToLocal = (date) => {
        return moment.utc(date).local().format();
    }

    // GET_PLAYERCARD_SL_MASTER_DATA
    getPLayerCardMasterData = (PlayerData) => {
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "player_team_id": PlayerData.player_team_id,
            "player_uid": PlayerData.player_uid,
            "is_pick_percentage_req": _.isUndefined(PlayerData.pick_percentage) ? 1 : 0
        }
        WSManager.Rest(API.GET_PLAYERCARD_SL_MASTER_DATA, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                responseJson = responseJson.data;
                if(param.is_pick_percentage_req == 0) {
                    responseJson.percentage = PlayerData.pick_percentage
                }
                this.setState({
                    PlayerDataMaster: responseJson,
                    // SelectedWeek: responseJson.active_week // responseJson.next_week
                })
                let AllWeekData = responseJson.master_data
                let SlideItemLength = responseJson.master_data.length;
                if (SlideItemLength > 0) {
                    var tempWeek = {}
                    if (SlideItemLength > 1) {
                        tempWeek = AllWeekData[SlideItemLength-2]
                    } else {
                        tempWeek = AllWeekData[SlideItemLength-1]
                    }

                    // var tmpSelectedWeek = '';
                    // _.map(AllWeekData, (week) => {
                    //     if(this.state.SelectedWeek == '' && responseJson.next_week == week.week){
                    //         tmpSelectedWeek = week;
                    //     }else if(week.week == this.state.SelectedWeek.week){
                    //         tmpSelectedWeek = week;
                    //     }
                    // })
                    this.setState({
                        AllWeek: AllWeekData,
                        SelectedWeek: tempWeek
                    })
                }
                this.handlerChangeWeeek(PlayerData, tempWeek, true)
            }
        })
    }

    handlerChangePlayerRole = (player_role) => {
        let PlayerData = this.state.PlayerData;
        PlayerData.player_role = player_role;
        this.setState((state, props) => { return { 
            PlayerData: PlayerData
         }});
         _.map(this.state.LineupsList, (item, idx) => {
             if (this.props.rosterContainsObject(item, this.props.LineupsList)) {
                switch (player_role) {
                    case 1:
                        if (item.player_role != 2) {
                            item.player_role = 0;
                        }
                        break;
                    case 2:
                        if (item.player_role != 1) {
                            item.player_role = 0;
                        }
                        break;
                    default:
                        break;
                }
                if (item.player_uid == PlayerData.player_uid) {
                    item.player_role = String(player_role);
                }
            }
            return item;
        })
        this.props.LineupUpdate(this.state.LineupsList);
    }
    // GET_PLAYERCARD
    handlerChangeWeeek = (PlayerData, week, autoslide) => {
        this.setState({
            SelectedWeek: week,
            posting: true,
        })
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "player_team_id": PlayerData.player_team_id,
            "player_uid": PlayerData.player_uid,
            "player_team": PlayerData.team_abbreviation,
            "against_team": PlayerData.against_team,
            "week": week.week
        }
        WSManager.Rest(API.GET_PLAYERCARD, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                responseJson = responseJson.data;
                this.setState({
                    next_week_start_time: responseJson.next_week_start_time,
                    PlayerDataDetail: responseJson.player_card,
                    PlayerMatches: responseJson.player_card.matches,
                    posting: false
                }, () => {
                    const { PlayerDataDetail } = this.state;
                    try {
                        this.PlayerStatsCollapseToggle(PlayerDataDetail.stats_match_list[0], true)
                    } catch (error) {
                        console.log(error)
                    }
                })
                if(autoslide) {
                    let SliderPos = 0;
                    let SlideItemLength = !_.isUndefined(this.state.AllWeek) ? this.state.AllWeek.length : 0;
                    if (SlideItemLength > 4 && SlideItemLength < 9) {
                        SliderPos = 3;
                    } else if (SlideItemLength > 8 && SlideItemLength < 13) {
                        SliderPos = 7;
                    } else if (SlideItemLength > 12 && SlideItemLength < 17) {
                        SliderPos = 11;
                    } else if (SlideItemLength > 16 && SlideItemLength < 21) {
                        SliderPos = 15;
                    } else if (SlideItemLength > 20 && SlideItemLength < 25) {
                        SliderPos = 19;
                    } else if (SlideItemLength > 24 && SlideItemLength < 29) {
                        SliderPos = 23;
                    } else if (SlideItemLength > 28 && SlideItemLength < 33) {
                        SliderPos = 27;
                    }
                    
                    if (!_.isNull(this.slider)) {
                        this.slider.slickGoTo(SliderPos)
                    }
                }
            } else {
                this.setState({
                    posting: false
                })
            }
        })

    }

    activeKeyInit = (PlayerData) => {
        this.setState({
            SelectedPlayerCardView: this.props.rosterContainsObject(PlayerData, this.props.LineupsList) ? 'ProfileShortView' : 'ProfileFullView'
        })
    }
    isFloat = (n) => {
        n = parseFloat(n)
        return Number(n) === n && n % 1 !== 0;
    }

    DetailTabSwitch = (SelectedDetailTab) => {
        this.setState({SelectedDetailTab})
    }
    PlayerStatsCollapseToggle = (item, manual) => {
        if(manual) {
            this.setState({
                PlayerStatsOpen: item.season_game_uid
            })
        } else {
            this.setState({
                PlayerStatsOpen: this.state.PlayerStatsOpen != item.season_game_uid ? item.season_game_uid : ''
            })
        }
    }
    // Lifecycle
    // ------------------------------
    constructor(props) {
        super(props);
        this.state = {
            SelectedWeek: '',
            next_week_start_time: '',
            PlayerData: props.PlayerData,
            PlayerDataMaster: [],
            PlayerDataDetail: [],
            LineupsList: props.LineupsList || [],
            BenchPlayerList: [],
            SelectedPlayerCardView: '', //props.isPlayerCardFieldView && props.rosterContainsObject(props.PlayerData, props.LineupsList) && !(props.rosterContainsObject(props.PlayerData, this.state.BenchPlayerList)) ? 'ProfileShortView' : 'ProfileFullView',
            PlayerMatches: [],
            SelectedDetailTab: 'Result',
            PlayerStatsOpen: ''
        }
    }
    componentWillMount() {
        this.getPLayerCardMasterData(this.props.PlayerData);
        let BenchPlayerList = _.filter(this.props.LineupsList, function (o) { return o.is_bench_player == 1 });
        this.setState({
            BenchPlayerList: BenchPlayerList,
            SelectedPlayerCardView: this.props.isPlayerCardFieldView && this.props.rosterContainsObject(this.props.PlayerData, this.props.LineupsList) && !this.props.rosterContainsObject(this.props.PlayerData, BenchPlayerList) ? 'ProfileShortView' : 'ProfileFullView'
        })        
    }
    componentDidMount() {
        if(this.props.language == 'ar') momentLib = require('moment/locale/ar');
    }
    ImageCallback = (PlayerData) => {
        PlayerData.player_image = Images.PLAYER_IMAGE
    }
    render() {
        var settings = {
            rtl: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: false,
            centerPadding: '0px',
            initialSlide: 0,
        };
        const {
            SelectedPlayerCardView,
            SelectedWeek,
            PlayerData,
            PlayerDataMaster,
            PlayerDataDetail,
            // next_week_start_time,
            posting,
            BenchPlayerList,
            AllWeek,
            PlayerMatches,
            SelectedDetailTab,
            PlayerStatsOpen
        } = this.state;
        const {
            isPlayerCardFieldView,
            _displayPosition,
            PitchViewFieldRef,
        } = this.props;

        // let TotalPoints = _.sumBy(PlayerDataDetail.display_card, function (o) { return parseFloat(o.Point); })
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.PlayerCardModalShow}
                        onHide={() => this.props.modalHide('PlayerCardModalShow')}
                        dialogClassName="custom-modal player-card-modal">
                        <Modal.Body>
                            <div className="modal-header no-padding border-0">
                                <button className="close player-card-close" onClick={() => this.props.modalHide('PlayerCardModalShow')}><span aria-hidden="true">×</span></button>
                            </div>
                            {
                                PlayerData.injury_status == 1 &&
                                <div className="injury-status-row"><i />{PlayerData.injury}</div>
                            }

                            <header className="player-card-header">
                                <figure className="player-card-thumbnail">
                                    <span className='thumbnail'>
                                        <img src={PlayerData.player_image} alt="" onError={() => this.ImageCallback(PlayerData)} />
                                    </span>
                                    {/* <img src={PlayerData.flag} alt="" className="falg-thumb"/> */}
                                    <img src={PlayerDataMaster && PlayerDataMaster.player_team ? PlayerDataMaster.player_team == PlayerDataMaster.home ? PlayerDataMaster.home_flag : PlayerDataMaster.away_flag : ''} alt="" className="falg-thumb"/>
                                    
                                </figure>
                                {
                                    isPlayerCardFieldView && this.props.is_editable == 1 &&
                                    <div className="player-card-header-action">
                                        {
                                            this.props.rosterContainsObject(PlayerData, this.props.LineupsList) ?
                                                <div className="player-action-btn" onClick={() => (this.props.RemovePlayerFromLineup(PlayerData), this.activeKeyInit(PlayerData))}>
                                                    <span className="ic">
                                                        <img src={Images.REMOVE_PLAYER_BTN} alt="" />
                                                    </span>
                                                    <div className="m-t-sm">{context.state.locale.PlayerCard.remove_player}</div>
                                                </div>
                                                :
                                                <div className="player-action-btn" onClick={() => (this.props.AddPlayerToLineup(PlayerData), this.activeKeyInit(PlayerData))}>
                                                    <span className="ic">
                                                        <img src={Images.ADD_PLAYER_BTN} alt=""/>
                                                    </span>
                                                    <div className="m-t-sm">{context.state.locale.PlayerCard.add_player}</div>
                                                </div>
                                        }
                                    </div>
                                }
                                
                                <div className="player-header-content">
                                    <div className="title">
                                        <span className="font-light">{PlayerData.full_name}</span>
                                        {/* <span className="text-black m-l-xs">{PlayerData.last_name}</span> */}
                                    </div>
                                    {/* <div className="subtitle text-italic-bold text-muted">{PlayerDataMaster && PlayerDataMaster.team_name ? PlayerDataMaster.team_name : PlayerData && PlayerData.team_name ? PlayerData.team_name : ''}</div> */}
                                    <div className="subtitle text-italic-bold text-muted">{PlayerDataMaster && PlayerDataMaster.team_name ? PlayerDataMaster.team_name : ''}</div>
                                    <Badge className='badge-player'>{_displayPosition(PlayerData.position, 'NAME', true)}</Badge>
                                </div>
                                <div className="player-card-feature">
                                    {
                                        PlayerDataMaster.most_picked_captain == 1 &&
                                        <span>
                                            <i className="icon-star"></i>
                                            {context.state.locale.PlayerCard.most_picked_captain}
                                        </span>
                                    }
                                    {
                                        PlayerDataMaster.most_picked_player == 1 &&
                                        <span className='m-l-sm'>
                                            <i className="icon-star"></i>
                                            {context.state.locale.PlayerCard.most_picked_player}
                                        </span>
                                    }

                                </div>
                            </header>
                            
                            <div className={`player-card-stats ${SelectedPlayerCardView == 'ProfileShortView' ? 'shadow' : ''}`}>
                                <div className="cell">
                                    <div className="label">{context.state.locale.PlayerCard.form}</div>
                                    <div className="value">
                                        {
                                            !_.isEmpty(PlayerDataMaster.avg_form) ? PlayerDataMaster.avg_form : '--'
                                        }
                                    </div>
                                </div>
                                <div className="cell">
                                    <div className="label">{context.state.locale.PlayerCard.price}</div>
                                    <div className="value">{context.state.locale.PlayerCard.currency_label} {PlayerData.salary}{NC.SALARY_UNIT}
                                    </div>
                                </div>
                                <div className="cell">
                                    <div className="label">{context.state.locale.PlayerCard.selected_by}</div>
                                    <div className="value">
                                        {
                                            !_.isEmpty(PlayerDataMaster) ? PlayerDataMaster.percentage + '%' : '--'
                                        }
                                    </div>
                                </div>
                                <div className="cell">
                                    <div className="label">{context.state.locale.PlayerCard.total_points}</div>
                                    <div className="value">
                                        {
                                            PlayerDataMaster.total_points ? PlayerDataMaster.total_points : '--'
                                        }
                                    </div>
                                </div>
                            </div>
                            
                            <Tab.Container id="PlayerCardDetail"
                                activeKey={SelectedPlayerCardView}
                                onSelect={this.handleTabSelect}>
                                <Tab.Content animation>
                                    <Tab.Pane eventKey="ProfileShortView">
                                        <div className="player-field-action">
                                            <Button className={`action-btn ${PlayerData.player_role == 1 ? 'selected' : ''}`} block onClick={PlayerData.player_role != 1 ? () => this.handlerChangePlayerRole(1) : null}>
                                                <i className="icon-check"></i>
                                                <i className="statuc-ic">
                                                    <img src={Images.CAPTAIN_IC} alt=""/>
                                                </i>
                                                <div className="label">{context.state.locale.PlayerCard.make_captain}</div>
                                            </Button>
                                            <Button className={`action-btn m-t-md ${PlayerData.player_role == 2 ? 'selected' : ''}`} block onClick={PlayerData.player_role != 2 ? () => this.handlerChangePlayerRole(2) : null}>
                                                <i className="icon-check"></i>
                                                <i className="statuc-ic">
                                                    <img src={Images.VICE_CAPTAIN_IC} alt=""/>
                                                </i>
                                                <div className="label">{context.state.locale.PlayerCard.make_vice_captain}</div>
                                            </Button>
                                        </div>
                                    </Tab.Pane>
                                    
                                    <Tab.Pane eventKey="ProfileFullView" className='profile-full-view'>
                                        <Slider className='player-week-slider' ref={slider => (this.slider = slider)} {...settings}>
                                            {
                                                _.map(AllWeek, (item, idx) => {
                                                    return (
                                                        <div className={`slider-item${item.week == SelectedWeek.week ? ' selected' : ''}`} key={idx}
                                                            onClick={() => this.handlerChangeWeeek(PlayerData, item)}
                                                            >
                                                            <div className="label">{context.state.locale.PlayerCard.week} {item.week}</div>
                                                            <div className="value">
                                                            {
                                                                item.week != PlayerDataMaster.next_week ?
                                                                item.score +' '+ context.state.locale.PlayerCard.pts
                                                                :
                                                                <small>{context.state.locale.PlayerCard.manage_team}</small>
                                                            }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Slider>
                                        <div className={`roster-filter-loader fade ${posting ? 'in' : null}`}><RosterLoader bsStyle='red'/></div>

{/* May 2019 */}
    <Row className='playercard-tabs'>
        <Col onClick={() => this.DetailTabSwitch('Result')} className={`tab ${SelectedDetailTab == 'Result' ? 'active' : ''}`} xs={6}>{context.state.locale.PlayerCard.result}</Col>
        <Col onClick={() => this.DetailTabSwitch('Fixtures')} className={`tab ${SelectedDetailTab == 'Fixtures' ? 'active' : ''}`} xs={6}>{context.state.locale.PlayerCard.fixtures}</Col>
    </Row>
    {
        SelectedDetailTab == 'Fixtures' &&
        <div className="fixture-card-tbl m-b">
        {
            !_.isEmpty(PlayerMatches) &&
            <Fragment>
                <div className="tbl-row head">
                    <div className="cell">GW</div>
                    <div className="cell">{context.state.locale.PlayerCard.upcoming_fixtures}</div>
                </div>
                {
                    _.map(PlayerMatches, (item, idx) => {
                        return(
                            <div className="tbl-row" key={idx}>
                                <div className="cell">{item.week}</div>
                                <div className="cell">
                                    <div className="fixture-tiles">
                                        <div className="col">{item.home_team_name}</div>
                                        <div className="col date">
                                            <Moment date={this.getUtcToLocal(item.season_scheduled_date)} format="DD MMM" />
                                        </div>
                                        <div className="col">{item.away_team_name}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Fragment>
        }
    </div>
    }
{/* End */}
{
    SelectedDetailTab == 'Result' &&
        <Fragment>
            <div className="m-b m-t-sm">
                {
                    _.map(PlayerDataDetail.stats_match_list, (item, idx) => {
                        return (
                            <div className={`player-stats-collapse ${PlayerStatsOpen == item.season_game_uid ? 'active' : ''}`} key={idx}>
                                <div className="player-stats-header" onClick={() => this.PlayerStatsCollapseToggle(item)}>
                                        {
                                            // PlayerDataDetail.team_abbreviation == item.away ?
                                            PlayerDataMaster.team_abbreviation == item.away ?
                                            <div className="top-detail">
                                                <span className="vs">{context.state.locale.PlayerCard.vs_text}</span> <span className="team-name">{item.home_team_name}</span><span className="date"> | <Moment date={this.getUtcToLocal(item.scheduled_date)} format="DD MMM" /></span>
                                                <i className="icon-nextArrow"></i>
                                            </div>
                                            : 
                                            <div className="top-detail">
                                                <span className="vs">{context.state.locale.PlayerCard.vs_text}</span> <span className="team-name">{item.away_team_name}</span><span className="date"> | <Moment date={this.getUtcToLocal(item.scheduled_date)} format="DD MMM" /></span>
                                                <i className="icon-nextArrow"></i>
                                            </div>
                                        }
                                </div>
                                <div className="player-stats-content">
                                    {
                                        !_.isEmpty(item.display_card) ?
                                        <div className="profile-stats-list">
                                            <div className="profile-stats-item tbl-header">
                                                <div className="cell lg">{context.state.locale.PlayerCard.statistics}</div>
                                                <div className="cell">{context.state.locale.PlayerCard.value}</div>
                                                <div className="cell">{context.state.locale.PlayerCard.points}</div>
                                            </div>
                                            {
                                                _.map(item.display_card, (obj, idx) => {
                                                    return (
                                                        <div className="profile-stats-item" key={idx}>
                                                            <div className="cell lg">{obj.Label}</div>
                                                            <div className="cell">{obj.Value}</div>
                                                            <div className="cell">{obj.Point}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="profile-stats-item tbl-footer">
                                                <div className="cell lg text-bold">{context.state.locale.PlayerCard.total_points}</div>
                                                <div className="cell"></div>
                                                <div className="cell text-black">{item.match_points}</div>
                                            </div>
                                        </div>
                                        :
                                        <div className='player-card-schedule'>
                                            {context.state.locale.PlayerCard.no_record_msz}
                                            {/* {
                                                next_week_start_time != '' ?
                                                <Moment date={this.getUtcToLocal(next_week_start_time)} format="DD MMM H:mm" />
                                                : '--'
                                            } */}
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
}
                                    </Tab.Pane>
                                    {
                                         SelectedPlayerCardView == 'ProfileShortView' ||
                                         (SelectedPlayerCardView == 'ProfileFullView' && SelectedDetailTab == 'Result') ?
                                        <Fragment>
                                            {
                                                this.props.user_join_count == 1 && !this.props.isMakeTransferActive && isPlayerCardFieldView &&
                                                <div className="player-field-action no-padding-t">
                                                    {
                                                        PlayerData.substitute ?
                                                        <Button className='action-btn substitute' block onClick={() => PitchViewFieldRef.current.SubstituteEnd(PlayerData)}>
                                                            <div className="label">{context.state.locale.PlayerCard.cancel_substitute}</div>
                                                        </Button>
                                                        : 
                                                        <Button className='action-btn substitute' block onClick={() => PitchViewFieldRef.current.SubstituteStart(PlayerData)}>
                                                            <div className="label">{context.state.locale.PlayerCard.substitute}</div>
                                                        </Button>
                                                    }
                                                </div>
                                            }
                                        </Fragment>
                                        : ''
                                    }

                                    {
                                        SelectedPlayerCardView == 'ProfileShortView' &&
                                        <Nav className='player-card-footer'>
                                            <NavItem eventKey="ProfileFullView">{context.state.locale.PlayerCard.view_full_profile}</NavItem>
                                        </Nav>
                                    }
                                    {
                                        SelectedPlayerCardView == 'ProfileFullView' &&
                                        isPlayerCardFieldView &&
                                        this.props.rosterContainsObject(PlayerData, this.props.LineupsList) &&
                                        !this.props.rosterContainsObject(this.props.PlayerData, BenchPlayerList) && 
                                        <Nav className='player-card-footer'>
                                            <NavItem onClick={() => this.DetailTabSwitch('Result')} eventKey="ProfileShortView">{context.state.locale.PlayerCard.back}</NavItem>
                                        </Nav>
                                    }
                                </Tab.Content>
                            </Tab.Container>
                        </Modal.Body>
                    </Modal>
                )}
            </MyContext.Consumer>
        )
    }
}