import React, { Component } from 'react'
import {  Button, Modal } from 'react-bootstrap';
import _ from "lodash";

import { MyContext } from '../context';

export default class SubstituteModal extends Component {
    render() {
        const { isOpen, modalHide, SubstituteData } = this.props
        console.log(SubstituteData, 'SubstituteData')
        return (
            <MyContext.Consumer>
                {(context) => (
                    
                    <Modal
                        show={isOpen}
                        onHide={() => modalHide('SubstituteModalshow')}
                        dialogClassName="custom-modal"
                    >
                        <Modal.Body className='substitute-modal-body'>
                                <div className="substitute-modal-header">
                                    <i className="icn" />
                                    <h3 className='title'>{context.state.locale.substitute_modal.hero_title}</h3>
                                </div>
                                <div className="substitute-modal-content">
                                    <div className="substitute-tbl-head">
                                        <div className="tbl-col">
                                            <i className="icon-nextArrow" />
                                            {context.state.locale.PitchView.sub_out}
                                        </div>
                                        <div className="tbl-col">
                                            <i className="icon-nextArrow" />
                                            {context.state.locale.PitchView.sub_in}
                                        </div>
                                    </div>
                                    {
                                        _.map(SubstituteData, (item, idx) => {
                                            return (
                                                <div className="substitute-tbl-row" key={idx}>
                                                    <div className="tbl-col">
                                                        <figure className="jersy-thumb">
                                                            <img src={item.out.jersey} alt=""/>
                                                        </figure>
                                                        <div className="overflow">
                                                            <div className="text-bold text-capitalize text-truncate">{item.out.full_name}</div>
                                                            <div className="text-muted text-uppercase small text-truncate">{item.out.team_abbr_lang}</div>
                                                        </div>
                                                    </div>
                                                    <div className="tbl-col">
                                                        <div className="overflow">
                                                            <div className="text-bold text-capitalize text-truncate">{item.in.full_name}</div>
                                                            <div className="text-muted text-uppercase small text-truncate">{item.in.team_abbr_lang}</div>
                                                        </div>
                                                        <figure className="jersy-thumb">
                                                            <img src={item.in.jersey} alt=""/>
                                                        </figure>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                        </Modal.Body>
                        <ul className='player-card-footer'>
                            <li><Button bsStyle="default" onClick={() => modalHide('SubstituteModalshow')} type='button' >{context.state.locale.Thankyou.ok_text}</Button></li>
                        </ul>
                    </Modal>
                )}
            </MyContext.Consumer>
        )
    }
}
