/* eslint-disable eqeqeq */
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { MyContext } from '../context'
import * as qs from 'query-string'
export default class HowToPlay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    const { location } = this.props
    const parsed = qs.parse(location.search)
    this.setState((state, props) => {
      return {
        isMobile: parsed.isMobile,
      }
    })
  }
  render() {
    const { isMobile } = this.state
    return (
      <MyContext.Consumer>
        {(context) =>
          context.state.language == 'en' ? (
            <div
              className={`container main-container ${
                isMobile ? 'no-margin-t' : ''
              }`}
            >
              <Helmet
                titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}
              >
                <title>{context.state.locale.MetaTag.howtoplay_title}</title>
                <meta
                  name="description"
                  content={context.state.locale.MetaTag.howtoplay_description}
                />
              </Helmet>
              <Row className="">
                <Col lg={9} className="auto-margin">
                  <div className="league-heading m">
                    <div className="title">HOW TO PLAY</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={9} className="auto-margin">
                  <div className="static-page-wrap">
                    <p>Sign up on Goal Fantasy league’s website/app.</p>
                    <p>
                      Upon sign up you will be given 100 Million USD to
                      establish your team.
                    </p>

                    <p>
                      The price of players varies from 4.0 - 13.0 Million USD
                      depending upon their performances. Initially they start
                      with a price that depends upon how the player played last
                      season and then the price may change accordingly depending
                      on his current performance.
                    </p>

                    <p className="no-margin-b">
                      Following are the rules while selecting your team.
                    </p>

                    <b>1. Your team should have 15 players as follows:</b>
                    <ul className="no-margin-t">
                      <li>2 Goal Keepers</li>
                      <li>5 Defenders</li>
                      <li>5 Midfielders</li>
                      <li>3 Attackers</li>
                    </ul>
                    <b>
                      2. You are allowed to select up to 3 players from a single
                      Goal Fantasy League team.
                    </b>
                    <p></p>
                    <br />
                    <b>
                      3. Your Squad will be of 15 players. (11 Players of the
                      field and 4 players as substitutes)
                    </b>

                    {/* <p>Your team can play in any formation providing that 1 goalkeeper, at least 3 defenders and at least 1 forward are selected at all times.</p> */}
                    <ul className="no-margin-t">
                      <li>
                        Select your 11 players whom you want to play in the
                        field.{' '}
                      </li>
                      <li>
                        All your points for the Gameweek will be scored by those
                        11 players.{' '}
                      </li>
                      <li>
                        However if one or more will not play, you can update the
                        players manually before the deadline.
                      </li>
                    </ul>
                    <p>
                      Your chosen team can play at any formation as long as you
                      are keeping 1 goalkeeper, at least 3 defenders and at
                      least 1 forward.
                    </p>

                    <b>4. Selecting a Captain and a Vice-Captain</b>
                    <p>
                      From your starting 11 players, nominate a Captain and a
                      Vice Captain for your team. Your captain’s score gets
                      double automatically. If the captain plays 0 minutes in
                      the Gameweek, the captain will be changed to the
                      vice-captain. If both the captain and the vice-captain
                      play 0 minutes in a Gameweek, then no player's score will
                      be doubled.
                    </p>

                    <b>5. Transfers</b>
                    <p>
                      After selecting your team you can buy and sell players in
                      the transfers market. Unlimited transfers can be made for
                      free until the first deadline. After the first deadline
                      you will receive 2 free transfers each Gameweek. Each
                      additional transfer you make in the same Gameweek will
                      deduct 4 points from your total score and match score at
                      the start of the next Gameweek.
                    </p>

                    <p>
                      If you do not use your free transfers, you cannot carry
                      them to the next Gameweek.
                    </p>

                    <b>6. Chips</b>
                    <p>
                      Chips can be used to potentially enhance your team's
                      performance during the season.
                    </p>

                    <p className="no-margin-b">
                      Only one chip can be played in a single Gameweek. The
                      chips available are as follows:
                    </p>

                    <ul className="no-margin-t">
                      <li>
                        Bench Boost
                        <div>
                          The points scored by your bench players in the next
                          Gameweek are included in your total.
                        </div>
                      </li>
                      <li>
                        Free Hit
                        <div>
                          Free Hit chip lets you make unlimited transfers that
                          are applied to one gameweek only. At the next
                          deadline, the original squad is returned.
                        </div>
                      </li>
                      <li>
                        Triple Captain
                        <div>
                          Your captain points are tripled instead of doubled in
                          the next Gameweek.
                        </div>
                      </li>
                      <li>
                        Wildcard
                        <div>
                          The transfers (including those already made) in the
                          Gameweek are free of charge.
                        </div>
                      </li>
                    </ul>
                    <p>
                      The Bench Boost and Triple Captain chips can each be used
                      only once a season and can be cancelled at any time before
                      the Game week deadline.
                    </p>
                    <p>
                      The Free Hit Chip can be used once a season and cannot be
                      cancelled once played.
                    </p>
                    <p>
                      The Wildcard chip can be used twice a season, once in the
                      first half of the season and once in the second half of
                      the season. The Wildcard chip is played when confirming
                      transfers that cost points and can't be cancelled once
                      played.
                    </p>

                    <b>7. Auto Substitution</b>
                    <p>
                      Goal will automatically replace your players who didn’t
                      play in a gameweek. Auto substitution is processed at the
                      end of each gameweek.
                    </p>

                    <p>
                      If any of your players plays 0 minutes in the gameweek, he
                      will be substituted by the highest priority substitute
                      bench player who played in that gameweek and does not
                      break the original formation rule of creating teams. In
                      this way the points scored by your bench players during
                      that gameweek will be added to your score.
                    </p>

                    <b>8. Euro Cup Rules</b>
                    <br></br>
                    <b>Number of players per team</b>
                    <p>
                      There's a limit on how many players you can select from
                      one national team. This limit changes as the tournament
                      progresses.
                    </p>
                    <ul className="no-margin-t">
                      <li>
                        For the <b> group stage, </b>you can pick a maximum of{' '}
                        <b>three</b> players from the same team
                      </li>
                      <li>
                        For the <b>round of 16</b>, you can pick a maximum of
                        four players from the same team
                      </li>
                      <li>
                        For the <b>quarter-finals,</b> you can pick a maximum of
                        five players from the same team
                      </li>
                      <li>
                        For the <b>semi-finals,</b> you can pick a maximum of
                        six players from the same team
                      </li>
                      <li>
                        For the <b>Finals,</b> you can pick a maximum of eight
                        players from the same team
                      </li>
                    </ul>

                    <b>Making transfers</b>
                    <p>
                      Transferring players in and out of your squad helps you
                      get more points throughout the tournament.
                    </p>

                    <p>
                      Before EURO CUP kicks off, you can make as many transfers
                      as you like as long as you stay within the budget.
                      Throughout the competition, you get free transfers each
                      week. The number of free transfers you can make depends on
                      the stage of the tournament.
                    </p>

                    <table>
                      <tbody>
                        <tr>
                          <th width="70%">
                            <b>Phase</b>
                          </th>
                          <th width="30%">
                            <b>Number of free transfers</b>
                          </th>
                        </tr>
                        <tr>
                          <td>Before tournament starts</td>
                          <td>Unlimited</td>
                        </tr>
                        <tr>
                          <td>During group stage</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>During Round of 16</td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>During Quarter-finals</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>During Semi-finals</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td>During Finals</td>
                          <td>8</td>
                        </tr>
                      </tbody>
                    </table>

                    <p>
                      *For every transfer you make beyond your quota of free
                      transfers, you'll have four points deducted from your
                      points total.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div
              className={`container main-container ${
                isMobile ? 'no-margin-t' : ''
              }`}
            >
              <Helmet
                titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}
              >
                <title>{context.state.locale.MetaTag.howtoplay_title}</title>
                <meta
                  name="description"
                  content={context.state.locale.MetaTag.howtoplay_description}
                />
              </Helmet>
              <Row className="">
                <Col lg={9} className="auto-margin">
                  <div className="league-heading m">
                    <div className="title">طريقة اللعب</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={9} className="auto-margin">
                  <div className="static-page-wrap">
                    <p>قم بالتسجيل على موقع او تطبيق كووورة فانتازي.</p>

                    <p>
                      فور تسجيلك، سوف تتلقى ١٠٠ مليون دولار افتراضي لكي تستخدمها
                      في عملية تشكيل فريقك.
                    </p>

                    <p>
                      قبل بداية الموسم، يتحدد سعر اللاعب بحسب ادائه في الموسم
                      السابق، ثم يتغير السعر بحسب مستواه الحالي.
                    </p>

                    <p className="no-margin-b">
                      القوانين العامة عند اختيار الفريق.
                    </p>

                    <b>1. يجب عليك اختيار 15 لاعبًا بالشكل التالي</b>
                    <ul className="no-margin-t">
                      <li>2 حراس مرمى</li>
                      <li>5 مدافعين</li>
                      <li>5 وسط ملعب</li>
                      <li>3 مهاجمين</li>
                    </ul>
                    <b>2. يمكنك اختيار كحد اقصى 3 لاعبين من فريق واحد .</b>
                    <p></p>
                    <b>
                      3. . تتكون تشكيلة فريقك من ١٥ لاعب (١١ منهم أساسيين و٤ على
                      دكة البدلاء)
                    </b>

                    <p>
                      يتم تسجيل كل نقاطك في الجولة من خلال ال11 لاعب الاساسيين،
                      وإذا لن يلعب أحدهم يمكنك تحديث اللاعبين بشكل يدوي قبل
                      بداية الجولة. يستطيع فريقك اللعب بـأي تشكيلة طالما كان
                      هناك حارس مرمى، وعلى الأقل ثلاثة مدافعين ومهاجم واحد في كل
                      الأوقات.
                    </p>

                    <b>4. اختيار القائد ونائب القائد</b>
                    <p>
                      من ال11 لاعبًا الذين اخترتهم، اختر القائد ونائب القائد.
                      يتضاعف رصيد القائد بشكل تلقائي. إذا لم يلعب قائد الفريق في
                      الجولة، سوف يتغير القائد إلى نائب القائد. إذا لم يلعب
                      القائد أو نائب القائد في الجولة، لن يتم مضاعفة رصيد أي
                      لاعب.
                    </p>
                    {/* <b>5. Prioritising Your Bench For Automatic Substitutions</b> */}
                    {/* <p>Your substitutes provide cover for unforeseen events like injuries and postponements by automatically replacing starting players who don't play in a Gameweek.</p>
                                    <p className='no-margin-b'>Based on the priorities you assign, automatic substitutions are processed at the end of the Gameweek as follows:</p>
                                    <ul className='no-margin-t'>
                                        <li>If your Goalkeeper plays 0 minutes in the Gameweek, he will be substituted by your replacement Goalkeeper, if he played in the Gameweek.</li>
                                        <li>If any of your outfield players play 0 minutes in the Gameweek, they will be substituted by the highest priority outfield substitute who played in the Gameweek and doesn't break the formation rules (eg. If your starting team has 3 defenders, a defender can only be replaced by another defender).</li>
                                    </ul> */}
                    <b>5. الانتقالات</b>
                    <p>
                      بعد اختيار فريقك، يمكنك بيع وشراء اللاعبين في سوق
                      الانتقالات. يمكنك القيام بانتقالات غير محدودة بشكل مجاني
                      حتى الموعد النهائي الأول. بعد الموعد النهائي الأول، سوف
                      تتمكن من القيام بانتقالين بشكل مجاني بعد كل جولة. أي
                      انتقال آخر تقوم به بعد ذلك في نفس الجولة سوف يخصم 4 نقاط
                      من رصيدك الكلي في بداية الجولة التالية.
                    </p>

                    <p>
                      إذا لم تستخدم الإنتقالات المجانية، فلن يمكنك القيام
                      بانتقال مجاني إضافي في الجولة التالية.
                    </p>

                    <b>6. بطاقات التعزيز</b>

                    <p>
                      يمكنك استخدام هذه البطاقات للتحسن من فرص فريقك خلال
                      الموسم.
                    </p>
                    <p>
                      يمكنك استخدام بطاقة تعزيز واحدة في كل جولة. البطاقات
                      المتاحة هي على الشكل التالي:
                    </p>

                    <ul className="no-margin-t">
                      <li>
                        الاوراق الرابحة
                        <div>
                          تضع النقاط التي سجلها لاعبو دكة البدلاء في الجولة
                          التالية في رصيدك الكلي.
                        </div>
                      </li>

                      <li>
                        اضرب بكل قوتك!
                        <div>
                          تمكنك من القيام بانتقالات غير محدودة خلال جولة واحدة
                          وبعدها يعود فريقك الى ما كان عليه قبل الجولة.
                        </div>
                      </li>

                      <li>
                        تريبل كابتن
                        <div>
                          تقوم باحتساب نقاط قائدك ثلاث مرات بدلًا من مرتين في
                          الجولة التالية.
                        </div>
                      </li>

                      <li>
                        وايلد كارد
                        <div>
                          تمكنك من القيام انتقالات مجانية (بما فيهم الانتقالات
                          التي قد قمت بها) في الجولة مجانًا.
                        </div>
                      </li>
                    </ul>

                    <p>
                      بطاقات تعزيز الأوراق الرابحة وتريبل كابتن يمكنك استخدامها
                      مرة واحدة في البطولة ويتم لعبهم عند حفظ فريقك في صفحة
                      فريقي، كما يمكنك إلغائهم في أي وقت قبل الموعد النهائي
                      للجولة.
                    </p>

                    <p>
                      يمكن استخدام بطاقة اضرب بكل قوتك! مرة واحدة خلال البطولة
                      ولا يمكن أن تلغى بعد التأكيد.
                    </p>

                    <p>
                      يمكنك استخدام بطاقة وايلد كارد مرتان في البطولة، مرة بعد
                      دور المجموعات، ومرة أخرى حتى نهاية البطولة، تلعب بطاقة غير
                      زي ما تحب عند تأكيد الانتقالات التي تكلف نقاط ولا يمكن أن
                      تُلغى بعد التأكيد.
                    </p>

                    <b>7. التبديلات التلقائية:</b>
                    <p>
                      يتم التبديل التلقائي للاعبين الاساسيين الذين لم يشاركوا
                      خلال الجولة على الشكل التالي: اي لاعب من تشكيلتك الاساسية
                      لم يشارك خلال الجولة سيتم استبداله بأحد لاعبي الإحتياط
                      الذين شاركوا في الجولة بحسب الأفضلية وبدون ان يكسر في اي
                      وقت القواعد الأساسية لتأسيس الفرق. (حارس مرمى، ثلاثة
                      مدافعين، مهاجم) بهذه الطريقة ستضاف النقاط التي أحرزتها عن
                      طريق لاعبي دكة البدلاء في الجولة إلى مجموع نقاطك. يتم
                      الإستبدال التلقائي بعد نهاية كل جولة.
                    </p>

                    <b>8. قواعد كووورة فانتازي</b>
                    <br></br>
                    <b>عدد اللاعبين لكل فريق</b>

                    <p>
                      هناك عدد محدد من اللاعبين الذين يمكنك اختيارهم من منتخب
                      واحد، يتغير هذا العدد مع تقدم البطولة.
                    </p>

                    <ul className="no-margin-t">
                      <li>
                        بالنسبة لمرحلة المجموعات، يمكنك اختيار 3 لاعبين كحد أقصى
                        من نفس الفريق
                      </li>
                      <li>
                        في دور الـ16، يمكنك اختيار 4 لاعبين كحد أقصى من نفس
                        الفريق
                      </li>
                      <li>
                        في ربع النهائي، يمكنك اختيار 5 لاعبين كحد أقصى من نفس
                        الفريق
                      </li>
                      <li>
                        بالنسبة للدور نصف النهائي يمكنك اختيار 6 لاعبين كحد أقصى
                        من نفس الفريق
                      </li>
                      <li>
                        بالنسبة والنهائي، يمكنك اختيار 8 لاعبين كحد أقصى من نفس
                        الفريق
                      </li>
                    </ul>

                    <b>إجراء التبديلات</b>
                    <p>
                      يساعدك نقل اللاعبين داخل وخارج فريقك في الحصول على المزيد
                      من النقاط طوال البطولة.
                    </p>
                    <p>
                      قبل انطلاق البطولة, يمكنك إجراء العديد من التبديلات التي
                      تريدها طالما بقيت في حدود الميزانية.
                    </p>
                    <p>
                      طوال فترة المنافسة، تحصل على انتقالات مجانية كل أسبوع.
                      ويعتمد عدد التبديلات المجانية التي يمكنك إجراؤها على مرحلة
                      البطولة.
                    </p>

                    <table>
                      <tbody>
                        <tr>
                          <th width="70%">
                            <b>المرحلة</b>
                          </th>
                          <th width="30%">
                            <b>عدد الانتقالات المجانية</b>
                          </th>
                        </tr>
                        <tr>
                          <td>قبل أن تبدأ البطولة</td>
                          <td>بلا حدود</td>
                        </tr>
                        <tr>
                          <td>خلال دور المجموعات</td>
                          <td> ٣</td>
                        </tr>
                        <tr>
                          <td>دور الـ16</td>
                          <td>٤</td>
                        </tr>
                        <tr>
                          <td> في دور الثمانية</td>
                          <td>٥</td>
                        </tr>
                        <tr>
                          <td>في قبل النهائي</td>
                          <td>٦</td>
                        </tr>
                        <tr>
                          <td>في النهائي</td>
                          <td>٨</td>
                        </tr>
                      </tbody>
                    </table>

                    <p>
                      * لكل عملية تبديل تتجاوز حصتك من التبديلات المجانية، ستخصم
                      4 نقاط من إجمالي نقاطك.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          )
        }
      </MyContext.Consumer>
    )
  }
}
