/* eslint-disable eqeqeq */
import React from 'react';
import Select from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';
import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import { MyContext } from '../context';
import { Col, Row } from 'react-bootstrap';
import RosterLoader from '../views/RosterLoader';
import {Helmet} from "react-helmet";
import Images from '../components/images'
import SimpleImageSlider from "ac-react-simple-image-slider";


var durationForBanner = 10;
export default class PlayerStates extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            AllPositionList: [],
            SelectedPlayerPosition: [],
            SortByList: [],
            SelectedSorting: [],
            RosterCurrentPage: NC.CURRENT_PAGE,
            SelectedMinSalary: NC.MIN_SALARY,
            SelectedMaxSalary: NC.MAX_SALARY,
            PlayerSearch: "",
            SelectedTeam: [],
            allRoster: [],
            isLoaderShow: false,
            isTableLoaderShow: false,
            hasMore: true,
        };
    }

    getLineupMasterData() {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
        }
        WSManager.Rest(API.GET_LINEUP_MASTER_DATA, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let AllPosition = {
                    "value": "",
                    "label": this.props.locale.MyTeam.all_players,
                }
                responseJson.data.all_position.map((data, key) => {
                    data.label = data.position_display_name;
                    data.value = data.position;
                    return data
                })
                responseJson.data.all_position.unshift(AllPosition)
                // let SortBy = {
                //     "value": "",
                //     "label": "Total Points",
                // }
                //responseJson.data.sort_by_list.unshift(SortBy)
                this.setState({
                    AllPositionList: responseJson.data.all_position,
                    SortByList: responseJson.data.sort_by_list,
                    SelectedPlayerPosition: responseJson.data.all_position[0],
                    SelectedSorting: responseJson.data.sort_by_list[0],
                })

            }
        })
    }
    getAllRoster(SelectedPlayerPosition, SelectedTeam, PlayerSearch, SelectedMinSalary, SelectedMaxSalary, RosterCurrentPage, SelectedSorting) {
        this.setState({ isLoaderShow: true });
        if (RosterCurrentPage > 1) {
            this.setState({ isTableLoaderShow: true });
        }
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "items_perpage": NC.ITEMS_PERPAGE,
            "current_page": RosterCurrentPage,
            "sort_order": "DESC",
            "sort_field": !_.isEmpty(SelectedSorting) ? SelectedSorting.value : '',
            "team_league_id": SelectedTeam.team_league_id || "",
            "position": SelectedPlayerPosition.position || "",
            "min_salary": SelectedMinSalary,
            "max_salary": SelectedMaxSalary,
            "player_name": PlayerSearch || "",
            "lineup_master_id": localStorage.getItem('lineup_master_id'),
            is_eliminate:"0"
        }
        WSManager.Rest(API.GET_ALL_ROSTER, param).then((responseJson) => {
            this.setState({
                isLoaderShow: false,
                isTableLoaderShow: false,
            });
            if (responseJson.response_code === NC.successCode) {
                var allRoster = this.state.allRoster;
                if (RosterCurrentPage == NC.CURRENT_PAGE) {
                    allRoster = [];
                    this.setState({
                        allRoster: allRoster
                    });
                }
                this.setState({
                    allRoster: _.concat(allRoster, responseJson.data.result)
                });
            }
        })

    }

    handlePositionSelectChange = (SelectedPlayerPosition) => {
        if (!SelectedPlayerPosition.value && !SelectedPlayerPosition.label) {
            SelectedPlayerPosition.value = SelectedPlayerPosition.position
        }
        this.setState({
            SelectedPlayerPosition: SelectedPlayerPosition,
            RosterCurrentPage: NC.CURRENT_PAGE
        });
        this.getAllRoster(SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch, this.state.SelectedMinSalary, this.state.SelectedMaxSalary, NC.CURRENT_PAGE, this.state.SelectedSorting)
    }
    handleSortingSelectChange = (SelectedSorting) => {
        this.setState({
            SelectedSorting: SelectedSorting,
            RosterCurrentPage: NC.CURRENT_PAGE
        });
        this.getAllRoster(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch, this.state.SelectedMinSalary, this.state.SelectedMaxSalary, NC.CURRENT_PAGE, SelectedSorting)
    }

    fetchMoreData = () => {
        let RosterCurrentPage = this.state.RosterCurrentPage + 1;
        this.setState({ RosterCurrentPage: RosterCurrentPage });
        this.getAllRoster(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch, this.state.SelectedMinSalary, this.state.SelectedMaxSalary, RosterCurrentPage, this.state.SelectedSorting)
    }
    componentDidMount() {
        this.getLineupMasterData()
        this.getAllRoster(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch, this.state.SelectedMinSalary, this.state.SelectedMaxSalary, NC.CURRENT_PAGE, this.state.SelectedSorting)
        window.addEventListener("resize", this.updateDimensions);
        this.callAdvBanner(WSManager.getAdBanner())
    }
    callAdvBanner = (data) => {
        let bannerData = data.advertisements
        let lowerBanner = [];
                let squareBanner = [];
                let topPtcBanner = [];
                let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
                for (let k = 0; k < bannerData.length; k++) {
                    if (bannerData[k].ad_position_id == 16 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        topPtcBanner.push(bannerData[k])
                    }
                    // if (bannerData[k].ad_position_id == 10 && bannerData[k].league_id == sportsLg) {
                    //     bannerData[k]["src"] = bannerData[k].image_adsense
                    //     bannerData[k]["id"] = bannerData[k].ads_unique_id
                    //     lowerBanner.push(bannerData[k])
                    // }
                    // if (bannerData[k].ad_position_id == 11 && bannerData[k].league_id == sportsLg) {
                    //     bannerData[k]["src"] = bannerData[k].image_adsense
                    //     bannerData[k]["id"] = bannerData[k].ads_unique_id
                    //     squareBanner.push(bannerData[k])
                    // }
                }
                this.setState({
                    // lowerBanner: lowerBanner,
                    // squareBanner:squareBanner,
                    topPtcBanner:topPtcBanner
                }, () => {
                    this.getBannerIndex();
                    // console.log(this.state.squareBanner,'sdssdfsquareBanner')
                })
    }
     /**
     * BANNER CLICK FOR LOWER BANNER 
     */
    getBannerIndex = (e) => {
        for (let i = 0; i <= this.state.topPtcBanner.length - 1; i++) {
            this.task(this.state.topPtcBanner[i], i);
        }
    }
    task = (data, i) => {
        let a = this.state.topPtcBanner.length - 1;
        setTimeout(function () {
            this.setState({
                currentBanner: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndex();
                }.bind(this), durationForBanner * 1000)
    
            }
        }.bind(this), durationForBanner * 1000 * i);
    
    }
    
    getBannerClick = (from) => {
        if (from == 2) {
            window.open(this.state.currentBanner);
        }
    
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    ImageCallback = (idx) => {
        let allRoster = this.state.allRoster
        allRoster[idx].player_image = Images.PLAYER_IMAGE
    }
    componentWillMount() {
        this.updateDimensions();
    }
    updateDimensions = () => {
        this.setState({
            innerWidth: window.innerWidth, 
            innerHeight: window.innerHeight
        });
    }
    render() {
        const {
            AllPositionList,
            SelectedPlayerPosition,
            SortByList,
            SelectedSorting,
            allRoster,
            // isTableLoaderShow,
            hasMore,
            isLoaderShow
        } = this.state;

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="container main-container statistics-style">
                    <Row>
                            <Col md={1} ></Col>
                            <Col md={10}>
                                {
                                    this.state.topPtcBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }}>
                                        <SimpleImageSlider
                                            showArrows={false}
                                            showDots={false}
                                            data={this.state.topPtcBanner}
                                            duration={durationForBanner}
                                        />
                                    </div>
                                }
                            </Col>
                            {/* <Col md={2} /> */}
                            <Col md={1} ></Col>
                        </Row>

                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.player_stats_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.player_stats_description} />
                        </Helmet>
                        <Row className="">
                            <Col lg={9} className="auto-margin">
                                <div className="league-heading m">
                                    <div className="title">{context.state.locale.PlayerStates.hero_title}</div>
                                    <div className="subtitle">{context.state.locale.PlayerStates.sub_title}</div>
                                </div>
                                <div className="row auto-margin">
                                    <div className="playerStats-filter col-sm-6 col-xs-6 col-md-6">
                                        <div className="title">{context.state.locale.PlayerStates.view}</div>
                                        <div className="playerStats-filter-dropdown">
                                            <Select
                                                className="select2"
                                                isSearchable={false}
                                                classNamePrefix="select"
                                                isClearable={false}
                                                value={SelectedPlayerPosition}
                                                options={AllPositionList}
                                                onChange={this.handlePositionSelectChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="playerStats-filter col-sm-6 col-xs-6 col-md-6">
                                        <div className="title">{context.state.locale.PlayerStates.sort_by}</div>
                                        <div className="playerStats-filter-dropdown">
                                            <Select
                                                className="select2"
                                                isSearchable={false}
                                                classNamePrefix="select"
                                                isClearable={false}
                                                value={SelectedSorting}
                                                options={SortByList}
                                                onChange={this.handleSortingSelectChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={9} className="auto-margin">
                                <div className="playerstate-table table-edited">
                                    <InfiniteScroll
                                        dataLength={allRoster.length}
                                        next={this.fetchMoreData}
                                        hasMore={hasMore}
                                        scrollableTarget={this.state.innerWidth <= 768 ? 'Site' : ''}
                                    // loader={
                                    //     isTableLoaderShow == true &&
                                    //     <h4 className='table-loader'>Loading...</h4>
                                    // }
                                    >
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{context.state.locale.PlayerStates.player}</th>
                                                    <th className="text-center">{context.state.locale.PlayerStates.form}</th>
                                                    <th className="text-center">{context.state.locale.PlayerStates.cost}</th>
                                                    <th className="text-center">{context.state.locale.PlayerStates.sel}</th>
                                                    {/* <th className="text-center">{context.state.locale.PlayerStates.pts}</th> */}
                                                    <th className="text-center">{context.state.locale.PlayerStates.total_points}</th>
                                                    {
                                                        (
                                                            SelectedSorting.value != 'fantasy_score' &&
                                                            SelectedSorting.value != 'avg_form'
                                                        )
                                                        &&
                                                        <th className="text-center">{SelectedSorting.label || '--'}</th>
                                                    }
                                                </tr>
                                                <tr className='blank-row'>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    {/* <th></th> */}
                                                    {
                                                        (
                                                            SelectedSorting.value != 'fantasy_score' &&
                                                            SelectedSorting.value != 'avg_form'
                                                        )
                                                        &&
                                                        <th></th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    _.map(allRoster, (item, idx) => {
                                                        return (
                                                            <tr key={idx} className={idx == 0 ? 'm-t-sm' : ''}>
                                                                <td className="player-pic" onClick={() => context.playerCardModalInit('PlayerCardModalShow', item, true)}>
                                                                    <div className="pick-player-block pointer" >

                                                                        <div className="clip-border">
                                                                            <img src={item.player_image} alt="" width="40px" onError={() => this.ImageCallback(idx)} />
                                                                        </div>

                                                                        <img className="team-stamp" src={item.flag} alt="" width="20px" />
                                                                    </div>
                                                                </td>
                                                                <td onClick={() => context.playerCardModalInit('PlayerCardModalShow', item, true)}>
                                                                    <span className="fname ellipsis pointer" >
                                                                        {item.full_name}
                                                                    </span>
                                                                    <span className="lname ellipsis pointer" >
                                                                        {item.team_abbr_lang + "  |  " + item.position}
                                                                    </span>
                                                                </td>
                                                                
                                                                <td className="text-center">
                                                                    {item.avg_form}
                                                                </td>
                                                                <td className="text-center">
                                                                    {item.salary}{NC.SALARY_UNIT}
                                                                </td>

                                                                <td className="text-center">
                                                                    {item.pick_percentage + '%' || '--'}
                                                                </td>

                                                                {/* <td className="text-center text-lowercase">
                                                                    {item.fantasy_score}{context.state.locale.PlayerStates.pts}
                                                                </td> */}
                                                                <td className="text-center">
                                                                    {item.fantasy_score}
                                                                </td>
                                                                {
                                                                    (
                                                                        SelectedSorting.value != 'fantasy_score' &&
                                                                        SelectedSorting.value != 'avg_form'
                                                                    )
                                                                    &&
                                                                    <td className="text-center">
                                                                        {item[SelectedSorting.value] || '--'}
                                                                    </td>
                                                                }
                                                            </tr>

                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <div className={`roster-filter-loader fade ${isLoaderShow ? 'in' : null}`}><RosterLoader /></div>
                                    </InfiniteScroll>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </MyContext.Consumer>
        );
    }
}
