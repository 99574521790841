/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Images from '../components/images'
import { Link, withRouter } from 'react-router-dom';
import * as qs from 'query-string';

import { MyContext } from './../context';

class Footer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isMobile: false,

        }
    }
    componentDidMount = () => {
        const { location } = this.props;
        const parsed = qs.parse(location.search);
        this.setState((state, props) => {
            return {
                isMobile: parsed.isMobile
            }
        });
    }
    render() {
        const { isMobile } = this.state;
        if (isMobile) { return null; }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <footer className='footer'>
                        <div className="container footer-top-section">
                            <Row>
                                <Col md={5} lg={4}>
                                    <div className="copyright">{context.state.locale.footer.copyright}</div>
                                    <div className="footer-logo">
                                        <a>
                                            <img style={{objectFit:'contain',width:61,height:45}} src={context.state.language == 'en' ? Images.KSAF_EN_GRAY_LOGO : Images.KSAF_AR_GRAY_LOGO} alt="" />
                                        </a>
                                    </div>
                                </Col>
                                <Col md={7} lg={8}>
                                     <ul className="footer-horizontal-menu">
                                        <li>
                                            <Link to={'/about-us'}>{context.state.locale.footer.about_us}</Link>
                                        </li>
                                        <li>
                                            <Link to={'/how-to-play'}>{context.state.locale.footer.how_to_play}</Link>
                                        </li>
                                        <li>
                                            <Link to={'/faq'}>{context.state.locale.footer.faq}</Link>
                                        </li>
                                        <li>
                                            <Link to={'/scoring'}>{context.state.locale.footer.rules_and_scoring}</Link>
                                        </li>
                                        {/* <li>
                                            <Link to={'/privacy-policy'}>{context.state.locale.footer.privacy_policy}</Link>
                                        </li> 
                                        <li>
                                            <Link to={'/terms'}>{context.state.locale.footer.terms}</Link>
                                        </li>
                                        
                                        <li>
                                            <Link to={'/support'}>{context.state.locale.nav.support}</Link>
                                        </li>*/}
                                    </ul>

                                    <div className="locale-switch">
                                        <span className={`item ${context.state.language == 'en' && 'active'}`} onClick={() => context.changeLanguage('en')}>English</span>
                                        <span className={`item ${context.state.language == 'ar' && 'active'}`} onClick={() => context.changeLanguage('ar')}>عربى</span>
                                    </div> 
                                </Col>
                            </Row>
                        </div>
                        <div className="footer-bottom">
                            <div className="container">
                                <Row>
                                    <Col md={6} >
                                        <div className="social-footer">
                                            <div className="label-title">{context.state.locale.footer.social_text}</div>
                                           <a href="https://www.facebook.com/kooora.goalzz" target=''> <i className="icon-facebook2"></i></a>
                                           <a href="https://www.instagram.com/kooora/" target=''><i className="icon-instagram"></i></a>
                                           <i className="icon-mail"></i>
                                           <div className="label-title mL10">{context.state.locale.Login.email_}</div>
                                        </div>
                                    </Col>
                                    {/* <Col md={6}>
                                        <span className="vinfotech">
                                            <span className="connect">
                                                {context.state.locale.footer.vinfotech_text}
                                            </span>
                                            <a className='vinfo-logo' href='https://www.vinfotech.com/fantasy-sports/custom-fantasy-sports-app-development' target='_blank' rel="noopener noreferrer">
                                                <img src={Images.VINFOTECH_LOGO} alt="" className="for-mobile-img" />
                                            </a>
                                        </span>
                                    </Col> */}
                                </Row>
                            </div>
                        </div>
                    </footer>

                )}
            </MyContext.Consumer>
        );
    }
}
export default withRouter(Footer);