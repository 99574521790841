/* eslint-disable eqeqeq, no-sequences */
import React from 'react'
import { Row, Col, Button, Modal, FormGroup } from 'react-bootstrap';
import FloatingLabel from 'floating-label-react';

import { inputStyle } from '../helper/input-style';
import Validation from './../helper/validation';

import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import { MyContext } from '../context';

export default class Forgot extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            email: '',
            formValid: false,
            posting: false
        };
    }

    getValidationState(type, value) {
        return Validation.validate(type, value)
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, this.validateForm);
    }
    validateForm() {
        this.setState({ formValid: Validation.validate('email', this.state.email) == 'success' });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ posting: true });
        let param = {
            "email": this.state.email
        }
        WSManager.Rest(API.FORGOT_PASSWORD, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({ posting: false });
                this.props.modalHide('ForgotModalShow');
                this.props.modalShow('ThankyouForgotModalShow');
            } else {
                this.setState({ posting: false });
            }
        })
    }
    render() {
        const {
            email,
            formValid,
            posting
        } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.ForgotModalShow}
                        onHide={() => this.props.modalHide('ForgotModalShow')}
                        dialogClassName="custom-modal"
                    >
                        <Modal.Header closeButton className='custom-modal-header'>
                            <div className="header-link">{context.state.locale.ForgotPwd.back_to_text} <span className='link' onClick={() => (this.props.modalHide('ForgotModalShow'), this.props.modalShow('LoginModalShow'))}>{context.state.locale.Login.hero_title}</span></div>
                            <div className="title">{context.state.locale.ForgotPwd.hero_title}</div>
                        </Modal.Header>
                        <form onSubmit={this.onSubmit}>
                            <Modal.Body>
                                {/* NATIVE FORM */}
                                <Row>
                                    <Col xs={12} className='text-center'>
                                        <div className="forgot-header-text">
                                        {context.state.locale.ForgotPwd.forgot_header_text}
                                    </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup
                                            className='input-label-center m-b-60'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('email', email)}>
                                            <FloatingLabel
                                                styles={inputStyle}
                                                id='email'
                                                name='email'
                                                placeholder={context.state.locale.ForgotPwd.email_placeholder}
                                                type='email'
                                                value={email}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer className='custom-modal-footer'>
                                <Button bsStyle="primary" disabled={!formValid || posting} type='submit'>{context.state.locale.ForgotPwd.submit_label}</Button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                )}
            </MyContext.Consumer>
        );
    }
}