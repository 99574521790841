/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import { MyContext } from '../context';
import Images from '../components/images';

export default class AppInfo extends Component {
    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className={`container main-container`}>
                        <div className="league-heading m">
                            <img src={context.state.language == 'en' ? Images.KUWAIT_LEAGUE_LOGO : Images.KUWAIT_LEAGUE_LOGO} alt="" className='logo-img' />
                            <div className="title">{context.state.locale.LandingPage.League_title}</div>
                            <div className="text-center">{context.state.locale.appinfo.version} {process.env.REACT_APP_VERSION}</div>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}