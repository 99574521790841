/* eslint-disable eqeqeq */
import React from 'react'
import { Button, Image, OverlayTrigger, Popover } from 'react-bootstrap'
import WSManager from '../helper/WSManager'
import { API } from '../helper'
import * as NC from '../helper/NetworkingConstants'
import Images from '../components/images'
import { notify } from 'react-notify-toast'
import { MyContext } from '../context'

export default class Boosters extends React.Component {
  // Methods
  // ------------------------------

  // SelectBooster = (type) => {
  //     this.setState({
  //         SelectedBooster: type
  //     });
  // }
  ApplyBooster = (duration, lineupUpdate) => {
    let param = {
      // "league_id": NC.leagueId,
      // "sports_id": NC.sportsId,
      sports_id: WSManager.getMultiLegData().sports_id,
      league_id: WSManager.getMultiLegData().league_id,
      lineup_master_id: this.props.team_info.lineup_master_id,
      booster: this.props.SelectedBooster,
    }
    this.setState({
      posting: true,
    })
    WSManager.Rest(API.APPLY_BOOSTER, param).then((responseJson) => {
      if (responseJson.response_code === NC.successCode) {
        this.setState({
          posting: false,
        })
        notify.show(responseJson.message, 'success', duration || 5000)
        this.props.ApplyBoosterCallBack(lineupUpdate)
      } else {
        this.props.ApplyBoosterCallBack(false, true) // LineupUpdate, Reset
        this.setState({
          posting: false,
        })
      }
    })
  }
  RemoveBooster = (type) => {
    let param = {
      // "league_id": NC.leagueId,
      // "sports_id": NC.sportsId,
      sports_id: WSManager.getMultiLegData().sports_id,
      league_id: WSManager.getMultiLegData().league_id,
      lineup_master_id: this.props.team_info.lineup_master_id,
      booster: type,
    }
    this.setState({
      posting: true,
    })
    WSManager.Rest(API.REMOVE_BOOSTER, param).then((responseJson) => {
      if (responseJson.response_code === NC.successCode) {
        this.setState({
          posting: false,
        })
        notify.show(responseJson.message, 'success', 5000)
        this.props.ApplyBoosterCallBack(true)
      }
    })
  }
  contextLocalInit = (context) => {
    this.setState({
      contextLocal: context,
    })
  }

  // Lifecycle
  // ------------------------------
  constructor(props) {
    super(props)
    this.state = {
      posting: false,
    }
  }

  render() {
    const { posting, contextLocal } = this.state
    const {
      team_info,
      next_week,
      SelectedBooster,
      SelectedCaptainBooster,
      SelectedBenchBooster,
      SelectedFreehitBooster,
      SelectBooster,
      RemoveSelectBooster,
      modalShow,
      is_editable,
    } = this.props
    const BenchBoostPopover = (
      <Popover id="BenchBoostPopover" className="booster-popover">
        <div></div>
        <div className="ad-div-booster">
          <Image src={Images.Ad_pitch} className="advertisement" />
        </div>
        <figure className="booster-image bench-boost"></figure>
        <div className="booster-title">
          {contextLocal && contextLocal.state.locale.Booster.bench_boost}
        </div>
        <div className="booster-content">
          {contextLocal && contextLocal.state.locale.Booster.bench_boost_desc}
        </div>
        <Button
          bsStyle="primary"
          block
          className="m-t"
          onClick={() => SelectBooster(2, contextLocal, SelectedBooster)}
          disabled={
            team_info.bench_booster_status.is_applied == 1 ||
            team_info.bench_booster_status.applied_week == next_week ||
            team_info.captain_booster_status.applied_week == next_week ||
            team_info.free_hit_booster_status.applied_week == next_week ||
            posting
          }
        >
          {SelectedBooster == 2 ||
          team_info.bench_booster_status.is_applied == 1
            ? Number(team_info.bench_booster_status.applied_week) <
                Number(next_week) &&
              Number(team_info.bench_booster_status.applied_week) > 0
              ? contextLocal && contextLocal.state.locale.Booster.played
              : contextLocal && contextLocal.state.locale.Booster.applied
            : contextLocal && contextLocal.state.locale.Booster.apply}
        </Button>
      </Popover>
    )

    const FreeHitPopover = (
      <Popover id="FreeHitPopover" className="booster-popover">
        <div className="ad-div-booster">
          <Image src={Images.Ad_pitch} className="advertisement" />
        </div>
        <figure className="booster-image freehit-booster"></figure>
        <div className="booster-title">
          {contextLocal && contextLocal.state.locale.Booster.free_hit}
        </div>
        <div className="booster-content">
          {contextLocal && contextLocal.state.locale.Booster.free_hit_desc}
        </div>
        {Number(team_info.wildcard_status.applied_week) ==
          Number(next_week) && (
          <div>
            <span className="booster-content-msz">
              {contextLocal &&
                contextLocal.state.locale.Booster.booster_content_msz}{' '}
              {next_week}
            </span>
          </div>
        )}
        <Button
          bsStyle="primary"
          block
          className="m-t"
          onClick={
            is_editable == 1
              ? () => modalShow('FreehitModalShow')
              : () => SelectBooster(3, contextLocal, SelectedBooster)
          }
          disabled={
            team_info.free_hit_booster_status.is_applied == 1 ||
            Number(team_info.wildcard_status.applied_week) ==
              Number(next_week) ||
            team_info.bench_booster_status.applied_week == next_week ||
            team_info.captain_booster_status.applied_week == next_week ||
            team_info.free_hit_booster_status.applied_week == next_week ||
            posting
          }
        >
          {SelectedBooster == 3 ||
          team_info.free_hit_booster_status.is_applied == 1
            ? Number(team_info.free_hit_booster_status.applied_week) <
                Number(next_week) &&
              Number(team_info.free_hit_booster_status.applied_week) > 0
              ? contextLocal && contextLocal.state.locale.Booster.played
              : contextLocal && contextLocal.state.locale.Booster.applied
            : contextLocal && contextLocal.state.locale.Booster.apply}
        </Button>
      </Popover>
    )

    const TripleCaptainPopover = (
      <Popover id="TripleCaptainPopover" className="booster-popover">
        <div className="ad-div-booster">
          <Image src={Images.Ad_pitch} className="advertisement" />
        </div>
        <figure className="booster-image three-x-booster"></figure>
        <div className="booster-title">
          {contextLocal && contextLocal.state.locale.Booster.triple_captain}
        </div>
        <div className="booster-content">
          {contextLocal &&
            contextLocal.state.locale.Booster.triple_captain_desc}
        </div>
        <Button
          bsStyle="primary"
          block
          className="m-t"
          onClick={() => SelectBooster(1, contextLocal, SelectedBooster)}
          disabled={
            team_info.captain_booster_status.is_applied == 1 ||
            team_info.bench_booster_status.applied_week == next_week ||
            team_info.captain_booster_status.applied_week == next_week ||
            team_info.free_hit_booster_status.applied_week == next_week ||
            posting
          }
        >
          {SelectedBooster == 1 ||
          team_info.captain_booster_status.is_applied == 1
            ? Number(team_info.captain_booster_status.applied_week) <
                Number(next_week) &&
              Number(team_info.captain_booster_status.applied_week) > 0
              ? contextLocal && contextLocal.state.locale.Booster.played
              : contextLocal && contextLocal.state.locale.Booster.applied
            : contextLocal && contextLocal.state.locale.Booster.apply}
        </Button>
      </Popover>
    )
    return (
      <MyContext.Consumer>
        {(context) => (
          <div className="boosters">
            {/* BENCH BOOSTER */}
            <span className="boost-btn-wrap">
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="top"
                overlay={BenchBoostPopover}
              >
                <Button
                  bsStyle="link"
                  className={
                    team_info.bench_booster_status.is_applied == 1 ||
                    SelectedBenchBooster == 2
                      ? 'active'
                      : ''
                  }
                  onClick={() => this.contextLocalInit(context)}
                >
                  <div className="label">
                    {context.state.locale.Booster.bench_boost}
                  </div>
                  <div className="status">
                    {SelectedBooster == 2 ||
                    team_info.bench_booster_status.is_applied == 1
                      ? Number(team_info.bench_booster_status.applied_week) <
                          Number(next_week) &&
                        Number(team_info.bench_booster_status.applied_week) > 0
                        ? context.state.locale.Booster.played
                        : context.state.locale.Booster.active
                      : context.state.locale.Booster.play}
                  </div>
                </Button>
              </OverlayTrigger>
              {/* {
                                team_info.bench_booster_status.is_applied == 1 || SelectedBenchBooster == 2 ?
                                    (
                                        team_info.bench_booster_status.applied_week == next_week || SelectedBenchBooster == 2 ?
                                            <span className='close' onClick={() => RemoveSelectBooster(2)} data-type='api'></span> : ''
                                    ) : ''
                            } */}

              {SelectedBooster == 2 ||
              team_info.bench_booster_status.is_applied == 1 ? (
                Number(team_info.bench_booster_status.applied_week) <
                  Number(next_week) &&
                Number(team_info.bench_booster_status.applied_week) > 0 ? (
                  ''
                ) : (
                  <span
                    className="close"
                    onClick={() => RemoveSelectBooster(2)}
                    data-type="api"
                  ></span>
                )
              ) : (
                ''
              )}
            </span>

            {/* FREE HIT BOOSTER */}
            <span className="boost-btn-wrap">
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="top"
                overlay={FreeHitPopover}
              >
                <Button
                  bsStyle="link"
                  className={
                    team_info.free_hit_booster_status.is_applied == 1 ||
                    SelectedFreehitBooster == 3
                      ? 'active'
                      : ''
                  }
                  onClick={() => this.contextLocalInit(context)}
                >
                  <div className="label">
                    {context.state.locale.Booster.free_hit}
                  </div>
                  <div className="status">
                    {SelectedBooster == 3 ||
                    team_info.free_hit_booster_status.is_applied == 1
                      ? Number(team_info.free_hit_booster_status.applied_week) <
                          Number(next_week) &&
                        Number(team_info.free_hit_booster_status.applied_week) >
                          0
                        ? context.state.locale.Booster.played
                        : context.state.locale.Booster.active
                      : context.state.locale.Booster.play}
                  </div>
                </Button>
              </OverlayTrigger>
              {team_info.booster_applied == 0 &&
                SelectedFreehitBooster == 3 &&
                team_info.free_hit_booster_status.is_applied == 0 && (
                  <span
                    className="close"
                    onClick={() => RemoveSelectBooster(3)}
                  ></span>
                )}
            </span>

            {/* CAPTAIN BOOSTER */}
            <span className="boost-btn-wrap">
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="top"
                overlay={TripleCaptainPopover}
              >
                <Button
                  bsStyle="link"
                  className={
                    team_info.captain_booster_status.is_applied == 1 ||
                    SelectedCaptainBooster == 1
                      ? 'active'
                      : ''
                  }
                  onClick={() => this.contextLocalInit(context)}
                >
                  <div className="label">
                    {context.state.locale.Booster.triple_captain}
                  </div>
                  <div className="status">
                    {SelectedBooster == 1 ||
                    team_info.captain_booster_status.is_applied == 1
                      ? Number(team_info.captain_booster_status.applied_week) <
                          Number(next_week) &&
                        Number(team_info.captain_booster_status.applied_week) >
                          0
                        ? context.state.locale.Booster.played
                        : context.state.locale.Booster.active
                      : context.state.locale.Booster.play}
                  </div>
                </Button>
              </OverlayTrigger>
              {/* {
                                team_info.captain_booster_status.is_applied == 1 || SelectedCaptainBooster == 1 ?
                                    (
                                        (team_info.captain_booster_status.applied_week == next_week && team_info.captain_booster_status.applied_week == active_week) || SelectedCaptainBooster == 1 ?
                                            <span className='close' onClick={() => RemoveSelectBooster(1)} data-type='api'></span>
                                        : ''
                                    ) : ''
                            } */}
              {SelectedBooster == 1 ||
              team_info.captain_booster_status.is_applied == 1 ? (
                Number(team_info.captain_booster_status.applied_week) <
                  Number(next_week) &&
                Number(team_info.captain_booster_status.applied_week) > 0 ? (
                  ''
                ) : (
                  <span
                    className="close"
                    onClick={() => RemoveSelectBooster(1)}
                    data-type="api"
                  ></span>
                )
              ) : (
                ''
              )}
            </span>
          </div>
        )}
      </MyContext.Consumer>
    )
  }
}
