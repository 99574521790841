/* eslint-disable eqeqeq */
import React from 'react';
import { Row, Col, Button, FormGroup } from 'react-bootstrap';
import FloatingLabel from 'floating-label-react';

import { inputStyle } from '../helper/input-style';
import Validation from '../helper/validation';
import { notify } from 'react-notify-toast';

import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";

import {Helmet} from "react-helmet";
import { MyContext } from '../context';
const md5 = require('md5');

export default class ResetPassword extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            password: '',
            retypePassword: '',
            formValid: false,
            posting: false
        };
    }

    getValidationState(type, value) {
        if (type == 'equals' && value.length > 0) {
            return Validation.validate('password', value) && value == this.state.password ? 'success' : 'error';
        } else {
            return Validation.validate(type, value);
        }
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, this.validateForm);
    }
    validateForm() {
        this.setState({ formValid: Validation.validate('password', this.state.password) == 'success' && this.getValidationState('equals', this.state.retypePassword) == 'success' });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ posting: true });
        let param = {
            "key": this.props.match.params.key,
            "password": md5(this.state.retypePassword)
        }
        WSManager.Rest(API.RESET_PASSWORD, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                notify.show(responseJson.message, 'success', 5000);
                this.props.history.push('/');
                this.setState({ posting: false });
            } else {
                this.setState({ posting: false });
            }
        })
    }

    render() {
        const {
            password,
            retypePassword,
            posting,
            formValid } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <form onSubmit={this.onSubmit}>
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.resetpassword_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.resetpassword_description} />
                        </Helmet>
                        <div className='container white-container'>
                            <Row>
                                <Col md={3} xs={12}></Col>
                                <Col md={6} xs={12}>
                                    <div className="title-hero">{context.state.locale.ResetPassword.hero_title}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} xs={12}></Col>
                                <Col md={6} xs={12}>
                                    <FormGroup
                                        className='input-label-center'
                                        controlId="formBasicText"
                                        validationState={this.getValidationState('password', password)}>
                                        <FloatingLabel
                                            styles={inputStyle}
                                            id='password'
                                            name='password'
                                            placeholder={context.state.locale.ResetPassword.password}
                                            type='password'
                                            value={password}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} xs={12}></Col>
                                <Col md={6} xs={12}>
                                    <FormGroup
                                        className='input-label-center'
                                        controlId="formBasicText"
                                        validationState={this.getValidationState('equals', retypePassword)}>
                                        <FloatingLabel
                                            styles={inputStyle}
                                            id='retypePassword'
                                            name='retypePassword'
                                            placeholder={context.state.locale.ResetPassword.retype_password}
                                            type='password'
                                            value={retypePassword}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} xs={12}></Col>
                                <Col md={6} xs={12}>
                                    <Button bsStyle="primary" disabled={!formValid || posting} type='submit' block>{context.state.locale.ResetPassword.btn}</Button>
                                </Col>
                            </Row>
                        </div>
                    </form>
                )}
            </MyContext.Consumer>

        )
    }

}