/* eslint-disable eqeqeq, jsx-a11y/anchor-is-valid */
import React from 'react'
import { Button, Modal } from 'react-bootstrap';

import Images from '../components/images'
import { MyContext } from '../context';
export default class ThankyouSignup extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            
        };
    }

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                <Modal
                    show={this.props.ThankyouSignupModalShow}
                    onHide={() => this.props.modalHide('ThankyouSignupModalShow')}
                    dialogClassName="custom-modal">
                    <Modal.Body>
                        <div className="modal-header no-padding border-0">
                            <button className="close thanks-close" onClick={() => this.props.modalHide('ThankyouSignupModalShow')}><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="thank-content">
                            <figure className="graphic">
                                <img style={{width:140,height:120,objectFit:'contain'}} src={context.state.language == 'en' ? Images.DARK_KSAF_EN_LOGO : Images.DARK_KSAF_AR_LOGO} alt=""/>
                            </figure>
                            <div className="title">{context.state.locale.Thankyou.hero_title}</div>
                            <div className="text-muted m-t">{context.state.locale.Thankyou.signup}</div>
                            <a className='text-bold'>
                                {this.props.BucketData.email}
                            </a>
                            <div className="signup-footer-text m-b-lg">
                            {context.state.locale.Thankyou.signup1}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='custom-modal-footer'>
                        <Button bsStyle="primary" onClick={() => this.props.modalHide('ThankyouSignupModalShow')}>{context.state.locale.Thankyou.ok_text}</Button>
                    </Modal.Footer>
                </Modal>
                )}
            </MyContext.Consumer>
        );
    }
}