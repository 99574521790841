/* eslint-disable eqeqeq */
import React from 'react'
import { MyContext } from '../context';
import Images from '../components/images';
import { Modal } from 'react-bootstrap';

import { notify } from 'react-notify-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class InviteFriend extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            origin: window.location.origin
        };
    }

    onCopyUrl = (msz) => {
        notify.show(msz, "success", 2000);
    }
    onCopyCode = (msz) => {
        notify.show(msz, "success", 2000);
    }

    render() {
        const { origin } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.InviteFriendShow}
                        onHide={() => this.props.modalHide('InviteFriendShow')}
                        dialogClassName="custom-modal">

                        <Modal.Body>
                            <div className="modal-header no-padding border-0">
                                <button className="close thanks-close" onClick={() => this.props.modalHide('InviteFriendShow')}><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="thank-content">
                                <figure className="graphic m-t-40">
                                    <img style={{width:120,height:90,objectFit:'contain'}} src={context.state.language == 'en' ? Images.DARK_KSAF_EN_LOGO : Images.DARK_KSAF_AR_LOGO} alt="" />
                                </figure>
                                <div className="title">{this.props.BucketData.leagueName}</div>
                                <div className="m-t-20 m-xs sub-title">{context.state.locale.InviteFriend.hero_title}</div>
                                <div className="text-muted">{context.state.locale.InviteFriend.description}</div>
                                <div className="invitePopup">
                                    <div className="invite-friends-share">
                                        <div className="row">
                                            <div className="invite-url-box">{origin + '/home/invite/' + this.props.BucketData.inviteData.code}</div>
                                            <CopyToClipboard onCopy={() => this.onCopyUrl(context.state.locale.InviteFriend.copy_url_msz)} text={origin + '/home/invite/' + this.props.BucketData.inviteData.code}>
                                                <div className="copy-clip-block m-l-sm">
                                                    <i className="icon-copy-file"></i>
                                                </div>
                                            </CopyToClipboard>
                                            <div className="copy-clip-block m-l-sm">
                                                <i className="icon-share"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="or-container auto-margin">
                                        <hr />
                                        <span className="or">{context.state.locale.InviteFriend.or_text}</span>
                                    </div>
                                    <div className="text-center m-b-sm">
                                        <div className="p-t text-bold">{context.state.locale.InviteFriend.copy_league_code}</div>
                                        <div className="m-sm invite-friend-code">{this.props.BucketData.inviteData.code}</div>
                                        <CopyToClipboard onCopy={() => this.onCopyCode(context.state.locale.InviteFriend.copy_code_msz)} text={this.props.BucketData.inviteData.code}>
                                            <div className="copy-clip-block">
                                                <i className="icon-copy-file"></i>
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                    </Modal>
                )}
            </MyContext.Consumer>
        );
    }
}