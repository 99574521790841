/* eslint-disable no-sequences, eqeqeq */
import React from 'react'
import { Row, Col, Button, Modal, FormGroup } from 'react-bootstrap';
import FloatingLabel from 'floating-label-react';
import { MyContext } from '../context';
import { inputStyle } from '../helper/input-style';
import GoogleLogin from 'react-google-login';
import Validation from './../helper/validation';
import Select from 'react-select';
import { notify } from 'react-notify-toast';
import Images from '../components/images';
import WSManager from "../helper/WSManager";
import {API, Auth} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import { SocialLogin } from './../components';
import { gapi } from 'gapi-script';

const md5 = require('md5');

export default class Signup extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            countries: [],
            first_name: '',
            last_name: '',
            user_name: '',
            email: '',
            password: '',
            phone_no: '',
            master_country_id: '',
            SelectedCountry: {},
            SelectedCountryCode: {},
            terms: true,
            formValid: false,
            posting: false,
            isShowPassword: false
        };
    }

    getCountryList = () => {
        let SelectedCountry = ''
        let SelectedCountryCode = ''
        let s3_param = [
            API.S3_GET_COUNTRY_LIST
        ]
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_COUNTRY_LIST, {}).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let countries = _.cloneDeep(responseJson.data.country_list);
                let countries_phonecode = _.cloneDeep(responseJson.data.country_list);
                _.map(countries, (item, idx) => {
                    item.label = item.country_name;
                    item.value = item.phonecode;
                    if (item.phonecode == 971) {
                        SelectedCountry = item;
                    }
                    return item;
                })
                _.map(countries_phonecode, (item, idx) => {
                    item.label = '+' + item.phonecode;
                    item.value = item.master_country_id;
                    if (item.phonecode == 971) {
                        SelectedCountryCode = item;
                    }
                    return item;
                })
                this.setState((state, props) => {
                    return {
                        countries: countries,
                        countries_phonecode: countries_phonecode,
                        SelectedCountry: SelectedCountry,
                        SelectedCountryCode: SelectedCountryCode
                    }
                });
            }
        });
    }

    getValidationState(type, value) {
        return Validation.validate(type, value)
    }

    checkValidate = (e, context)=>{
        console.log(e.target.value.length)
        if(e.target.name  == 'first_name')
        {
        if(e.target.value.length <3)
        {
            notify.show(context.state.locale.Signup.first_name_val, "error", 3000);
            return;
        }
    }
    if(e.target.name  == 'last_name')
    {
    if(e.target.value.length <3)
    {
        notify.show(context.state.locale.Signup.last_name_val, "error", 3000);
        return;
    }
}
        
        //alert(e.target.length);
        // notify.show(responseJson.message, "success", 5000);
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, this.validateForm);
    }

    SelectChangeHandle = (el, data) => {
        this.setState({ [el]: data }, this.validateForm);
        let SelectedData = _.cloneDeep(data)
        switch (el) {
            case 'SelectedCountry':
                SelectedData.label = '+' + SelectedData.phonecode;
                SelectedData.value = SelectedData.master_country_id;
                this.setState({ SelectedCountryCode: SelectedData }, this.validateForm);

                break;
            case 'SelectedCountryCode':
                SelectedData.label = SelectedData.country_name;
                SelectedData.value = SelectedData.phonecode;
                this.setState({ SelectedCountry: SelectedData }, this.validateForm);

                break;

            default:
                break;
        }

    }
    validateForm() {
        this.setState({
            formValid: (this.state.phone_no.length == 0 ?
                    Validation.validate('first_name', this.state.first_name) == 'success'
                    && Validation.validate('last_name', this.state.last_name) == 'success'
                    && Validation.validate('user_name', this.state.last_name) == 'success'
                    && Validation.validate('email', this.state.email) == 'success'
                    && Validation.validate('password', this.state.password) == 'success'
                    : 
                    Validation.validate('first_name', this.state.first_name) == 'success'
                    && Validation.validate('last_name', this.state.last_name) == 'success'
                    && Validation.validate('user_name', this.state.last_name) == 'success'
                    && Validation.validate('email', this.state.email) == 'success'
                    && Validation.validate('password', this.state.password) == 'success'
                    && Validation.validate('phone_no', this.state.phone_no) == 'success'
                )
        });
    }

    refreshPage() {
        window.location.reload();
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ posting: true });
        let param = {
            "first_name": this.state.first_name,
            "last_name": this.state.last_name,
            "user_name": this.state.user_name,
            "email": this.state.email,
            "password": md5(this.state.password),
            "phone_no": this.state.phone_no,
            "master_country_id": this.state.SelectedCountryCode.master_country_id,
            "terms": this.state.terms,
            "device_type": NC.deviceType,
            "device_id": NC.deviceID,
        }
        WSManager.Rest(API.signup, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({ posting: false });
                this.props.modalHide('SignupModalShow');
                this.props.modalShow('ThankyouSignupModalShow', { email: this.state.email });
            } else {
                this.setState({ posting: false });
            }
        });
    }

    responseGoogle = (googleUser, isSuccess) => {
     

        if (googleUser) {
            var id_token = googleUser.getAuthResponse().id_token;
            var googleId = googleUser.getId();


            let param = {
                "email": googleUser.profileObj.email,
                "google_id": googleId,
                "google_access_token": id_token,
                "password": '',
                "device_type": NC.deviceType,
                "device_id": NC.deviceID,
            }

            WSManager.Rest(API.login, param).then((responseJson) => {
                if (responseJson.response_code === NC.successCode) {
                    Auth.setAuth(responseJson.data.session_key)
                    WSManager.setProfile(responseJson.data.user_profile);
                    WSManager.setMultiLeg(true);
                    this.setState({ posting: false });
                    this.props.loggedInStatusUpdate();
                    this.props.modalHide('LoginModalShow');
                    this.refreshPage();
                } else {
                    this.setState({ posting: false });
                }
            })
        }

    }
    onFacebookSuccess = (user) => {
        if (user) {
            let param = {
                "email": user._profile.email,
                "facebook_id": user._profile.id,
                "facebook_access_token": user._token.accessToken,
                "password": '',
                "device_type": NC.deviceType,
                "device_id": NC.deviceID,
            }
            WSManager.Rest(API.login, param).then((responseJson) => {
                if (responseJson.response_code === NC.successCode) {
                    Auth.setAuth(responseJson.data.session_key)
                    WSManager.setProfile(responseJson.data.user_profile);
                    WSManager.setMultiLeg(true);
                    this.setState({ posting: false });
                    this.props.loggedInStatusUpdate();
                    this.props.modalHide('LoginModalShow');
                    this.refreshPage();
                } else {
                    this.setState({ posting: false });
                }
            })
        }
    }

    onLoginFailure(err) {
        console.error(err)
    }

    componentDidMount() {
        gapi.load('client:auth2',()=>{
            gapi.auth2.init({clientId:NC.GPLUS_ID})
        })
        this.getCountryList()
    }

    showPassword = () => {
        this.setState({
            isShowPassword: !this.state.isShowPassword
        })
    }

    render() {
        const {
            countries,
            // countries_phonecode,
            // master_country_id,
            first_name,
            last_name,
            user_name,
            email,
            password,
            phone_no,
            formValid,
            posting,
            isShowPassword,
            SelectedCountry,
            SelectedCountryCode
        } = this.state;
        const inputStyleUpdate = {
            ...inputStyle,
            input: {...inputStyle.input, background: 'transparent'}
        }
        return (

            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.SignupModalShow}
                        onHide={() => this.props.modalHide('SignupModalShow')}
                        dialogClassName="custom-modal"
                    >
                        <Modal.Header closeButton className='custom-modal-header'>
                            <div className="header-link">{context.state.locale.Signup.already_a_member} <span className='link' onClick={() => (this.props.modalHide('SignupModalShow'), this.props.modalShow('LoginModalShow'))}>{context.state.locale.Signup.login_label}</span></div>
                            <div className="title">{context.state.locale.Signup.hero_title}</div>
                        </Modal.Header>
                        <form onSubmit={this.onSubmit}>
                            <Modal.Body>
                                {/* SOCIAL(s) */}
                                {
                                    !NC.SocialLoginDisable &&
                                    <div className="socail-region">
                                    <div className="title">{context.state.locale.Signup.social_text}</div>
                                    <div className="content">
                                        <div className="social-item">
                                            <SocialLogin {...this.props} onFacebookSuccess={this.onFacebookSuccess} />
                                            <img src={Images.FACEBOOK_IC} alt="" />
                                            <div className="label">{context.state.locale.Signup.facebook}</div>
                                        </div>
                                        <div className="social-item">
                                            <GoogleLogin
                                                style={{ opacity: 0 }}
                                                clientId={NC.GPLUS_ID}
                                                buttonText="GOOGLE"
                                                scope="email"
                                                autoLoad={false}
                                                icon={false}
                                                fetchBasicProfile={false}
                                                className="google-login-btn"
                                                onSuccess={(user) => this.responseGoogle(user, true)}
                                                onFailure={(user) => this.responseGoogle(user, false)}
                                            >
                                            </GoogleLogin>
                                            <img src={Images.GOOGLE_IC} alt="" />
                                            <div className="label">{context.state.locale.Signup.google}</div>
                                        </div>
                                    </div>
                                    {/* <div className="sap">{context.state.locale.Signup.or_signup}</div> */}
                                </div>
                                }
                                {/* NATIVE FORM */}

                                <Row>
                                    <Col xs={6} className='field-sap'>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('first_name', first_name)}>
                                            <FloatingLabel
                                                styles={inputStyleUpdate}
                                                id='first_name'
                                                name='first_name'
                                                placeholder={context.state.locale.Signup.first_name_placeholder}
                                                type='text'
                                                value={this.state.first_name}
                                                onChange={this.handleChange}
                                                onBlur = {(e)=>{this.checkValidate(e, context)}}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('last_name', last_name)}>
                                            <FloatingLabel
                                                styles={inputStyleUpdate}
                                                id='last_name'
                                                name='last_name'
                                                placeholder={context.state.locale.Signup.last_name_placeholder}
                                                type='text'
                                                value={this.state.last_name}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('user_name', user_name)}>
                                            <FloatingLabel
                                                styles={inputStyleUpdate}
                                                id='user_name'
                                                name='user_name'
                                                placeholder={context.state.locale.Signup.username_placeholder}
                                                type='text'
                                                value={this.state.user_name}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup
                                            className='input-label-center no-margin-b'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('password', password)}>
                                            <FloatingLabel
                                                styles={inputStyleUpdate}
                                                id='password'
                                                name='password'
                                                placeholder={context.state.locale.Signup.password_placeholder}
                                                type={isShowPassword ? 'text' : 'password'}
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                            />
                                            <i className={`password-eye ${isShowPassword ? 'icon-eye-alt' : 'icon-eye-cancel'}`} onClick={() => this.showPassword()}></i>
                                        </FormGroup>
                                        <div className='clearfix'>
                                            <small className="text-muted pull-right m-t-xs" >{context.state.locale.Signup.min_pwd_characters}</small>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('email', email)}>
                                            <FloatingLabel
                                                styles={inputStyleUpdate}
                                                id='email'
                                                name='email'
                                                placeholder={context.state.locale.Signup.email_placeholder}
                                                type='email'
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12}>
                                        <FormGroup
                                            className='input-label-center zindex2'
                                            controlId="formBasicText">
                                            <label className="select-field-transparent-label fs13">Country</label>
                                            <Select
                                                className='select-field-transparent'
                                                classNamePrefix='select'
                                                placeholder={context.state.locale.Signup.country_placeholder}
                                                isSearchable={false}
                                                value={SelectedCountry}
                                                onChange={Event => this.SelectChangeHandle('SelectedCountry', Event)}
                                                options={countries}>
                                            </Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='phone-wrap'>
                                    <Col xs={4}>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText">
                                            {/* <Select
                                                className='select-field-transparent'
                                                classNamePrefix='select'
                                                placeholder={context.state.locale.Signup.code_placeholder}
                                                isSearchable={true}
                                                value={SelectedCountryCode}
                                                onChange={Event => this.SelectChangeHandle('SelectedCountryCode', Event)}
                                                options={countries_phonecode}>
                                            </Select> */}
                                            <FloatingLabel
                                                styles={inputStyleUpdate}
                                                readOnly
                                                type='text'
                                                value={SelectedCountryCode.label}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={8}>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('phone_no', phone_no)}>
                                            <FloatingLabel
                                                styles={inputStyleUpdate}
                                                id='phone_no'
                                                name='phone_no'
                                                placeholder={context.state.locale.Signup.mobile_placeholder}
                                                type='text'
                                                value={this.state.phone_no}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col xs={12}>
                                        <div className="signup-footer-text">
                                        {context.state.locale.Signup.disclaimer_start}<br />{context.state.locale.Signup.disclaimer_end} <a href='/terms' target='_blank'>{context.state.locale.Signup.terms_and_conditions}</a>
                                        </div>
                                    </Col>
                                </Row> */}
                            </Modal.Body>
                            <Modal.Footer className='custom-modal-footer'>
                                <Button bsStyle="primary" disabled={!formValid || posting} type='submit'>{context.state.locale.Signup.submit_label}</Button>
                            </Modal.Footer>
                        </form>
                    </Modal>

                )}
            </MyContext.Consumer>
        );
    }
}