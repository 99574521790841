import React from 'react';
import {Col, Row } from 'react-bootstrap';

import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";

import ReactHtmlParser from 'react-html-parser';
import { MyContext } from '../context';
import {Helmet} from "react-helmet";

export default class Prizes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstPrizeDetail: '',
            secondPrizeDetail: '',
            thirdPrizeDetail: '',
        }
    }
    componentWillMount() {
        let param = {
        }
        this.setState({ isLoaderShow: true })
        WSManager.Rest(API.GET_PRIZE_DATA, param).then((responseJson) => {
            this.setState({ isLoaderShow: false })
            if (responseJson.response_code === NC.successCode) {
                if (responseJson.data.prizes.length > 2) {
                    this.setState({
                        firstPrizeDetail: responseJson.data.prizes[0],
                        secondPrizeDetail: responseJson.data.prizes[1],
                        thirdPrizeDetail: responseJson.data.prizes[2],
                        PageContent: responseJson.data.page_content
                    })
                }
            }
        })
    }
    render() {
        const {
            firstPrizeDetail,
            secondPrizeDetail,
            thirdPrizeDetail,
            PageContent
        } = this.state;
        return (

            <MyContext.Consumer>
                {(context) => (

                   <div className="container main-container">
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.prizes_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.prizes_description} />
                        </Helmet>
                        <Row>
                            <Col lg={9} className="auto-margin DashBoardStyle prize-page">
                                <div className="prize-page-wrapper">
                                    <div className="prizeContainer row">
                                        <div className="col-md-12 row m">
                                            <div className="col-md-5 prize-heading">
                                                <div className="titleWin">{context.state.locale.Prizes.win}</div>
                                                <div className="subtitleWin">{context.state.locale.Prizes.cash_prizes}</div>
                                            </div>
                                            <div className="col-md-7 auto-margin">
                                                <div className="second-prize">
                                                    <div className="prize-detail">
                                                        <div className="sar">{context.state.locale.Prizes.usd_currency_label}</div>
                                                        <div className="money">{secondPrizeDetail.prize_money}</div>
                                                    </div>
                                                </div>

                                                <div className="first-prize">
                                                    <div className="prize-detail">
                                                        <div className="sar">{context.state.locale.Prizes.usd_currency_label}</div>
                                                        <div className="money">{firstPrizeDetail.prize_money}</div>
                                                    </div>
                                                </div>


                                                <div className="third-prize">
                                                    <div className="prize-detail">
                                                        <div className="sar">{context.state.locale.Prizes.usd_currency_label}</div>
                                                        <div className="money">{thirdPrizeDetail.prize_money}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="static-page-wrap">
                                {/* <h5 className='text-center'>COMING SOON</h5> */}
                                    {ReactHtmlParser(PageContent)}
                                </div>
                            </Col>
                        </Row>
                    </div>

                )}
            </MyContext.Consumer>
        )
    }
}