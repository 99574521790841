/* eslint-disable no-mixed-operators, eqeqeq, no-unused-vars */
import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";

import moment from 'moment';
import Moment from 'react-moment';

import Slider from "react-slick";
import RosterLoader from '../views/RosterLoader';
import LineLoader from '../views/LineLoader';
import Images from '../components/images';

import { MyContext } from '../context';
import SimpleImageSlider from "ac-react-simple-image-slider";
import AdsContainer from '../components/AdsContainer';
var durationForBanner = 10;
// var durationForBanner

let momentLib;
export default class FixturesModal extends React.Component {

    componentWillMount = (e) => {
        let advBanner = WSManager.getAdBanner();
        if(advBanner && advBanner.advertisements != undefined)
        {
            this.callAdvBanner(advBanner);
        }
        
    }

    /**
    * ADVERSTMENT
    */
    callAdvBanner = (data) => {
        let bannerData = data.advertisements;
        let lowerBanner = [];
        let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
        for (let k = 0; k < bannerData.length; k++) {
            if (bannerData[k].ad_position_id == 15 && bannerData[k].league_id == sportsLg) {
                bannerData[k]["src"] = bannerData[k].image_adsense
                bannerData[k]["id"] = bannerData[k].ads_unique_id
                lowerBanner.push(bannerData[k])
            }
        }
        this.setState({
            lowerBanner: lowerBanner
        }, () => {
            this.getBannerIndex();
        })
    }

    /**
    * BANNER CLICK FOR LOWER BANNER 
    */
    getBannerIndex = (e) => {
        for (let i = 0; i <= this.state.lowerBanner.length - 1; i++) {
            this.task(this.state.lowerBanner[i], i);
        }
    }
    task = (data, i) => {
        let a = this.state.lowerBanner.length - 1;
        setTimeout(function () {
            this.setState({
                currentBanner: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndex();
                }.bind(this), durationForBanner * 1000)

            }
        }.bind(this), durationForBanner * 1000 * i);

    }

    getBannerClick = (from) => {
        if (from == 2) {
            window.open(this.state.currentBanner);
        }

    }


    // Methods
    // ------------------------------

    // GET_ALL_WEEK
    GetAllWeek = () => {
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id" : WSManager.getMultiLegData() ? WSManager.getMultiLegData().sports_id  : 0,
            "league_id" : WSManager.getMultiLegData() ? WSManager.getMultiLegData().league_id : 0,
            "league_week": "1"
        }
        this.setState({
            posting: true
        })

        let sportsId =  WSManager.getMultiLegData().sports_id;
        let leagueId =  WSManager.getMultiLegData().league_id;
        let s3_param = [
            API.S3_GET_ALL_WEEK,
            sportsId,
            leagueId
        ]
console.log('sm param ===',s3_param);
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_ALL_WEEK, param).then(responseJson => {
            if (responseJson.response_code === NC.successCode) {
                responseJson = responseJson.data;
                this.setState({
                    AllWeek: responseJson.all_week,
                    CurrentWeek: responseJson.current_week,
                    NextWeek: responseJson.next_week,
                    SelectedWeek: responseJson.current_week,
                })
                this.GetFixtureList(responseJson.next_week);
                setTimeout(() => {
                    if (!_.isNull(this.slider)) {
                        this.slider.slickGoTo(responseJson.next_week - 1)
                    }
                }, 100);
            }
        })
    }

    // GET_FIXTURE_LIST
    GetFixtureList = (league_week) => {
        let param = {
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "league_week": league_week
            
            
        }
        this.setState({
            posting: true,
            SelectedWeek: league_week
        })

        let s3_param = [
            API.S3_GET_FIXTURE_LIST,
            // NC.sportsId,
            // NC.leagueId,
            WSManager.getMultiLegData().sports_id,
             WSManager.getMultiLegData().league_id,
            league_week,
        ]

        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_FIXTURE_LIST, param).then(responseJson => {
            if (responseJson.response_code === NC.successCode) {
                responseJson = responseJson.data;
                this.setState({
                    FixturesList: []
                }, () => {
                    this.setState({
                        posting: false,
                        FixturesList: responseJson.match_list
                    })
                })
            }
        })
    }

    onSlideChange(index) {
        if (this.state.AllWeek && this.state.AllWeek.length > index) {
            this.GetFixtureList(this.state.AllWeek[index].season_week)
        }
    }

    getUtcToLocal = (date) => {
        return moment.utc(date).local().format();
    }

    // Lifecycle
    // ------------------------------
    constructor(props) {
        super(props);
        this.state = {
            posting: false,
            opening: false,
            openingItem: '',
            SelectedWeek: '',
            CurrentFixtuerSelected: ''
        }
    }

    getUtcToLocal = (date) => {
        let localDate = moment(date).utc(true).local().format();
        return localDate;
    }
    isDateTimePast = (season_scheduled_date) => {
        let date = new Date(season_scheduled_date + ' UTC')
        let now = new Date();
        let status = false;
        if (now > date) status = true;
        return status;
    }

    componentDidMount() {
        if (this.props.language == 'ar') momentLib = require('moment/locale/ar');
        this.GetAllWeek();
    }

    keyToTitle = (str) => {
        return str.replace(/_{1,}/g, ' ').replace(/(\s{1,}|\b)(\w)/g, function (m, space, letter) {
            return space + letter.toUpperCase();
        })
    }

    getFixtureDetail() {
        const { MatchSummary } = this.state;
        return (
            <div className="fixtures-item-container">
                {
                    _.map(MatchSummary.stats, (item, idx) => {
                        return (
                            <div className="fixtures-box" key={idx}>
                                {
                                    (!_.isEmpty(item.home) || !_.isEmpty(item.away)) &&
                                    <Fragment>
                                        <div className="title">{MatchSummary.stats_lang[idx]}</div>
                                        <div className="fixtures-box-content">
                                            <div className="box">
                                                {
                                                    item.home &&
                                                    _.map(item.home, (obj, index) => {
                                                        return (
                                                            <span className='text-bold' title={obj.full_name} key={index}>
                                                                <span className='text-ellipsis'>{obj.full_name}</span>
                                                                {
                                                                    Number(obj.value) > 0 &&
                                                                    <Fragment>
                                                                        {" "}({obj.value})
                                                                    </Fragment>
                                                                }
                                                            </span>
                                                        )
                                                    })
                                                    ||
                                                    '--'
                                                }
                                            </div>
                                            <div className="box">
                                                {
                                                    item.away &&
                                                    _.map(item.away, (obj, index) => {
                                                        return (
                                                            <span className='text-bold' title={obj.full_name} key={index}>
                                                                <span className='text-ellipsis'>{obj.full_name}</span>
                                                                {
                                                                    Number(obj.value) > 0 &&
                                                                    <Fragment>
                                                                        {" "}({obj.value})
                                                                    </Fragment>
                                                                }
                                                            </span>
                                                        )
                                                    })
                                                    ||
                                                    '--'
                                                }
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        )
                    })
                }


                {/* <div className="fixtures-box">
                    <div className="title">Assits</div>
                    <div className="fixtures-box-content">
                        <div className="box">
                            <span className='text-bold'>Llorente</span>
                        </div>
                        <div className="box">
                        </div>
                    </div>
                </div>

                <div className="fixtures-box">
                    <div className="title">Yellow Cards</div>
                    <div className="fixtures-box-content">
                        <div className="box">
                        </div>
                        <div className="box">
                            <span className='text-bold'>Yedlin</span>
                        </div>
                    </div>
                </div>

                <div className="fixtures-box">
                    <div className="title">Saves</div>
                    <div className="fixtures-box-content">
                        <div className="box">
                            <span className='text-bold'>Lloris (2)</span>
                        </div>
                        <div className="box">
                            <span className='text-bold'>Dubravka</span>
                        </div>
                    </div>
                </div>

                <div className="fixtures-box">
                    <div className="title">Bonus</div>
                    <div className="fixtures-box-content">
                        <div className="box">
                            <span className='text-bold'>Son (3)</span>
                            <span className='text-bold'>Vertonghen (2)</span>
                            <span className='text-bold'>Trippier (2)</span>
                        </div>
                        <div className="box">
                        </div>
                    </div>
                </div>

                <div className="fixtures-box">
                    <div className="title">Bonus Points System</div>
                    <div className="fixtures-box-content">
                        <div className="box">
                            <span className='text-bold'>Son (36)</span>
                            <span className='text-bold'>Vertonghen (30)</span>
                            <span className='text-bold'>Trippier (30)</span>
                            <span className='text-bold'>Alderweireld (27)</span>
                            <span className='text-bold'>Lloris (24)</span>
                        </div>
                        <div className="box">
                            <span className='text-bold'>Ritchie (16)</span>
                            <span className='text-bold'>Schar (16)</span>
                            <span className='text-bold'>Longstaff (15)</span>
                            <span className='text-bold'>Lejeuune (14)</span>
                            <span className='text-bold'>Hayden (13)</span>
                        </div>
                    </div>
                </div> */}

            </div>
        )
    }
    toggleFixtureDetail = (item) => {
        if (this.state.CurrentFixtuerSelected != item.season_id) {
            let param = {
                // "league_id": NC.leagueId,
                "league_id" : WSManager.getMultiLegData().league_id,
                "season_game_uid": item.season_game_uid
            }
            this.setState({
                openingItem: item.season_id
            })
            WSManager.Rest(API.GET_MATCH_SUMMARY, param).then((responseJson) => {
                if (responseJson.response_code === NC.successCode) {
                    responseJson = responseJson.data;
                    this.setState({
                        MatchSummary: responseJson.match_summary,
                        openingItem: ''
                    }, () => {
                        this.setState({ CurrentFixtuerSelected: item.season_id })
                    })
                } else {
                    this.setState({
                        opening: false,
                        openingItem: ''
                    })
                }
            })
        } else {
            this.setState({ CurrentFixtuerSelected: '' });
        }
    }
    render() {
        const {
            FixturesList,
            posting,
            AllWeek,
            SelectedWeek,
            CurrentFixtuerSelected,
            opening,
            openingItem
        } = this.state
        var settings = this.props.settings;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Row>
                        {
                            context.state.language == 'ar' ? settings.rtl = true : null
                        }
                        <Col lg={12}>
                            <Row>
                                <Col lg={12}>
                                    {
                                        settings &&
                                        <Slider className="weeklist-slider" ref={slider => (this.slider = slider)} {...settings}>
                                            {
                                                _.map(AllWeek, (week, idx) => {
                                                    return (
                                                        <div className={`weeklist-slider-item ${week.season_week == SelectedWeek ? 'selected' : ''}`} key={idx} onClick={() => this.GetFixtureList(week.season_week)}> {WSManager.getWeekLabel( WSManager.getMultiLegData().week_label[week.season_week])} </div>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className={`roster-filter-loader fade ${posting ? 'in' : null}`}><RosterLoader /></div>
                                </Col>

                            </Row>
                            {
                                this.state.lowerBanner && this.state.lowerBanner.length > 0 && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }}>
                                    <SimpleImageSlider
                                        showArrows={false}
                                        showDots={false}
                                        data={this.state.lowerBanner}
                                        duration={durationForBanner}
                                    />
                                </div>
                            }
                            {/* <div id = {'Leaderboard'} className='sponsor-logo-bg '></div> */}
                            <AdsContainer />
                            {/* <div className=' sponsor-logo-bg'>
                                <img style={{ width: '100%', marginBottom: '10px' }} src={Images.SPONSER_BANNER} ></img>
                            </div> */}
                            <Row>
                                <Col lg={12} className='fixtures-list-wrap'>
                                    {
                                        _.map(FixturesList, (Fixture, idx) => {
                                            return (
                                                <div className="fixtures-list" key={idx}>
                                                    <div className="fixtures-list-header">
                                                        <Moment date={this.getUtcToLocal(Fixture[0].season_scheduled_date)} format="dddd, MMM DD" />
                                                    </div>
                                                    {
                                                        _.map(Fixture, (item, index) => {
                                                            return (
                                                                <div className="fixtures-item" key={index}>
                                                                    <div className={`fixtures-item-header ${item.season_id == CurrentFixtuerSelected ? 'active' : ''}`}
                                                                        onClick={!opening ? () => this.toggleFixtureDetail(item) : null}>
                                                                        <div className="cell left-cell">
                                                                            <figure className="team-falg">
                                                                                <span className="thumbnail">
                                                                                    <img src={item.flag_home} alt="" />
                                                                                </span>
                                                                            </figure>
                                                                            <span className={`name ${item.home_team_goal > item.away_team_goal ? 'green' : ''}`} title={item.home_team_name}>{item.home_team_name}</span>
                                                                        </div>
                                                                        <div className="cell ovel-cell">
                                                                            {
                                                                                this.isDateTimePast(item.season_scheduled_date, item) ?
                                                                                    <span>

                                                                                        {
                                                                                            item.home_team_goal + ' - ' + item.away_team_goal
                                                                                        }
                                                                                    </span>
                                                                                    :
                                                                                    <Moment date={this.getUtcToLocal(item.season_scheduled_date)} format="H:mm" />
                                                                            }
                                                                        </div>
                                                                        <div className="cell right-cell">
                                                                            <figure className="team-falg">
                                                                                <span className="thumbnail">
                                                                                    <img src={item.flag_away} alt="" />
                                                                                </span>
                                                                            </figure>
                                                                            <span className={`name ${item.home_team_goal < item.away_team_goal ? 'green' : ''}`} title={item.away_team_name}>{item.away_team_name}</span>
                                                                        </div>
                                                                        {
                                                                            item.season_id == openingItem &&
                                                                            <LineLoader />
                                                                        }
                                                                    </div>
                                                                    {
                                                                        item.season_id == CurrentFixtuerSelected &&
                                                                        this.getFixtureDetail()
                                                                    }

                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </MyContext.Consumer>
        )
    }
}