/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq, no-unused-vars */
import React from 'react';
import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import Moment from 'react-moment';
import { MyContext } from '../context';
import RosterLoader from '../views/RosterLoader';
import { Col } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Helmet} from "react-helmet";
let momentLib;
export default class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NotificationList: [],
            isLoaderShow: false,
            hasMore: false,
            Offset: NC.CURRENT_OFFSET,
        }
    }


    readAllNotification = () => {
        WSManager.RestGet(API.UPDATE_READ_ALL).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
            }
        })
    }

    getNotification = () => {
        this.setState({ isLoaderShow: true })
        let param = JSON.stringify({
            "limit": NC.ITEMS_PERPAGE_LG,
            "offset": this.state.Offset
        })
        WSManager.Rest(API.GET_NOTIFICATION, param).then((responseJson) => {
            this.setState({ isLoaderShow: false })
            if (responseJson.response_code === NC.successCode) {
                var tmpNotificationList = this.state.NotificationList

                if (this.state.Offset == NC.CURRENT_OFFSET) {
                    tmpNotificationList = [];
                    this.setState({
                        NotificationList: tmpNotificationList
                    });
                }

                tmpNotificationList = _.concat(tmpNotificationList, responseJson.data.notification_list)

                this.setState({
                    NotificationList: tmpNotificationList,
                    hasMore:responseJson.data.is_load_more
                });
                if (tmpNotificationList.length <= NC.ITEMS_PERPAGE_LG) {
                    setTimeout(() => {
                        this.readAllNotification();
                    }, 1500);
                }
            }
        })
    }


    _renderTagMessage = (rowData) => {
        var msg = rowData.message
        //console.log(rowData,"rowData");
        if(rowData.notification_type == '51'){
            var msg = rowData.message || '';
           
            var content = JSON.parse(rowData.content);
           
        
            msg = msg.replace("{{result_text}}",content.result_text);
         
            return msg;
        }
        else{
            var arrySplit = msg.split(/ /)
            for (var i = 0; i < arrySplit.length; i++) {
                arrySplit[i] = arrySplit[i] + ' '
                for (var obj in JSON.parse(rowData.content)) {
                     if (arrySplit[i] == "{{" + obj + "}} ") {
                       
                        arrySplit[i] = <span key={obj} >{JSON.parse(rowData.content)[obj]} </span>
                    }
                    if (arrySplit[i] == "{{contest_name}}, ") {
                        
                        arrySplit[i] = <span key={obj} >{JSON.parse(rowData.content)[obj]}, </span>
                    }
                    
                }
            }
            return arrySplit;
        }
        
       
    }
    refreshPage() {
        window.location.reload();
    }
    fetchMoreData = () => {
        let offset = this.state.Offset + NC.ITEMS_PERPAGE_LG;
        this.setState({ Offset: offset });
        this.getNotification();
    }
    componentDidMount = () => {
        if(this.props.language == 'ar') momentLib = require('moment/locale/ar');
        this.getNotification();
    }
    

    render() {
        const { NotificationList, isLoaderShow, hasMore } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="container main-container notifications">
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.notifications_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.notifications_description} />
                        </Helmet>
                        <Col lg={9} className="auto-margin">
                            <div className="m">
                                <div className="league-heading">
                                    <div className="title">{context.state.locale.Notification.hero_title}</div>
                                    <div className="subtitle">{context.state.locale.Notification.sub_title}</div>
                                </div>
                                <InfiniteScroll
                                    dataLength={NotificationList.length}
                                    next={this.fetchMoreData}
                                    hasMore={hasMore}>
                                    {
                                        _.map(NotificationList, (item, idx) => {
                                            return (
                                                <div className={item.notification_status == 1 ? 'notification-block unread' : 'notification-block'} key={idx}>
                                                    <i className="icon-mail-alt"></i>
                                                    <p>
                                                        {this._renderTagMessage(item)}
                                                        <small>
                                                            <Moment date={item.added_date} format="ddd, MMM DD - hh:mm A" />
                                                        </small>
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }
                                </InfiniteScroll>
                                <div className={`roster-filter-loader fade ${isLoaderShow ? 'in' : null}`}><RosterLoader /></div>
                            </div>
                        </Col>
                    </div>
                )}
            </MyContext.Consumer>
        );
    }
}
