/* eslint-disable eqeqeq */
import React from 'react';
import { Row, Col, Tab, NavItem, Nav } from 'react-bootstrap';

import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import Drawer from 'react-motion-drawer';
import { MyContext } from '../context';
import PitchViewField from '../views/PitchView';
import PitchViewLoader from '../views/PitchViewLoader';
import ListViewField from '../views/ListViewField';
import LineupInfo from '../views/LineupInfo';

import PlayerCard from '../Modals/PlayerCard';
import RosterLoader from '../views/RosterLoader';
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import SubstituteModal from '../Modals/SubstituteModal';
import SimpleImageSlider from "ac-react-simple-image-slider";
import AdsContainer from '../components/AdsContainer';
var durationForBanner = 10;
var durationForBannerUpper = 8;
export default class Points extends React.Component {

    // Methods
    // ------------------------------

    // GET_LINEUP_MASTER_DATA
    getLineupMasterData = () => {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
        }
        WSManager.Rest(API.GET_LINEUP_MASTER_DATA, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({
                    LineupFormationList: responseJson.data.lineup_formation
                });
                sessionStorage.setItem('SelectedFormation', responseJson.data.default_formation);
                if (WSManager.loggedIn()) {
                    this.getManageTeamMaster(true);
                }
            }
        })

    }

    // GET_MANAGE_TEAM_MASTER_DATA
    getManageTeamMaster = (autoSlide) => {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "is_performance_required": 1,
            "user_id": this.state.other_user_id,
            "week": this.state.SelectedWeek == '' ? '' : this.state.SelectedWeek.week
        }
        WSManager.Rest(API.GET_MANAGE_TEAM_MASTER_DATA, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({
                    user_join_count: responseJson.data.user_join_count,
                    my_performance: responseJson.data.my_performance,
                    active_week: responseJson.data.active_week,
                    next_week_start_time: responseJson.data.next_week_start_time,

                    contest_id: responseJson.data.contest_id,
                    next_week: responseJson.data.next_week,
                    lineup_master_id: responseJson.data.lineup_master_id
                })

                this._isFullFieldViewMode();
                if (responseJson.data.user_join_count != 0) {
                    this.getUserLineupDetail({
                        contest_id: responseJson.data.contest_id,
                        lineup_master_id: responseJson.data.lineup_master_id,
                        active_week: this.state.SelectedWeek == '' ? responseJson.data.active_week : this.state.SelectedWeek.week,
                        autoSlide: autoSlide
                    })
                } else {
                    if (_.isNull(sessionStorage.getItem(WSManager.getMultiLegData().league_abbr+'lineup'))) {
                        this.LineupCreate(this.state.LineupFormationList)
                    } else {
                        var lineup = JSON.parse(sessionStorage.getItem(WSManager.getMultiLegData().league_abbr+'lineup'));
                        var selected_formation = this.state.LineupFormationList.formation[this.state.SelectedFormation];
                        this.LineupCreate(selected_formation, lineup);
                    }
                }
            }
        })
    }

    // GET_USER_LINEUP
    getUserLineupDetail = (options) => {
        const { contest_id, active_week, lineup_master_id } = options;
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "lineup_master_id": lineup_master_id,
            "contest_id": contest_id,
            "week": active_week // Live week (Prev Scoring week)
        }
        this.setState({
            PitchViewLoaderInit: true
        })
        WSManager.Rest(API.GET_USER_LINEUP_DETAIL, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let data = responseJson.data;
                var selected_formation = !_.isEmpty(this.state.LineupFormationList) ? this.state.LineupFormationList.formation[data.formation] : {};
                this.setState({
                    SelectedFormation: data.formation,
                    MatchList: data.match_list,
                    team_name: data.team_name,
                    default_formation: data.default_formation,
                    manage_team_name: data.team_name,
                }, () => {

                    sessionStorage.setItem('SelectedFormation', data.formation);
                    _.map(responseJson.data.lineup, (player) => {
                        player.display_position = this._displayPosition(player.position)
                    })
                    if (data.lineup.length > 0) {
                        this.LineupCreate(selected_formation, data.lineup)
                    }

                    this.getUserTeamInfo(options);
                    this.GetLineupSubstitutePlayer(options)
                })
            }
        })
    }

    GetLineupSubstitutePlayer = (options) => {
        let param = {
            // "league_id": NC.leagueId,
            "league_id" : WSManager.getMultiLegData().league_id,
            "lineup_master_id": options.lineup_master_id,
            "week": options.active_week
        }

        let isSubstituteWeek = localStorage.getItem('isSubstituteWeek');
        if (!(_.isNull(isSubstituteWeek) || isSubstituteWeek != options.active_week)) return;

        WSManager.Rest(API.GET_LINEUP_SUBSTITUTE_PLAYER, param).then((response) => {
            if (!_.isEmpty(response.data)) {
                localStorage.setItem('isSubstituteWeek', options.active_week)
                this.setState({
                    SubstituteData: response.data
                }, () => {
                    this.setState({
                        SubstituteModalshow: true,
                    })
                })
            }
        })

    }

    getUserTeamInfo = (options) => {
        const { contest_id, active_week, lineup_master_id, autoSlide } = options;
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "lineup_master_id": lineup_master_id,
            "contest_id": contest_id,
            "week": active_week // Live week (Prev Scoring week)
        }
        WSManager.Rest(API.GET_USER_TEAM_INFO, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let data = responseJson.data;
                this.setState({
                    team_info: data.team_info,
                    is_editable: data.team_info.is_editable,
                    show_transfer: data.team_info.show_transfer,
                }, () => {
                    if (responseJson.data.team_info.scoring_weeks.length > 0) {
                        var tmpAllWeek = [];
                        var tmpSelectedWeek = ''

                        _.map(responseJson.data.team_info.scoring_weeks, (week) => {
                            tmpAllWeek.push(week);
                            if (this.state.SelectedWeek == '' && active_week == week.week) {
                                tmpSelectedWeek = week;
                            } else if (week.week == this.state.SelectedWeek.week) {
                                tmpSelectedWeek = week;
                            }
                        })
                        this.setState({
                            AllWeek: tmpAllWeek,
                            SelectedWeek: tmpSelectedWeek,
                        }, () => {
                            if (autoSlide) {
                                let weekSlide = Number(this.state.SelectedWeek.week)
                                setTimeout(() => {
                                    if (!_.isNull(this.slider)) {
                                        this.slider.slickGoTo(weekSlide)
                                    }
                                }, 1000);
                            }
                        })
                    }
                    this._isFullFieldViewMode();
                })
            }
        })
    }





    // LINEUP CREATE
    rosterContainsObject = (obj, list) => {
        var x;
        for (x in list) {
            if (list[x].player_uid == obj.player_uid) {
                return true;
            }
        }
        return false;
    }
    containsObject = (obj, list) => {
        var x;
        for (x in list) {
            if (list.hasOwnProperty(x) && list[x] === obj) {
                return true;
            }
        }
        return false;
    }

    _isFullFieldViewMode = () => {
        this.setState({
            isFullFieldViewMode: (this.state.is_editable == 1 && this.state.show_transfer == 0 && this.state.user_join_count == 0)
        })
    }
    _displayPosition = (pos, type, CONTEXT, single) => {
        let display_pos = type != 'NAME' ? NC.DISPLAYNAME_GOALKEEPER : NC.DISPLAYNAME_GOALKEEPER_FULL
        switch (pos) {
            case NC.DEFENDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_DEFENDER : NC.DISPLAYNAME_DEFENDER_FULL
                break;
            case NC.MIDFIELDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_MIDFIELDER : NC.DISPLAYNAME_MIDFIELDER_FULL
                break;
            case NC.FORWARDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_FORWARDER : NC.DISPLAYNAME_FORWARDER_FULL
                break;
            default:
                break;
        }
        if (CONTEXT) {
            display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_GOALKEEPER : CONTEXT.DISPLAYNAME_GOALKEEPER_FULL
            switch (pos) {
                case NC.DEFENDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_DEFENDER : CONTEXT.DISPLAYNAME_DEFENDER_FULL
                    break;
                case NC.MIDFIELDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_MIDFIELDER : CONTEXT.DISPLAYNAME_MIDFIELDER_FULL
                    break;
                case NC.FORWARDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_FORWARDER : CONTEXT.DISPLAYNAME_FORWARDER_FULL
                    break;
                default:
                    break;
            }
        }
        if (single) {
            display_pos = display_pos.slice(0, -1);
        }
        return display_pos;
    }
    playerNameSort = (name) => {
        if (!name) return
        var str = name.split(' ');
        str[0] = str[0].charAt(0)
        return str[0] + '. ' + str[str.length - 1]
    }

    LineupCreate = (FormationList, Lineuplist, isFormationProcess) => {
        this._isFullFieldViewMode()
        const Lineup = [];
        if (Lineuplist != undefined) {
            let CurrentFormation = FormationList;
            for (let i = 0; i < CurrentFormation.length; i++) {
                const element = CurrentFormation[i];
                var filterArr = Lineuplist.filter(function (item) {
                    return item.position == element.position
                })
                _.map(filterArr, (Player, idx) => {
                    if (idx < element.number_of_players) {
                        Lineup.push(Player);
                    } else {
                        Lineup.push(Player);
                    }
                })
            }
        }
        sessionStorage.setItem(WSManager.getMultiLegData().league_abbr+'lineup', JSON.stringify(Lineup));
        this.setState({
            LineupsList: Lineup,
            isLoaderShow: false,
            isRosterViewShow: true,
            PitchViewLoaderInit: false
        });
        if (_.isEmpty(this.state.LineupsListClone)) {
            this.setState({
                LineupsListClone: _.cloneDeep(Lineup)
            });
        }
    }

    modalShow = (Modal) => {
        this.setState({ [Modal]: true });

    }
    modalHide = (Modal) => {
        this.setState({ [Modal]: false });
    }
    playerCardModalInit = (Modal, PlayerData, isPlayerCardFieldView) => {
        this.setState({
            [Modal]: true,
            PlayerData: PlayerData || {},
            isPlayerCardFieldView: isPlayerCardFieldView || false
        });
    }
    DrawerRosterTrigger = (DrawerRosterOpen) => {
        this.setState({
            DrawerRosterOpen: !DrawerRosterOpen
        })
    }
    updateDimensions = () => {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }
    // Lifecycle
    // ------------------------------
    constructor(props) {
        super(props);
        this.state = {
            // Flags
            isPointScreen: true,
            is_editable: 1,
            show_transfer: 0,
            isMyTeamScreen: false,
            user_join_count: '',
            FieldLoader: false,
            CreateTeamModalShow: false,
            FixtureModalShow: false,
            ThankyouCreateTeamModalShow: false,
            PlayerCardModalShow: false,
            isFullFieldViewMode: false,
            isPlayerCardFieldView: false,
            DrawerRosterOpen: false,
            defaultRosterWidth: 991,

            // Asyn data
            SelectedFormation: _.isNull(sessionStorage.getItem('SelectedFormation')) ? NC.DEFAULT_FORMATION : sessionStorage.getItem('SelectedFormation'),
            LineupFormationList: [],
            LineupsList: [],
            LineupsListClone: [],
            TransferPlayerIn: [],
            TransferPlayerOut: [],
            team_info: [],
            PlayerData: [],
            BoosterRef: [],
            SelectedBooster: '', // 1 (Triple Captain), 2 (Bench Boost), 3 (Free Hit)

            //weeks,
            SelectedWeek: '',
            AllWeek: [],
            PitchViewLoaderInit: true,
            other_user_lineup_master_id: '',
            other_user_name: '',
            other_user_id: '',

            SubstituteModalshow: false,
            SubstituteData: []

        }
        this.RosterRef = React.createRef();
        this.BoosterRef = React.createRef();
    }
    componentWillMount() {
        if (!_.isUndefined(this.props.location.state)) {
            this.setState({ other_user_lineup_master_id: this.props.location.state.lineup_master_id, other_user_id: this.props.location.state.user_id, other_user_name: this.props.location.state.user_name })
        }
        this.getLineupMasterData();
        this.updateDimensions();
        let advBanner = WSManager.getAdBanner();
        if(advBanner && advBanner.advertisements != undefined)
        {
           this.callAdvBanner(advBanner);
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
      /**
     * ADVERSTMENT
     */
    callAdvBanner = (data) => {
        let bannerData = data.advertisements;
        let lowerBanner = [];
        let squareBanner = [];
        let topPtcBanner = [];
        let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
        for (let k = 0; k < bannerData.length; k++) {
            if (bannerData[k].ad_position_id == 9 && bannerData[k].league_id == sportsLg) {
                bannerData[k]["src"] = bannerData[k].image_adsense
                bannerData[k]["id"] = bannerData[k].ads_unique_id
                topPtcBanner.push(bannerData[k])
            }
            if (bannerData[k].ad_position_id == 10 && bannerData[k].league_id == sportsLg) {
                bannerData[k]["src"] = bannerData[k].image_adsense
                bannerData[k]["id"] = bannerData[k].ads_unique_id
                lowerBanner.push(bannerData[k])
            }
            if (bannerData[k].ad_position_id == 11 && bannerData[k].league_id == sportsLg) {
                bannerData[k]["src"] = bannerData[k].image_adsense
                bannerData[k]["id"] = bannerData[k].ads_unique_id
                squareBanner.push(bannerData[k])
            }
        }
        this.setState({
            lowerBanner: lowerBanner,
            squareBanner:squareBanner,
            topPtcBanner:topPtcBanner
        }, () => {
            this.getBannerIndex();
            // console.log(this.state.squareBanner,'sdssdfsquareBanner')
        })
    }

      /**
     * BANNER CLICK FOR LOWER BANNER 
     */
    getBannerIndex = (e) => {
        for (let i = 0; i <= this.state.lowerBanner.length - 1; i++) {
            this.task(this.state.lowerBanner[i], i);
        }
    }
    task = (data, i) => {
        let a = this.state.lowerBanner.length - 1;
        setTimeout(function () {
            this.setState({
                currentBanner: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndex();
                }.bind(this), durationForBanner* 1000)

            }
        }.bind(this), durationForBanner * 1000 * i);

    }


    /**
  * BANNER CLICK FOR UPPER BANNER 
  */
    getBannerIndexUpper = (e) => {
        for (let i = 0; i <= this.state.upperBanner.length - 1; i++) {
            this.taskUpper(this.state.upperBanner[i], i);
        }
    }
    
    taskUpper = (data, i) => {
        let a = this.state.upperBanner.length - 1;
        setTimeout(function () {
            this.setState({
                currentBannerUpper: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndexUpper();
                }.bind(this), durationForBannerUpper* 1000)

            }
        }.bind(this), durationForBannerUpper * 1000 * i);

    }

    /***
     * BANNER CLICK 
     */

    getBannerClick = (from) => {
        if (from == 1) {
            window.open(this.state.currentBannerUpper);
        }
        if (from == 2) {
            window.open(this.state.currentBanner);
        }

    }

    onGameWeekChange = (week) => {
        // this.state.SelectedWeek = week; 
        this.setState({
            SelectedWeek: week
        })
        this.getManageTeamMaster()
    }
    isFloat = (n) => {
        n = parseFloat(n)
        return Number(n) === n && n % 1 !== 0;
    }
    getSelectedWeekObj = () => {
        return this.state.SelectedWeek
    }
    render() {

        var settings = {
            rtl: false,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 3,
            centerMode: false,
            variableWidth: false,
            centerPadding: '0px',
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        };



        const {
            AllWeek,
            SelectedWeek,
            LineupsList,
            SelectedFormation,
            user_join_count,
            is_editable,
            show_transfer,
            isMyTeamScreen,
            FieldLoader,
            isFullFieldViewMode,
            team_info,
            team_name,
            my_performance,
            PlayerCardModalShow,
            PlayerData,
            DrawerRosterOpen,
            PitchViewLoaderInit,
            next_week,
            active_week,
            isPointScreen,

            // Substitute
            SubstituteModalshow,
            SubstituteData
        } = this.state;

        // Instance Properties
        // ------------------------------
        const RosterDrawerProps = {
            ...this.props,
            overlayColor: "rgba(0,0,0,0.8)",
            width: 320,
            right: true,
            fadeOut: true,
            open: DrawerRosterOpen,
            noTouchOpen: false
        };
        const FieldViewProps = {
            lineups: LineupsList,
            SelectedFormation: SelectedFormation,
            user_join_count: user_join_count,
            is_editable: is_editable,
            show_transfer: show_transfer,
            isMyTeamScreen: isMyTeamScreen,
            isPoint: true,

            team_info: team_info,
            isFullFieldViewMode: isFullFieldViewMode,
            playerNameSort: this.playerNameSort,
            RemovePlayerFromLineup: function () { },
            PositionSelectChange: function () { },
            displayPosition: this._displayPosition,
            playerCardModalInit: this.playerCardModalInit,
            DrawerRosterTrigger: this.DrawerRosterTrigger,
            isSwapingEnabled: false,
            next_week: next_week,
            active_week: active_week,
            isPointScreen: isPointScreen,
            SelectedWeek: SelectedWeek.week
        }
        const LineupInfoProps = {
            ...this.props,
            team_info: team_info,
            team_name: team_name,
            user_name: this.state.other_user_name,
            user_id: this.state.other_user_id,
            my_performance: my_performance,
            DrawerRosterTrigger: this.DrawerRosterTrigger,
            getSelectedWeekObj: this.getSelectedWeekObj,
            isPointScreen: true
        }
        const PlayerCardProps = {
            modalHide: this.modalHide,
            _displayPosition: this._displayPosition,
            PlayerCardModalShow: PlayerCardModalShow,
            PlayerData: PlayerData,
            isPlayerCardFieldView: false,
            rosterContainsObject: function () { },
            AddPlayerToLineup: function () { },
            RemovePlayerFromLineup: function () { },
            LineupUpdate: function () { },
            LineupsList: LineupsList,
            user_join_count: user_join_count,
        }

        const RosterView = (
            <div>
                {
                    user_join_count == 1 &&
                    <LineupInfo {...LineupInfoProps} />
                }
            </div>
        )

        const SubstituteModalProps = {
            isOpen: SubstituteModalshow,
            modalHide: this.modalHide,
            SubstituteData: SubstituteData
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className='container main-container'>
                    {this.state.topPtcBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('1') }}>
                             <SimpleImageSlider
                                 showArrows={false}
                                 showDots={false}
                                 data={this.state.topPtcBanner}
                                 duration={durationForBanner}
                             />
                         </div>}

                        <AdsContainer />
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.points_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.points_description} />
                        </Helmet>
                        {/* <div className="league-heading m-v">
                            <div className="title">Points</div>
                        </div> */}
                        {
                            context.state.language == 'ar' ? RosterDrawerProps.right = false : RosterDrawerProps.right = true
                        }

                        <Row className='d-block d-sm-block d-md-block d-lg-none'>
                            <Col sm={12}>
                                <Drawer className='drawer-roster-wrapper' {...RosterDrawerProps}
                                    onChange={open => this.setState({ DrawerRosterOpen: open })}>
                                    {this.state.defaultRosterWidth > this.state.innerWidth ? RosterView : ''}
                                </Drawer>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={12} lg={8}>
                                <Col lg={12} className="m-b">
                                    <Slider className="weeklist-slider" ref={slider => (this.slider = slider)} {...settings}>
                                        {
                                            _.map(AllWeek, (week, idx) => {
                                                return (
                                                    <div className={`weeklist-slider-item ${week.week == SelectedWeek.week ? 'selected' : ''}`} key={idx} onClick={() => this.onGameWeekChange(week)}>{WSManager.getWeekLabel( WSManager.getMultiLegData().week_label[week.week])} </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </Col>
                                <div className={`roster-filter-loader fade ${FieldLoader ? 'in' : null}`}><RosterLoader /></div>
                                {/* FIELDVIEW : START */}
                                <Tab.Container id="LineupFieldView" defaultActiveKey="PitchView">
                                    <Row className={`clearfix ${user_join_count == 1 ? 'm-t-sm' : ''}`}>
                                        <Col sm={12}>
                                            <Nav className="fieldview-tabnav">
                                                <NavItem className='tabnav' eventKey="PitchView">{context.state.locale.MyTeam.pitch}</NavItem>
                                                <NavItem className='tabnav' eventKey="ListView">{context.state.locale.MyTeam.list}</NavItem>
                                            </Nav>
                                        </Col>



                                        <Col sm={12} className='m-t-md'>
                                            <Tab.Content animation>
                                                <Tab.Pane eventKey="PitchView">
                                                    {
                                                        (_.isEmpty(LineupsList) || PitchViewLoaderInit) &&
                                                        <PitchViewLoader />
                                                    }
                                                    {
                                                        !_.isEmpty(LineupsList) && !PitchViewLoaderInit &&
                                                        <PitchViewField {...FieldViewProps} />
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="ListView">
                                                    {
                                                        !_.isEmpty(LineupsList) &&
                                                        <ListViewField {...FieldViewProps} />
                                                    }
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                        
                                    </Row>
                                </Tab.Container>
                                {/* FIELDVIEW : END */}
                            </Col>
                            <Col lg={4}>
                                {
                                    this.state.defaultRosterWidth <= this.state.innerWidth ? RosterView : ''
                                }
                            </Col>
                        </Row>
                        <Row>
                        <Col lg={8} md={12}> {
                             this.state.lowerBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }}>
                             <SimpleImageSlider
                                 showArrows={false}
                                 showDots={false}
                                 data={this.state.lowerBanner}
                                 duration={durationForBanner}
                             />
                         </div>
                        }</Col>
                        <Col lg={4} className='hide-sm'>
                            {this.state.squareBanner && <div className='sponsor-logo-bg sq-banner' onClick={(e) => { this.getBannerClick('1') }}>
                             <SimpleImageSlider
                                 showArrows={false}
                                 showDots={false}
                                 data={this.state.squareBanner}
                                 duration={durationForBanner}
                             />
                         </div>}
                        </Col>
                       </Row>

                        {
                            PlayerCardModalShow &&
                            <PlayerCard {...PlayerCardProps} locale={context.state.locale} language={context.state.language} />
                        }
                        {
                            SubstituteModalshow &&
                            <SubstituteModal {...SubstituteModalProps} />
                        }
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}