import React from 'react';

export default class JellyBoxLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            // <div id="loader">
            //     <div id="shadow"></div>
            //     <div id="box"></div>
            // </div>
            <div className="dank-ass-loader">
                <div className="row">
                    <div className="arrow-alt up outer outer-18"></div>
                    <div className="arrow-alt down outer outer-17"></div>
                    <div className="arrow-alt up outer outer-16"></div>
                    <div className="arrow-alt down outer outer-15"></div>
                    <div className="arrow-alt up outer outer-14"></div>
                </div>
                <div className="row">
                    <div className="arrow-alt up outer outer-1"></div>
                    <div className="arrow-alt down outer outer-2"></div>
                    <div className="arrow-alt up inner inner-6"></div>
                    <div className="arrow-alt down inner inner-5"></div>
                    <div className="arrow-alt up inner inner-4"></div>
                    <div className="arrow-alt down outer outer-13"></div>
                    <div className="arrow-alt up outer outer-12"></div>
                </div>
                <div className="row">
                    <div className="arrow-alt down outer outer-3"></div>
                    <div className="arrow-alt up outer outer-4"></div>
                    <div className="arrow-alt down inner inner-1"></div>
                    <div className="arrow-alt up inner inner-2"></div>
                    <div className="arrow-alt down inner inner-3"></div>
                    <div className="arrow-alt up outer outer-11"></div>
                    <div className="arrow-alt down outer outer-10"></div>
                </div>
                <div className="row">
                    <div className="arrow-alt down outer outer-5"></div>
                    <div className="arrow-alt up outer outer-6"></div>
                    <div className="arrow-alt down outer outer-7"></div>
                    <div className="arrow-alt up outer outer-8"></div>
                    <div className="arrow-alt down outer outer-9"></div>
                </div>
            </div>
        )
    }
}