/* eslint-disable no-unused-vars, eqeqeq */
// Imports
import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';

// Import Context
import { MyContext } from './context';
import Notification from 'react-notify-toast';

import WSManager from "./helper/WSManager";

import Header from "./components/header";
import Footer from "./components/footer";
import HowToPlay from "./views/HowToPlay";
import FAQ from "./views/FAQ";
import TnC from "./views/TnC";
import AboutUs from "./views/AboutUs";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Scoring from "./views/Scoring";
import Prizes from "./views/Prizes";
import Support from "./views/Support";

import ResetPassword from "./views/ResetPassword";
import MyTeam from "./views/MyTeam";
import Transfer from "./views/Transfer";
import Points from "./views/Points";
import MyProfile from "./views/MyProfile";
import Leagues from './views/leagues';
import Statistics from './views/Statistics';
import CreateLeague from './views/CreateLeague';
import Dashboard from './views/Dashboard';
import LeagueDetails from './views/league-details';
import PlayerStats from './views/PlayerStats';
import Fixtures from './views/Fixtures';
import Notifications from './views/Notification'
import LandingPage from "./views/LandingPage";
import AppInfo from "./views/appinfo";
import GroupChat from "./views/GroupChat";
import MultiLeg from "./views/MultiLeg";

// import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import PlayTrivia from './views/PlayTrivia';
import TriviaLeaderboard from './views/TriviaLeaderboard';

// const tagManagerArgs = {
//     gtmId: 'GTM-W46V3QD',
//     // events: {'gtm.start': new Date().getTime(), event: 'gtm.js'},
//     // dataLayerName: 'dataLayer'
// }
// TagManager.initialize(tagManagerArgs)

ReactGA.initialize('UA-146072563-1');
ReactGA.pageview(window.location.pathname + window.location.search)

let createHistory = require("history").createBrowserHistory

const history = createHistory();
const location = history.location;

const queryString = require('query-string');
const parsed = queryString.parse(location.search);

if (process.env.REACT_APP_GTM_ID != '') {
    const TagManager = require('react-gtm-module');
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID
    }
    TagManager.initialize(tagManagerArgs)
}


class Main extends Component {
    constructor(params) {
        super(params);
        this.state = {
            loggedInStatus: true,
        };
        //this.HeaderRef = React.createRef();
    }
    componentWillMount() {
        this.setState({
            loggedInStatus: WSManager.loggedIn()
        })
    }

    render() {

        
        const PrivateRoute = ({ component: Component, context: Context, ...rest }) => (
            <Route {...rest} render={(props) => (                
                this.state.loggedInStatus === true
                    ? <Component {...props} locale={Context.locale} language={Context.state.language} />
                    : <Redirect to={{
                        pathname: '/',
                        state: { from: props.location }
                    }} />
            )} />
        )
        // (this.state.loggedInStatus === true && Context.state.week_info.active_week > Number(Context.state.joined_week))
        const PointsRoute = ({ component: Component, context: Context, ...rest }) => (
            <Route {...rest} render={(props) => (                
                (this.state.loggedInStatus === true)
                    ? <Component {...props} locale={Context.locale} language={Context.state.language} />
                    : <Redirect to={{
                        pathname: '/',
                        state: { from: props.location }
                    }} />
            )} />
        )
        const NormalRoute = ({ component: Component, context: Context, ...rest }) => (
            <Route {...rest} render={(props) => (                
                <Component {...props} locale={Context.locale} language={Context.state.language} />
            )} />
        )

        return (
            <MyContext.Consumer>
                {(context) => (
                    <React.Fragment>
                        <Router>
                            <main className='Site' id='Site'>
                                <div className="Site-content">
                                    <ScrollMemory />
                                    <Header context={context} />
                                    <Notification options={{ zIndex: 1060 }} />
                                    <Switch>
                                        <NormalRoute context={context} path="/" component={LandingPage} exact/>
                                        <NormalRoute context={context} path="/appinfo" component={AppInfo} exact/>

                                        <NormalRoute context={context} path="/home" component={Dashboard} exact/>
                                        <NormalRoute context={context} path="/reset-password/:key" component={ResetPassword} />
                                        <NormalRoute context={context} path="/fixtures" component={Fixtures} />
                                        <NormalRoute context={context} path="/statistics" component={Statistics} exact />

                                        <NormalRoute context={context} path="/player-stats" component={PlayerStats} exact />

                                        <NormalRoute context={context} path="/home/invite/:join_code" component={Dashboard} />
                                        
                                        <NormalRoute context={context} path="/prizes" component={Prizes} />
                                        <NormalRoute context={context} path="/support" component={Support} />
                                        <NormalRoute context={context} path="/how-to-play" component={HowToPlay} />
                                        <NormalRoute context={context} path="/faq" component={FAQ} />
                                        <NormalRoute context={context} path="/about-us" component={AboutUs} />
                                        <NormalRoute context={context} path="/terms" component={TnC} />
                                        <NormalRoute context={context} path="/privacy-policy" component={PrivacyPolicy} />
                                        <NormalRoute context={context} path="/scoring" component={Scoring} />

                                        <PrivateRoute context={context} path="/my-team" component={MyTeam} exact />
                                        <PrivateRoute context={context} path="/transfers" component={Transfer} exact />
                                        <PrivateRoute context={context} path="/profile" component={MyProfile} exact />
                                        <PrivateRoute context={context} path="/leagues/details" component={LeagueDetails} exact />
                                        <PrivateRoute context={context} path="/leagues/create-league" component={CreateLeague} exact />
                                        <PrivateRoute context={context} path="/leagues" component={Leagues} exact />
                                        <PrivateRoute context={context} path="/notification" component={Notifications} exact />
                                        <PointsRoute context={context} path="/points" component={Points} exact />
                                        <PointsRoute context={context} path="/leagues/details/group-chat" component={GroupChat} exact />
                                        <PrivateRoute context={context} path="/select-league" component={MultiLeg} exact />
                                        <PrivateRoute context={context} path="/play-trivia" component={PlayTrivia} exact />
                                        <PrivateRoute context={context} path="/trivia/leaderboard" component={TriviaLeaderboard} exact />
                                        {/* <LocaleViewCall path="/privacy" component={Privacy} componentAe={PrivacyAe} context={context}/> */}
                                        {/* <Redirect from='*' to='/' /> */}
                                    </Switch>
                                </div>
                                <Footer />
                            </main> 
                        </Router>
                    </React.Fragment>
                )}
            </MyContext.Consumer>

        )

    }
}
export default Main;