/* eslint-disable eqeqeq, jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid, no-sequences */
import React from 'react';
import {
    Col,
    Row,
} from 'react-bootstrap';
import Img from '../components/images'
import WSManager from "../helper/WSManager";
import _ from "lodash";
import Message from "./Message";
import firebase from "firebase";
import { MyContext } from '../context';
import { Helmet } from "react-helmet";
import moment from "moment";
import Moment from "react-moment";
var today = moment();
var yesterday = moment().subtract(1, 'day');

export default class GroupChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
message: "",
        }
      
    }

    componentWillMount = (e)=>{
        try {
            this.messageRef = firebase
                .database()
                .ref()
                .child(this.props.location.data.contest_info.contest_unique_id)
                .child("messages");
            this.listenMessages();
        } catch (e) {
            this.props.history.goBack();
        }
    }

    listenMessages() {
        this.messageRef.limitToLast(500).on("value", message => {
          if (message.val() != null) {
            let msgList = Object.values(message.val());
            var groupedMsgList = _.map(_.groupBy(msgList, "date"), mlist =>
              mlist.map(msgList => _.omit(msgList, "date"))
            );
            this.setState({
              chatCount: msgList.length,
              list: groupedMsgList
            });
            setTimeout(() => {
              //  this.messagesEnd.scrollTop = this.messagesEnd.scrollHeight;
            }, 10);
          }
        });
      }
      handleChange(event) {
        this.setState({ message: event.target.value });
      }
      handleSend() {
        if (this.state.message.trim().length > 0) {
          var newItem = {
            userName: WSManager.getProfile().user_name,
            userId: WSManager.getProfile().user_id,
            userImage: WSManager.getProfile().image,
            messageDate: moment(new Date(), "dd-MM-yyyy hh:mm a").toString(),
            date: new Date().toDateString(),
            message: this.state.message
          };
          this.messageRef.push(newItem);
          this.setState({ message: "" });
        }
      }
      handleKeyPress(event) {
        if (event.key !== "Enter") return;
        this.handleSend();
      }
    // API Fatch
    // getUserLeaderboardList = () => {
    //     this.setState({ isLoaderShow: true })
    //     let param = {
    //         "sports_id": NC.sportsId,
    //         "league_id": NC.leagueId,
    //     }
    //     const { state }  = this.props.location;

    //     const ExecuteLeaderboardList = function(responseJson, _this) {
    //         _.map(responseJson.global_league, (item, i) => {
    //             item.league_type = 'global_league';
    //             item.module = i;
    //             return item
    //         })
    //         _.map(responseJson.other_league, (item, i) => {
    //             item.league_type = 'other_league';
    //             return item.module = i
    //         })
    //         _this.setState({
    //             GlobalLeagueList: responseJson.global_league,
    //             OtherLeagueList: responseJson.other_league,
    //             SelectedLeaderboard: !_.isUndefined(_this.props.location.state) ? _this.props.location.state : responseJson.global_league['overall_standing']
    //         }, () => {
    //             _this.isPrivateLeague()
    //             _this.getLeaderboardMasterData(_this.state.SelectedLeaderboard);
    //         })
    //     }

    //     if(_.isUndefined(state)) {
    //         WSManager.Rest(API.GET_USER_LEADERBOARD_LIST, param).then((responseJson) => {
    //             this.setState({ isLoaderShow: false })
    //             if (responseJson.response_code === NC.successCode) {

    //                 ExecuteLeaderboardList(responseJson.data, this)

    //             }
    //         })
    //     } else {
    //         ExecuteLeaderboardList(state.LeaderboardList, this)
    //     }

    // }

    // getLeaderboardMasterData = (data) => {
    //     this.setState({ isLoaderShow: true });
    //     let param = {
    //         "sports_id": NC.sportsId,
    //         "league_id": NC.leagueId,
    //         "contest_id": data.contest_id,
    //     }
    //     WSManager.Rest(API.CONTEST_LEADERBOARD_MASTER_DATA, param).then((responseJson) => {
    //         this.setState({ isLoaderShow: false });
    //         if (responseJson.response_code === NC.successCode) {
    //             this.setState({
    //                 LeaderboardMasterData: responseJson.data.master_data,
    //                 is_owner: responseJson.data.master_data.is_owner,
    //                 SelectedScoringMonths: responseJson.data.master_data.scoring_months[0]
    //             })
    //             this.getUserLeaderboardDetails(data, responseJson.data.master_data.scoring_months[0], NC.CURRENT_OFFSET);
    //         }
    //     })
    // }

    // getUserLeaderboard = (Arr) => {
    //     return _.filter(Arr, (item, i) => {
    //         item.isPrivate = true;
    //         return item.lineup_master_id == this.state.LeaderboardMasterData.lineup_master_id
    //     })[0]
    // }

    render() {

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="container main-container">
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.leaguesdetail_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.leaguesdetail_description} />
                        </Helmet>

                        <Row>
                            <Col sm={12}>

                                <a className="back-btn-league" onClick={() => this.props.history.push({ pathname: '/leagues' })}>
                                    <i className="icon-previousArrow"></i>
                                    <span>{context.state.locale.LeagueDetails.back}</span>
                                </a>

                             
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <div className="chat-container">
                                    <div className="group-chat col-xs-12 mL10">
                                        {/* col-xs-12 col-lg-9 */}
                                        <div className="header-chat">
                                            <img
                                                src={Img.groupchat}
                                                alt=""
                                                style={{ width: 22, marginRight: 10 }}
                                            />
                                            Group CHAT{" "}
                                            {this.state.chatCount > 0
                                                ? "(" + this.state.chatCount + ")"
                                                : ""}
                                        </div>
                                        <div
                                            ref={el => {
                                                this.messagesEnd = el;
                                            }}
                                            className="message-list"
                                        >
                                            {_.map(this.state.list,(obj, index) => (
                                                <div>
                                                    <div className="date-header">
                                                        {moment(obj[0].messageDate).isSame(today, 'day') ? 'Today' : moment(obj[0].messageDate).isSame(yesterday, 'day') ? 'Yesterday' : <Moment date={obj[0].messageDate} format="MMM DD, YYYY" />}
                                                    </div>
                                                    {/* _.map(pendingList, (item, idx) */}
                                                    {_.map(obj,(item, index) => (
                                                        <Message key={index} message={item} />
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='disply-f'>
                                             <div className="typing-box">
                                            <input
                                                className="form__input"
                                                type="text"
                                                placeholder="Enter your message"
                                                value={this.state.message}
                                                onChange={this.handleChange.bind(this)}
                                                onKeyPress={this.handleKeyPress.bind(this)}
                                            />
                                        </div>
                                        <div className="form_button">
                                            <img
                                                src={Img.CHATSHAP}
                                                alt=""
                                                //className="form_button"
                                                onClick={this.handleSend.bind(this)}
                                            />
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </Col>
                        </Row>


                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}