/* eslint-disable eqeqeq */
import React from 'react';
import { Col, Button, Modal, FormGroup } from 'react-bootstrap';
import FloatingLabel from 'floating-label-react';
import { notify } from 'react-notify-toast';

import { inputStyle } from '../helper/input-style';

import Validation from './../helper/validation';

import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";

import { MyContext } from '../context';
const md5 = require('md5');
export default class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            old_password : '',
            password: '',
            retypePassword: '',
            formValid: false,
            posting: false,
        }
    }

    getValidationState(type, value) {
        if (type == 'equals' && value.length > 0) {
            return Validation.validate('password', value) && value == this.state.password ? 'success' : 'error';
        } else {
            return Validation.validate(type, value);
        }
    }
    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid:
                Validation.validate('password', this.state.old_password) == 'success'
                && Validation.validate('password', this.state.password) == 'success'
                && this.getValidationState('equals', this.state.retypePassword) == 'success'
         });
        
    }

    handleClubChange = (event) => {
        this.setState({
            team_league_id: event.target.value
        }, this.validateForm);
    } 
    
    handleSelectChange = (el, data) => {
        this.setState({ [el]: data }, this.validateForm);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ posting: true });
        let param = {
            "old_password": md5(this.state.old_password),
            "password": md5(this.state.password)
        }
        WSManager.Rest(API.CHANGE_PASSWORD, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({ posting: false });
                notify.show(responseJson.message, "success", 5000);
                this.props.modalHide('ChangePasswordModalShow');
            } else {
                this.setState({ posting: false });
            }
        })
    }
    // Lifecycle
    // ------------------------------
    componentWillMount() {
        
    }

    render() {
        const {
            password,
            retypePassword,
            old_password,
            formValid,
            posting
        } = this.state;


        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.ChangePasswordModalShow}
                        onHide={() => this.props.modalHide('ChangePasswordModalShow')}
                        dialogClassName="custom-modal"
                    >
                        <form onSubmit={this.onSubmit}>
                            <Modal.Body>
                                <div className="thank-content">
                                    <div className="modal-header no-padding border-0">
                                        <button type='button' className="close" onClick={() => this.props.modalHide('ChangePasswordModalShow')}><span aria-hidden="true">×</span></button>
                                    </div>
                                    <div className="title m-t">{context.state.locale.change_password.hero_title}</div>
                                    <Col xs={11} className="auto-margin m-t-20">
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('password', old_password)}>
                                            <FloatingLabel
                                                styles={inputStyle}
                                                id='old_password'
                                                name='old_password'
                                                placeholder={context.state.locale.change_password.old_password_label}
                                                type='password'
                                                value={old_password}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={11} className="auto-margin">
                                        <FormGroup
                                            className='input-label-center no-margin-b'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('password', password)}>
                                            <FloatingLabel
                                                styles={inputStyle}
                                                id='password'
                                                name='password'
                                                placeholder={context.state.locale.change_password.new_password_label}
                                                type='password'
                                                value={password}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <div className='clearfix'>
                                            <small className="text-muted pull-right m-t-xs" >{context.state.locale.Signup.min_pwd_characters}</small>
                                        </div>
                                    </Col>
                                    <Col xs={11} className="auto-margin">
                                        <FormGroup
                                            className='input-label-center no-margin-b'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('equals', retypePassword)}>
                                            <FloatingLabel
                                                styles={inputStyle}
                                                id='retypePassword'
                                                name='retypePassword'
                                                placeholder={context.state.locale.change_password.retype_password_label}
                                                type='password'
                                                value={retypePassword}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <div className='clearfix'>
                                            <small className="text-muted pull-right m-t-xs" >{context.state.locale.Signup.min_pwd_characters}</small>
                                        </div>
                                    </Col>
                                </div>
                            </Modal.Body>
                            <ul className='player-card-footer'>
                                <li><Button bsStyle="default" disabled={!formValid || posting} type='submit'>{context.state.locale.change_password.submit_label}</Button></li>
                            </ul>
                        </form>
                    </Modal>
                )}
            </MyContext.Consumer>
        )
    }
}