/* eslint-disable jsx-a11y/anchor-is-valid, react/no-direct-mutation-state, eqeqeq, no-unused-vars */

import React from 'react';
import { Button, FormGroup, Row, Col, Image } from 'react-bootstrap';
import FloatingLabel from 'floating-label-react';
import WSManager from "../helper/WSManager";
import {API, Auth} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";

import ChangePassword from '../Modals/ChangePassword';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import Moment from 'react-moment';
import Select from 'react-select';
import Validation from './../helper/validation';
import { notify } from 'react-notify-toast';
import { inputStyle } from '../helper/input-style';

import { MyContext } from '../context';
import { Helmet } from "react-helmet";

var globalThis = null;
var userProfile = '';
const Genders = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
];
const GendersAe = [
    { value: 'male', label: 'الذكر' },
    { value: 'female', label: 'انثى' }
];
let momentLib;
export default class MyProfile extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.onDrop = this.onDrop.bind(this);
        this.state = {
            ChangePasswordModalShow: false,
            displayForm: false,
            email: '',
            isLoading: false,
            file: '',
            selectedImage: '',
            mProfile: '',
            isReadOnly: true,
            dob: '',
            selectedGender: '',
            gender: '',
            fName: '',
            lName: '',
            user_name: '',
            imageURL: '',
            phone_no: '',
            address: '',
            city: '',
            zip: '',
            selectedCountry: '',
            selectedCountryCode: '',
            selectedState: '',
            allCountryCode: [],
            allCountry: [],
            allState: [],
            clubList: [],
            selectedClub: '',
            formErrors: { firstname: '', lastname: '', mobile: '', address: '', city: '', zip: '' },
            isProfileComplete: null,
            ClubTeamList:[],
            ContextState: null
        };
        this.handleChange = this.handleChange.bind(this);
        console.log(props)
    }
    componentDidMount() {
        if(this.props.language == 'ar') momentLib = require('moment/locale/ar');
        this.getMyProfile();
        globalThis = this;
    }

    handleChange(date) {
        this.setState({
            dob: date
        }, this.validateField('dob', date));
    }

    onDrop(e) {
        e.preventDefault();
        let reader = new FileReader();
        let mfile = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: mfile,
                selectedImage: reader.result
            });
        }
        reader.readAsDataURL(mfile)
    }

    isDate18orMoreYearsOld = () => {
        let date = new Date();
        date.setYear(date.getFullYear() - 18);
        return date;
    }
    dobSet = (dob) => {
        if (dob == '') {
            this.setState({ dob: this.isDate18orMoreYearsOld() })
        }
    }
    enableEdit() {
        this.setState({ isReadOnly: false }, this.validateForm())
    }

    update() {
        this.uploadImageAndUPdateProfile();
    }

    handleGenderChange = (selectedOption) => {
        this.setState({ selectedGender: selectedOption }, this.validateField('gender', selectedOption))
    }

    handleCountryChange = (selectedOption) => {
        this.setState({ selectedCountry: selectedOption }, this.validateField('country', selectedOption));
        this.setState({ selectedState: '' })
        this.getAllState(selectedOption.value)
        this.setState({ selectedCountryCode: { value: selectedOption.value, label: selectedOption.phonecode, country_name: selectedOption.label } }, this.validateField('code', selectedOption));
    }

    handleCountryPhoneCodeChange = (selectedOption) => {
        this.setState({ selectedCountryCode: selectedOption }, this.validateField('code', selectedOption));
        this.setState({ selectedCountry: { value: selectedOption.value, label: selectedOption.country_name, phonecode: selectedOption.label } }, this.validateField('country', selectedOption));
        this.setState({ selectedState: '' })
        this.getAllState(selectedOption.value)

    }

    handleStateChange = (selectedOption) => {
        this.setState({ selectedState: selectedOption }, this.validateField('state', selectedOption));
    }
    handleClubChange = (selectedOption) => {
        this.setState({ selectedClub: selectedOption });
    }

    onFNameChange = (e) => {
        this.setState({ fName: e.target.value }, this.validateField(e.target.name, e.target.value))
    }

    onLNameChange = (e) => {
        this.setState({ lName: e.target.value }, this.validateField(e.target.name, e.target.value))
    }

    onMobileChange = (e) => {
        this.setState({ phone_no: e.target.value }, this.validateField(e.target.name, e.target.value));
    }

    onAddressChange = (e) => {
        this.setState({ address: e.target.value }, this.validateField(e.target.name, e.target.value))
    }

    onCityChange = (e) => {
        this.setState({ city: e.target.value }, this.validateField(e.target.name, e.target.value))
    }

    onZipChange = (e) => {
        this.setState({ zip: e.target.value }, this.validateField(e.target.name, e.target.value))
    }

    getAllCountry() {
        this.setState({ isLoading: true });
        let param = {}

        let s3_param = [
            API.S3_GET_COUNTRY_LIST
        ]
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_ALL_COUNTRY, param).then((responseJson) => {
            this.setState({ isLoading: false });
            if (responseJson != null && responseJson != '' && responseJson.response_code === NC.successCode) {
                const countries = [];
                const allCountryCode = [];
                responseJson.data.country_list.map((data, key) => {
                    countries.push({ value: data.master_country_id, label: data.country_name, phonecode: "+" + data.phonecode })
                    allCountryCode.push({ value: data.master_country_id, label: "+" + data.phonecode, country_name: data.country_name })
                    return '';
                })
                this.setState({ allState: [] })
                this.state.allCountry = countries;
                this.setState({ allCountry: countries })
                this.state.allCountryCode = allCountryCode;
                this.setState({ allCountryCode: allCountryCode })
                if (this.state.selectedCountry != '') {
                    for (let k = 0; k < this.state.allCountry.length; k++) {
                        if (this.state.allCountry[k].value == this.state.selectedCountry) {
                            this.setState({ selectedCountry: this.state.allCountry[k], selectedCountryCode: this.state.allCountryCode[k] })
                            this.getAllState(this.state.allCountry[k].value)
                            break;
                        }
                    }
                }
            }
            else {
                var keys = Object.keys(responseJson.error);
                if (keys.length > 0) {
                    var k1 = keys;
                    notify.show(responseJson.error.keys, "error", 5000);
                }
                this.setState({ allState: [] })
            }
            this.setState({ displayForm: true })
        })
    }

    getAllState(masterCountryId) {
        this.setState({ isLoading: true });
        let param = {
            "master_country_id": masterCountryId
        }

        let s3_param = [
            API.S3_GET_ALL_STATE,
            masterCountryId
        ]

        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_ALL_STATE, param).then((responseJson) => {
            this.setState({ isLoading: false });
            if (responseJson != null && responseJson != '' && responseJson.response_code === NC.successCode) {
                const states = [];
                responseJson.data.state_list.map((data, key) => {
                    states.push({ value: data.master_state_id, label: data.state_name })
                    return '';
                })
                this.setState({ allState: [] })
                this.setState({ allState: states })

                if (this.state.selectedState != '') {
                    for (let k = 0; k < this.state.allState.length; k++) {
                        if (this.state.allState[k].value == this.state.selectedState) {
                            this.setState({ selectedState: this.state.allState[k] })
                            break;
                        }
                    }
                }
            }
            else {
                var keys = Object.keys(responseJson.error);
                if (keys.length > 0) {
                    var k1 = keys;
                    notify.show(responseJson.error.keys, "error", 5000);
                }
                this.setState({ allState: [] })
            }
        })

    }
    getAllTeam = (type) => {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
        }
        let s3_param = [
            API.S3_GET_ALL_TEAM,
            // NC.sportsId,
            // NC.leagueId
            WSManager.getMultiLegData().sports_id,
            WSManager.getMultiLegData().league_id,
        ]
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_ALL_TEAM, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                responseJson.data.result.map((data, key) => {
                    data.label = data.team_name;
                    data.value = data.team_id;
                    return data;
                })
                const team_list_club = _.clone(responseJson.data.result);
                this.setState({
                    ClubTeamList: team_list_club
                });  
                
                if (this.state.selectedClub != '') {
                    for (let k = 0; k < responseJson.data.result.length; k++) {
                        if (responseJson.data.result[k].value == this.state.selectedClub) {
                            this.setState({ selectedClub: responseJson.data.result[k] })
                            break;
                        }
                    }
                }
            }
        })
    }

    getMyProfile() {
        this.setState({ isLoading: true });
        const { locale, language } = this.props;

        let param = {
            "session_key": Auth.getAuth()
        }

        WSManager.Rest(API.GET_MY_PROFILE, param).then((responseJson) => {
            this.getAllCountry();
            this.getAllTeam()
            this.setState({ isLoading: false });
            if (null != responseJson && responseJson != undefined && null != responseJson.data) {
                userProfile = responseJson.data.user_profile;

                if (userProfile.first_name == null) {
                    this.setState({ fName: '' })
                }
                else {
                    this.setState({ fName: userProfile.first_name })
                }

                if (userProfile.last_name == null) {
                    this.setState({ lName: '' })
                }
                else {
                    this.setState({ lName: userProfile.last_name })
                }

                if (userProfile.user_name == null) {
                    this.setState({ user_name: '' })
                }
                else {
                    this.setState({ user_name: userProfile.user_name })
                }

                if (userProfile.image == null) {
                    this.setState({ imageURL: '' })
                }
                else {
                    this.setState({ imageURL: userProfile.image })
                }

                if (userProfile.email == null) {
                    this.setState({ email: '' })
                }
                else {
                    this.setState({ email: userProfile.email })
                }

                
                
                if (userProfile.gender == 'male') {                    
                    this.setState({
                        selectedGender: language == 'ar' ? GendersAe[0] : Genders[0],
                        gender: userProfile.gender
                    })
                }
                else if (userProfile.gender == 'female') {
                    this.setState({
                        selectedGender: language == 'ar' ? GendersAe[1] : Genders[1],
                        gender: userProfile.gender
                    })
                }
                else {
                    this.setState({ selectedGender: null })
                }
                this.setState({ selectedImage: userProfile.image })

                if (userProfile.phone_no == null) {
                    this.setState({ phone_no: '' })
                }
                else {
                    this.setState({ phone_no: userProfile.phone_no })
                }

                if (userProfile.address == null) {
                    this.setState({ address: '' })
                }
                else {
                    this.setState({ address: userProfile.address })
                }

                if (userProfile.master_country_id == null) {
                    this.setState({ selectedCountry: '' })
                }
                else {
                    this.setState({ selectedCountry: userProfile.master_country_id })
                }

                if (userProfile.master_state_id == null) {
                    this.setState({ selectedState: '' })
                }
                else {
                    this.setState({ selectedState: userProfile.master_state_id })
                }

                if (userProfile.city == null) {
                    this.setState({ city: '' })
                }
                else {
                    this.setState({ city: userProfile.city })
                }

                if (userProfile.zip_code == null) {
                    this.setState({ zip: '' })
                }
                else {
                    this.setState({ zip: userProfile.zip_code })
                }

                if (userProfile.team_id == null) {
                    this.setState({ selectedClub: '' })
                }
                else {
                    this.setState({ selectedClub: userProfile.team_id })
                }

                var formattedDate = _.isNull(userProfile.dob) ? '' : new Date(userProfile.dob);
                this.setState({ dob: formattedDate })

                if (userProfile.first_name != null && userProfile.first_name != '') {
                    this.validateField('firstname', userProfile.first_name)
                }
                if (userProfile.last_name != null && userProfile.last_name != '') {
                    this.validateField('lastname', userProfile.last_name)
                }
                if (userProfile.phone_no != null && userProfile.phone_no != '') {
                    this.validateField('mobile', userProfile.phone_no)
                }
                if (userProfile.city != null && userProfile.city != '') {
                    this.validateField('city', userProfile.city)
                }
                if (userProfile.zip_code != null && userProfile.zip_code != '') {
                    this.validateField('zip', userProfile.zip_code)
                }
            }
        })

    }

    uploadImageAndUPdateProfile() {
        if (this.state.file != '') {
            this.uploadImage();
        }
        else {
            this.updateMyProfile(this.state.selectedImage);
        }
    }

    updateMyProfile(path) {
        this.setState({ isLoading: true });
        var options = { year: 'numeric', month: 'short', day: '2-digit' };
        let param = {
            'first_name': this.state.fName,
            'last_name': this.state.lName,
            'email': this.state.email,
            'dob': this.state.dob !='' ? this.state.dob.toLocaleDateString("en-US", options) : '',
            'image': path,
            'gender': this.state.selectedGender ? this.state.selectedGender.value : '',
            'phone_no': this.state.phone_no,
            'address': this.state.address,
            'master_country_id': (this.state.selectedCountry == '' || this.state.selectedCountry == null) ? '' : this.state.selectedCountry.value,
            'master_state_id': (this.state.selectedState == '' || this.state.selectedState == null) ? '' : this.state.selectedState.value,
            'city': this.state.city,
            'zip_code': this.state.zip,
            'home_club': this.state.selectedClub.value,
            'team_id' : this.state.selectedClub.value
        }
        WSManager.Rest(API.UPDATE_PROFILE, param).then((responseJson) => {
            if (responseJson != null && responseJson != '' && responseJson.response_code === NC.successCode) {
                this.setState({ isLoading: false });
                this.setState({ mProfile: responseJson.data.user_profile });
                this.setState({ isReadOnly: true })
                WSManager.setProfile(responseJson.data.user_profile);
                window.location.reload();
            }
            else {
                var keys = Object.keys(responseJson.error);
                console.log("keys" , responseJson);
                if (keys.length > 0) {
                    var k1 = keys;
                    notify.show(responseJson.error.keys, "error", 5000);
                }
                this.setState({ allState: [] })
            }
        })
    }

    uploadImage() {

        this.setState({ isLoading: true });
        var data = new FormData();
        data.append("userfile", this.state.file);

        var xhr = new XMLHttpRequest();
        //xhr.withCredentials = true;
        xhr.withCredentials = false;
        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                var response = JSON.parse(this.responseText);

                globalThis.setState({ isLoading: false });
                if (response != '' && response.response_code === NC.successCode) {
                    var imagePath = response.data.image_path;
                    globalThis.setState({ imageURL: imagePath })
                    globalThis.updateMyProfile(imagePath);
                }
                else {
                    var keys = Object.keys(response.error);
                    if (keys.length > 0) {
                        var k1 = keys;
                        notify.show(response.error.keys, "error", 5000);
                    }
                }

            }
        });

        xhr.open("POST", API.DO_UPLOAD);
        xhr.setRequestHeader('session_key', Auth.getAuth())
        xhr.send(data);
    }

    validateForm() {
        // this.setState({ formValid: this.state.firstNameValid && this.state.lastNameValid && this.state.isMobileValid });
        this.setState({
            formValid:
                this.state.firstNameValid
                && this.state.lastNameValid
                // && this.state.selectedGender
                // && this.state.selectedCountryCode
                // && (this.state.isMobileValid || this.state.phone_no == '')
                // && this.state.address
                && this.state.selectedCountry
                // && this.state.selectedState
                // && this.state.city
                // && this.state.zip
                // && this.state.dob
                && this.state.selectedClub
        });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;
        let isMobileValid = this.state.isMobileValid;
        switch (fieldName) {
            case 'firstname':
                firstNameValid = Validation.validate('first_name', value);
                fieldValidationErrors.firstname = firstNameValid ? '' : ' is invalid';
                break;
            case 'lastname':
                lastNameValid = Validation.validate('last_name', value);
                fieldValidationErrors.lastname = lastNameValid ? '' : ' is invalid';
                break;
            case 'mobile':
                isMobileValid = (value.length > 3 || value.length == 0) && value.match(/^[0-9]*$/g) ? true : false;
                fieldValidationErrors.mobile = isMobileValid ? '' : ' is invalid';
                break;

            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            isMobileValid: isMobileValid,
        }, this.validateForm);
    }
    modalShow = (Modal) => {
        this.setState({ [Modal]: true });

    }
    modalHide = (Modal) => {
        this.setState({ [Modal]: false });
    }
    
    render() {
        const ChangePasswordProps = {
            modalHide: this.modalHide,
            ChangePasswordModalShow: this.state.ChangePasswordModalShow
        }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="container main-container">
                        
                        {/* {
                            this.setContext(context)
                        } */}
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.profile_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.profile_description} />
                        </Helmet>
                        <Row>
                            <Col lg={9} className='auto-margin'>
                                {(this.state.displayForm) ?
                                    <div className='profile_bg_container'>
                                        <div className='profile_cover_container'>
                                            {(!this.state.isReadOnly) ?
                                                <div className='default-button centerView'>

                                                    <input id="myInput"
                                                        type="file"
                                                        accept="image/*"
                                                        ref={(ref) => this.upload = ref}
                                                        style={{ display: 'none' }}
                                                        onChange={this.onDrop.bind(this)}
                                                    />
                                                    <div className='avatar_container edit'>
                                                        <span className="edit-span icon-plus" onClick={() => { this.upload.click() }}></span>
                                                        <Image className='avatar_container' width='72px' height='72px' onClick={() => { this.upload.click() }} src={this.state.selectedImage == '' ? this.state.imageURL : this.state.selectedImage} circle />
                                                    </div>

                                                    <div className='centerView-box'>
                                                        <a className='username-container'>{this.state.fName + ' ' + this.state.lName}</a>
                                                    </div>
                                                    <a className='userNameClass'>{context.state.locale.MyProfile.username} - {this.state.user_name}</a>
                                                </div>
                                                :
                                                <div className='default-button centerView'>
                                                    <div className='avatar_container'>
                                                        <Image className='avatar_container' width='72px' height='72px' src={this.state.imageURL} circle />
                                                    </div>
                                                    <div className='centerView-box'>
                                                        <a className='username-container'>{this.state.fName + ' ' + this.state.lName}</a>
                                                    </div>
                                                    <a className='userNameClass'>{context.state.locale.MyProfile.username} - {this.state.user_name}</a>
                                                </div>
                                            }
                                            {
                                                this.state.isReadOnly &&
                                                <span onClick={() => this.enableEdit()} className='profile-edit-btn'></span>
                                            }
                                            {
                                                !_.isUndefined(this.props.location.state) && this.props.location.state.is_country_set == 0 &&
                                                <a className="profile-back-btn" onClick={() => this.props.history.push({ pathname: '/leagues' })}>
                                                    <i className="icon-previousArrow"></i>
                                                    <span className='d-none d-sm-none d-md-block d-lg-block'>{context.state.locale.MyProfile.back_leagues_text}</span>
                                                </a>
                                            }

                                            <a className='change-password-link' onClick={() => this.modalShow('ChangePasswordModalShow')}>{context.state.locale.MyProfile.change_password}</a>
                                        </div>
                                        {
                                            !this.state.isReadOnly ?
                                                <div className='form_containers'>
                                                    <div className='form-field row'>
                                                        <FormGroup controlId="formHorizontalEmail" className="profile-input-field col-sm-6">
                                                            <Col sm={12}>
                                                                <FloatingLabel id='firstname'
                                                                    name='firstname'
                                                                    placeholder={context.state.locale.MyProfile.first_name}
                                                                    type='text'
                                                                    required
                                                                    maxLength={25}
                                                                    className='form-field'
                                                                    onChange={this.onFNameChange}
                                                                    readOnly={this.state.isReadOnly}
                                                                    value={this.state.fName}
                                                                    styles={inputStyle} />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup controlId="formHorizontalEmail" className="profile-input-field col-sm-6">
                                                            <Col sm={12}>
                                                                <FloatingLabel id='lastname'
                                                                    name='lastname'
                                                                    placeholder={context.state.locale.MyProfile.last_name}
                                                                    type='text'
                                                                    maxLength={25}
                                                                    required
                                                                    readOnly={this.state.isReadOnly}
                                                                    className='form-field'
                                                                    onChange={this.onLNameChange}
                                                                    value={this.state.lName}
                                                                    styles={inputStyle} />
                                                            </Col>
                                                        </FormGroup>
                                                    </div>
                                                    <div className='form-field row'>
                                                        <FormGroup className='profile-input-field col-sm-6' controlId="formHorizontalEmail">
                                                            <Col sm={12}>
                                                                <FloatingLabel id='email'
                                                                    name='email'
                                                                    placeholder={context.state.locale.MyProfile.email}
                                                                    type='email'
                                                                    required
                                                                    autoFocus={true}
                                                                    readOnly={true}
                                                                    className='form-field'
                                                                    value={this.state.email}
                                                                    styles={inputStyle} />
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup className='profile-input-field col-sm-6' controlId="formHorizontalEmail">
                                                            <Col sm={12}>
                                                                <FloatingLabel id='username'
                                                                    name='username'
                                                                    placeholder={context.state.locale.MyProfile.username}
                                                                    type='text'
                                                                    required
                                                                    autoFocus={true}
                                                                    readOnly={true}
                                                                    className='form-field'
                                                                    value={this.state.user_name}
                                                                    styles={inputStyle} />
                                                            </Col>
                                                        </FormGroup>

                                                    </div>
                                                    <div className='form-field row'>
                                                        <FormGroup className='col-sm-6' controlId="formHorizontalEmail">
                                                            <Col sm={12}>
                                                                <div className='datepicker_display'>
                                                                    <label style={inputStyle.label}>{context.state.locale.MyProfile.dob}</label>
                                                                    <div className="dobField">
                                                                        <DatePicker
                                                                            className='date-picker-custom'
                                                                            maxDate={this.isDate18orMoreYearsOld()}
                                                                            value={this.state.dob}
                                                                            required={true}
                                                                            disabled={this.state.isReadOnly}
                                                                            minDetail='decade'
                                                                            locale={context.state.language}
                                                                            onChange={this.handleChange}
                                                                            onClick={!this.state.isReadOnly ? () => this.dobSet(this.state.dob) : null}
                                                                        />
                                                                    </div> 
                                                                </div>
                                                                <div className='dob-border col-sm-12' />

                                                            </Col>


                                                        </FormGroup>

                                                        <FormGroup className='col-sm-6' controlId="formHorizontalEmail">

                                                            <Col sm={12}>

                                                                <div className="select-gender">
                                                                    <label style={inputStyle.label}>{context.state.locale.MyProfile.gender}</label>
                                                                    <div className="genderStyle">
                                                                        <Select
                                                                            onChange={this.handleGenderChange}
                                                                            options={context.state.language == 'en' ? Genders : GendersAe}
                                                                            classNamePrefix="secondary"
                                                                            className="select-secondary minusML10"
                                                                            isDisabled={this.state.isReadOnly}
                                                                            arrowRenderer={this.arrowRenderer}
                                                                            value={this.state.selectedGender}
                                                                            placeholder="--"
                                                                            isSearchable={false} isClearable={false}
                                                                        />
                                                                    </div>
                                                                    <span className="select-arr"><i className="icon-select-arrow"></i></span>
                                                                    <div className='gender-border col-sm-12' />
                                                                </div>
                                                            </Col>
                                                        </FormGroup>



                                                    </div>
                                                    <div className='form-field row'>

                                                        <FormGroup className="col-sm-6 " controlId="formHorizontalEmail">
                                                            <Col sm={12} className="row phone-wrap">
                                                                <Col xs={3}>
                                                                    <label className='phone-wrap-label' style={inputStyle.label}>{context.state.locale.MyProfile.code}</label>
                                                                    <FloatingLabel
                                                                        id='mobileCode'
                                                                        name='mobileCode'
                                                                        type='text'
                                                                        required
                                                                        autoFocus={true}
                                                                        readOnly={true}
                                                                        className='form-field'
                                                                        value={this.state.selectedCountryCode.label}
                                                                        styles={inputStyle} />

                                                                    {/* <div className="select-phoneCode">

                                                                        <div className="phoneCodeStyle">
                                                                            <Select
                                                                                onChange={this.handleCountryPhoneCodeChange}
                                                                                options={this.state.allCountryCode}
                                                                                classNamePrefix="secondary"
                                                                                className="select-secondary minusML10"
                                                                                isDisabled={this.state.isReadOnly}
                                                                                arrowRenderer={this.arrowRenderer}
                                                                                value={this.state.selectedCountryCode}
                                                                                placeholder="--"
                                                                                isSearchable={false} isClearable={false}
                                                                            />
                                                                        </div>
                                                                        <span className="select-arr"><i className="icon-select-arrow"></i></span>
                                                                        <div className='phoneCode-border col-sm-12' />
                                                                    </div> */}
                                                                </Col>
                                                                <Col xs={9} className='profile-input-field'>
                                                                    <FloatingLabel id='mobile'
                                                                        name='mobile'
                                                                        placeholder={context.state.locale.MyProfile.mobile}
                                                                        type='text'
                                                                        maxLength={10}
                                                                        required
                                                                        readOnly={this.state.isReadOnly}
                                                                        className='form-field'
                                                                        onChange={this.onMobileChange}
                                                                        value={this.state.phone_no}
                                                                        styles={inputStyle} />
                                                                </Col>
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup className='profile-input-field col-sm-6' controlId="formHorizontalEmail">
                                                            <Col sm={12}>
                                                                <FloatingLabel id='address'
                                                                    name='address'
                                                                    placeholder={context.state.locale.MyProfile.street_address}
                                                                    type='text'
                                                                    required
                                                                    maxLength={50}
                                                                    readOnly={this.state.isReadOnly}
                                                                    className='form-field'
                                                                    onChange={this.onAddressChange}
                                                                    value={this.state.address}
                                                                    disabled={this.state.address == '' ? this.state.isReadOnly : false}
                                                                    styles={inputStyle} />
                                                            </Col>
                                                        </FormGroup>
                                                    </div>
                                                    <div className='form-field row'>

                                                        <FormGroup className='col-sm-6' controlId="formHorizontalEmail">

                                                            <Col sm={12}>

                                                                <div className="select-country">
                                                                    <label style={inputStyle.label}>{context.state.locale.MyProfile.country}</label>
                                                                    <div className="countryStyle">
                                                                        <Select
                                                                            onChange={this.handleCountryChange}
                                                                            options={this.state.allCountry}
                                                                            classNamePrefix="secondary"
                                                                            className="select-secondary minusML10"
                                                                            isDisabled={this.state.isReadOnly}
                                                                            arrowRenderer={this.arrowRenderer}
                                                                            value={this.state.selectedCountry}
                                                                            placeholder="--"
                                                                            isSearchable={false} isClearable={false}
                                                                        />
                                                                    </div>
                                                                    <span className="select-arr"><i className="icon-select-arrow"></i></span>
                                                                    <div className='country-border col-sm-12' />
                                                                </div>
                                                            </Col>
                                                        </FormGroup>


                                                        <FormGroup className='col-sm-6' controlId="formHorizontalEmail">

                                                            <Col sm={12}>

                                                                <div className="select-state">
                                                                    <label style={inputStyle.label}>{context.state.locale.MyProfile.state}</label>
                                                                    <div className="stateStyle">
                                                                        <Select
                                                                            onChange={this.handleStateChange}
                                                                            options={this.state.allState}
                                                                            classNamePrefix="secondary"
                                                                            className="select-secondary minusML10"
                                                                            isDisabled={this.state.isReadOnly}
                                                                            arrowRenderer={this.arrowRenderer}
                                                                            value={this.state.selectedState}
                                                                            name="state"
                                                                            placeholder="--"
                                                                            isSearchable={false} isClearable={false}
                                                                        />
                                                                    </div>
                                                                    <span className="select-arr"><i className="icon-select-arrow"></i></span>
                                                                    <div className='state-border col-sm-12' />
                                                                </div>
                                                            </Col>
                                                        </FormGroup>

                                                    </div>
                                                    <div className='form-field row'>

                                                        <FormGroup className="col-sm-6 profile-input-field" controlId="formHorizontalEmail">
                                                            <Col sm={12}>
                                                                <FloatingLabel id='city'
                                                                    name='city'
                                                                    placeholder={context.state.locale.MyProfile.city}
                                                                    type='text'
                                                                    required
                                                                    readOnly={this.state.isReadOnly}
                                                                    className='form-field'
                                                                    onChange={this.onCityChange}
                                                                    value={this.state.city}
                                                                    disabled={this.state.city == '' ? this.state.isReadOnly : false}
                                                                    styles={inputStyle} />
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup className="col-sm-6 profile-input-field" controlId="formHorizontalEmail">
                                                            <Col sm={12}>
                                                                <FloatingLabel id='zip'
                                                                    name='zip'
                                                                    placeholder={context.state.locale.MyProfile.zip_code}
                                                                    type='text'
                                                                    maxLength={9}
                                                                    required
                                                                    readOnly={this.state.isReadOnly}
                                                                    className='form-field'
                                                                    onChange={this.onZipChange}
                                                                    value={this.state.zip}
                                                                    disabled={this.state.zip == '' ? this.state.isReadOnly : false}
                                                                    styles={inputStyle} />
                                                            </Col>
                                                        </FormGroup>


                                                    </div>


                                                    <div className='form-field row'>
                                                        <FormGroup className='col-sm-6' controlId="formHorizontalEmail">
                                                            <Col sm={12}>
                                                                <div className="select-state">
                                                                    <label style={inputStyle.label}>{context.state.locale.CreateTeam.favourite_club}</label>
                                                                    <div className="stateStyle">
                                                                        <Select
                                                                            onChange={this.handleClubChange}
                                                                            options={this.state.ClubTeamList}
                                                                            classNamePrefix="secondary"
                                                                            className="select-secondary minusML10"
                                                                            isDisabled={this.state.isReadOnly}
                                                                            arrowRenderer={this.arrowRenderer}
                                                                            value={this.state.selectedClub}
                                                                            name="Favourite Club"
                                                                            placeholder="--"
                                                                            isSearchable={false} isClearable={false}
                                                                        />
                                                                    </div>
                                                                    <span className="select-arr"><i className="icon-select-arrow"></i></span>
                                                                    <div className='state-border col-sm-12' />
                                                                </div>
                                                            </Col>
                                                        </FormGroup>

                                                    </div>

                                                    <FormGroup className='no-margin-b'>
                                                        {(!this.state.isReadOnly) ?
                                                            <Col sm={12}>
                                                                <Button bsStyle="primary" disabled={!this.state.formValid} onClick={() => this.update()} type='submit'>{context.state.locale.MyProfile.update}</Button>
                                                            </Col>
                                                            : ''
                                                        }
                                                    </FormGroup>

                                                </div>
                                                :
                                                <div className='form_containers'>
                                                    <Row>
                                                        <Col sm={12} className='profile-display-field'>
                                                            <div className="label">{context.state.locale.MyProfile.email}</div>
                                                            <div className="value">{this.state.email}</div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} className='profile-display-field'>
                                                            <div className="label">{context.state.locale.MyProfile.dob}</div>
                                                            <div className="value">
                                                                {
                                                                    this.state.dob ?
                                                                        <Moment date={this.state.dob} format="MMM DD, YYYY" />
                                                                        : '--'
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} className='profile-display-field'>
                                                            <div className="label">{context.state.locale.MyProfile.gender}</div>
                                                            <div className="value text-capitalize">
                                                                {
                                                                    this.state.gender ?
                                                                        this.state.selectedGender.label
                                                                        : '--'
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} className='profile-display-field'>
                                                            <div className="label">{context.state.locale.MyProfile.mobile}</div>
                                                            <div className="value">{this.state.phone_no != '' ? this.state.selectedCountryCode.label + ' ' + this.state.phone_no : '--'}</div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} className='profile-display-field'>
                                                            <div className="label">{context.state.locale.MyProfile.address}</div>
                                                            <div className="value">{this.state.address || '--'}</div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                        }

                                    </div>
                                    :
                                    <div></div>
                                }
                            </Col>
                        </Row>
                        {
                            this.state.ChangePasswordModalShow &&
                            <ChangePassword {...ChangePasswordProps} />
                        }
                    </div>

                )}
            </MyContext.Consumer>
        );
    }
}