/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
import React from 'react';
import { Row, Col, Button, Modal, FormGroup } from 'react-bootstrap';
import FloatingLabel from 'floating-label-react';
import { notify } from 'react-notify-toast';

import { inputStyle } from '../helper/input-style';

import Validation from './../helper/validation';

import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";

import Slider from "react-slick";
import Select from 'react-select';

import { MyContext } from '../context';

export default class CreateTeam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            team_name : '',
            team_league_id: '',
            SelectedCaptain: [],
            SelectedViceCaptain: [],
            formValid: false,
            posting: false,
            ClubTeamList:[]
        }
    }

    getValidationState(type, value) {
        // this.state.SelectedCaptain.position == this.state.SelectedViceCaptain.position && this.state.SelectedCaptain.position == 'GK'
        if (type == 'captain') {
            let isValidate = null;
            if (!_.isEmpty(value)) {
                isValidate = value.player_uid.length > 0
                && (this.state.SelectedCaptain.player_uid != this.state.SelectedViceCaptain.player_uid)
                && !(this.state.SelectedViceCaptain.position == 'GK' && this.state.SelectedCaptain.position == 'GK')
                    ? 'success' : 'error';
            }
            return isValidate;
        } else {
            return Validation.validate(type, value)
        }

    }

        // GET_ALL_TEAM
        getAllTeam = (type) => {
            let param = {
                // "league_id": NC.leagueId,
                // "sports_id": NC.sportsId,
                "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
                "is_none_available" : '1'
            }
            let s3_param = [
                API.S3_GET_ALL_TEAM,
                // NC.sportsId,
                // NC.leagueId
                WSManager.getMultiLegData().sports_id,
                WSManager.getMultiLegData().league_id,
            ]
    
            WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_ALL_TEAM, param).then((responseJson) => {
                if (responseJson.response_code === NC.successCode) 
                {
                    let result = []
                    responseJson.data.result.map((data, key) => {
                        if (data.is_eliminate != 1)
                        {
                            data.label = data.team_name;
                            data.value = data.team_id;
                            // return data;
                            result.push(data)
                        }

                        return data
                    })

                    // const team_list_club = _.clone(responseJson.data.result);
                    const team_list_club = _.clone(result);


                    this.setState({
                        ClubTeamList: team_list_club
                    });    
                }
            })
        }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid:
            Validation.validate('team_name', this.state.team_name) == 'success'
            && this.state.team_league_id != ''
            && this.getValidationState('captain', this.state.SelectedCaptain) == 'success'
            && this.getValidationState('captain', this.state.SelectedViceCaptain) == 'success'
        });
    }

    handleClubChange = (event) => {
        this.setState({
            team_league_id: event.target.value
        }, this.validateForm);
    } 
    
    handleSelectChange = (el, data) => {
        this.setState({ [el]: data }, this.validateForm);
    }

    _randomBenchPlayer = (LineupsList) => {
        let CurrentFormation = this.props.LineupFormationList.formation[this.props.SelectedFormation]
        let lineupFltr = _.filter(LineupsList, (item, idx) => {
            return !(item.player_role != 0 && !_.isUndefined(item.player_role))
        })
        let k = 1;
        for (let i = 0; i < CurrentFormation.length; i++) {
            const element = CurrentFormation[i];
            var filterArr = lineupFltr.filter(function (item) {
                return item.position == element.position
            })
            _.map(filterArr, (item, idx) => {
                if (idx < element.number_of_bench_player) {
                    item.is_bench_player = '1';
                    item.bench_order = k;
                    k = k + 1
                }
                return item;
            })
        }
        return LineupsList
    }


    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ posting: true });
        _.map(this.props.LineupsList, (item, idx) => {
            item.player_role = '0';
            item.is_bench_player = '0';
            if (item.player_uid == this.state.SelectedCaptain.player_uid) {
                item.player_role = '1';
            }
            if (item.player_uid == this.state.SelectedViceCaptain.player_uid) {
                item.player_role = '2';
            }
            return item;
        });


        const { SelectedFormation, LineupsList } = this.props;
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "contest_id" :  this.props.contest_id,
            "selected_formation": SelectedFormation,
            "lineup": this._randomBenchPlayer(LineupsList),
            "team_league_id": this.state.team_league_id,
            "team_name": this.state.team_name,
            // "lineup_master_id": this.state.ManageTeamData.lineup_master_id ,
        }
                
        WSManager.Rest(API.LINEUP_PROCCESS, param).then((responseJson) => {

            if (responseJson.response_code === NC.successCode) {
                localStorage.setItem('lineup_master_id', responseJson.data.lineup_master_id);
                let join_game_param = {
                    "sports_id" : WSManager.getMultiLegData().sports_id,
                    "league_id" : WSManager.getMultiLegData().league_id,
                    "contest_id" :  this.props.contest_id,
                    "lineup_master_id": responseJson.data.lineup_master_id
                }
                WSManager.Rest(API.JOIN_CONTEST, join_game_param).then((responseJson) => {
                    if (responseJson.response_code === NC.successCode) {
                        // notify.show(responseJson.message, "success", 5000);
                        localStorage.setItem('user_join_count', 1);
                    } else {
                        this.setState({ posting: false });
                    }
                    this.props.modalHide('CreateTeamModalShow');
                    this.props.modalShow('ThankyouCreateTeamModalShow');
                })
            } else {
                this.setState({ posting: false });
                notify.show(NC.MSZ_AUTHENTICATE_USER, "error", 5000);
                this.props.modalHide('CreateTeamModalShow');
            }
        })
    }
    // Lifecycle
    // ------------------------------
    componentWillMount() {
       
        this.getAllTeam()
        _.map(this.props.LineupsList, (item, idx) => {
            item.label = item.full_name + ' (' + (item.position_name || item.position) +', '+ item.team_abbr_lang +')';
            item.value = item.player_uid;
            if (item.player_role == 1) {
                this.setState({ SelectedCaptain: item });
            }
            if (item.player_role == 2) {
                this.setState({ SelectedViceCaptain: item });
            }

            return item;
        })
    }

    render() {
        const {
            team_name,
            team_league_id,
            SelectedCaptain,
            SelectedViceCaptain,
            formValid,
            posting,
            ClubTeamList
        } = this.state;

        const {
            LineupsList
        } = this.props;

        var settings = {
            // dots: true,
            rtl: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: true,
            centerPadding: '0px'
        };
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.CreateTeamModalShow}
                        onHide={() => this.props.modalHide('CreateTeamModalShow')}
                        dialogClassName="custom-modal xcustom-bg"
                    >
                        <Modal.Header closeButton className='custom-modal-header'>
                            <div className="title">{context.state.locale.CreateTeam.hero_title}</div>
                        </Modal.Header>

                        <form onSubmit={this.onSubmit}>
                            <Modal.Body>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('team_name', team_name)}>
                                            <FloatingLabel
                                                styles={inputStyle}
                                                id='team_name'
                                                name='team_name'
                                                placeholder={context.state.locale.CreateTeam.team_name_placeholder}
                                                type='text'
                                                value={this.state.team_name}
                                                onChange={this.handleChange}
                                            />
                                            <i className="float-icon football-ic"></i>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <label className="clublist-label">{context.state.locale.CreateTeam.favourite_club}</label>
                                        <Slider className="clublist-slider" {...settings}>
                                            {
                                                _.map((ClubTeamList.length == 0 ? this.props.ClubTeamList : ClubTeamList), (item, idx) => {
                                                    return (
                                                        <label className={`club-slick-item ${item.team_league_id == team_league_id ? 'selected' : null}`} key={idx}>
                                                            <div className="club-image">
                                                                <img src={item.flag} alt="" />
                                                            </div>
                                                            <div className="club-name">{item.team_name}</div>
                                                            <input type="radio" name="club-name" value={item.team_league_id} onChange={this.handleClubChange} />
                                                        </label>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('captain', SelectedCaptain)}>
                                            <label className="select-field-transparent-label">{context.state.locale.CreateTeam.captain}</label>
                                            <Select
                                                className='select-field-transparent'
                                                classNamePrefix='select'
                                                placeholder={context.state.locale.CreateTeam.select_placeholder}
                                                isSearchable={false}
                                                value={SelectedCaptain}
                                                onChange={Event => this.handleSelectChange('SelectedCaptain', Event)}
                                                options={LineupsList}>
                                            </Select>
                                            <i className="float-icon captain-ic"></i>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup
                                            className='input-label-center'
                                            controlId="formBasicText"
                                            validationState={this.getValidationState('captain', SelectedViceCaptain)}>
                                            <label className="select-field-transparent-label m-t-md">{context.state.locale.CreateTeam.vice_captain}</label>
                                            <Select
                                                className='select-field-transparent'
                                                classNamePrefix='select'
                                                placeholder={context.state.locale.CreateTeam.select_placeholder}
                                                isSearchable={false}
                                                value={SelectedViceCaptain}
                                                onChange={Event => this.handleSelectChange('SelectedViceCaptain', Event)}
                                                options={LineupsList}>
                                            </Select>
                                            <i className="float-icon vice-captain-ic"></i>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                                {/* <Row>
                                    <Col xs={12}>
                                            <div className="create-team-info">
                                            {context.state.locale.CreateTeam.disclaimer_start} <br/>
                                            {context.state.locale.CreateTeam.disclaimer_end}
                                            </div>
                                    </Col>
                                </Row> */}
                            </Modal.Body>
                            <Modal.Footer className='custom-modal-footer'>
                                <Button bsStyle="primary" disabled={!formValid || posting} type='submit'>{context.state.locale.CreateTeam.submit_label}</Button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                )}
            </MyContext.Consumer>
        )
    }
}