import config from "../config";

const API = {
    signup: `${config.apiGateway.URL}/user/auth/signup`,
    login: `${config.apiGateway.URL}/user/auth/login`,
    resend_verification_code : `${config.apiGateway.URL}/user/auth/resend_email_verification_link`,
    FORGOT_PASSWORD: `${config.apiGateway.URL}/user/auth/forgot_password`,
    CHANGE_PASSWORD: `${config.apiGateway.URL}/user/my_profile/change_password`,
    RESET_PASSWORD: `${config.apiGateway.URL}/user/auth/reset_password`,
    GET_COUNTRY_LIST: `${config.apiGateway.URL}/user/auth/get_country_list`,
    S3_GET_COUNTRY_LIST: `country_list`,
    
    GET_LINEUP_MASTER_DATA: `${config.apiGateway.URL}/fantasy/sl_lineup/get_lineup_master_data`,
    GET_ALL_TEAM: `${config.apiGateway.URL}/fantasy/sl_lineup/get_all_team`,
    S3_GET_ALL_TEAM: `team_list`,
    GET_ALL_ROSTER: `${config.apiGateway.URL}/fantasy/sl_lineup/get_all_roster`,
    GET_ROSTER_BY_POSTION: `${config.apiGateway.URL}/fantasy/sl_lineup/get_roster_by_position`,
    GET_ROSTER_BY_POSTION_TRANSFER: `${config.apiGateway.URL}/fantasy/sl_lineup/roster_by_position_for_transfer`,

    LINEUP_PROCCESS: `${config.apiGateway.URL}/fantasy/sl_lineup/lineup_proccess`,
    JOIN_GAME: `${config.apiGateway.URL}/fantasy/sl_lineup/join_game`,
    APPLY_BOOSTER: `${config.apiGateway.URL}/fantasy/sl_lineup/apply_booster`,
    REMOVE_BOOSTER: `${config.apiGateway.URL}/fantasy/sl_lineup/remove_booster`,
    GET_AUTOFILL_LINEUP_DATA: `${config.apiGateway.URL}/fantasy/sl_lineup/get_autofill_lineup_data`,
    
    GET_MANAGE_TEAM_MASTER_DATA: `${config.apiGateway.URL}/fantasy/sl_contest/get_manage_team_master_data`,
    GET_USER_LINEUP: `${config.apiGateway.URL}/fantasy/sl_lineup/get_user_lineup`,
    GET_USER_LINEUP_DETAIL: `${config.apiGateway.URL}/fantasy/sl_lineup/get_user_lineup_detail`,
    GET_USER_TEAM_INFO: `${config.apiGateway.URL}/fantasy/sl_lineup/get_user_team_info`,

    GET_ROSTER_FOR_TRANSFER: `${config.apiGateway.URL}/fantasy/sl_lineup/get_roster_for_transfer`,
    PROCESS_PLAYER_TRANSFER: `${config.apiGateway.URL}/fantasy/sl_lineup/process_player_transfer`,
    MY_CURRENT_LINEUP: `${config.apiGateway.URL}/fantasy/sl_lineup/my_current_lineup`,
    
    // Home
    GET_HOME_DATA: `${config.apiGateway.URL}/fantasy/sl_lobby/get_home_data`,
    TOP_SCORING_PLAYERS: `${config.apiGateway.URL}/fantasy/sl_lobby/top_scoring_players`,
    GET_LIVE_FIXTURE: `${config.apiGateway.URL}/fantasy/sl_contest/get_live_fixture_list`,
    S3_TOP_SCORING_PLAYERS: `top_scoring_players`,
    
    //Player card
    GET_PLAYERCARD_SL: `${config.apiGateway.URL}/fantasy/common/get_playercard_sl`,
    GET_PLAYERCARD_SL_MASTER_DATA: `${config.apiGateway.URL}/fantasy/common/get_playercard_sl_master_data`,
    GET_PLAYERCARD: `${config.apiGateway.URL}/fantasy/common/get_playercard_sl`,
    
    //Create League 
    CREATE_PRIVATE_LEAGUE: `${config.apiGateway.URL}/fantasy/sl_contest/create_private_league`,
    JOIN_CONTEST: `${config.apiGateway.URL}/fantasy/sl_contest/join_game`,
    WEEK_BY_LEAGUE: `${config.apiGateway.URL}/fantasy/sl_contest/get_all_week_by_league_id`,
    
    //Leaderboard
    CONTEST_LEADERBOARD: `${config.apiGateway.URL}/fantasy/sl_leaderboard/contest_leaderboard`,
    CONTEST_LEADERBOARD_MASTER_DATA: `${config.apiGateway.URL}/fantasy/sl_leaderboard/contest_leaderboard_master_data`,
    GET_MY_COMMON_LEAGUES: `${config.apiGateway.URL}/fantasy/sl_contest/get_my_common_leagues`,
    GET_INVITE_CODE: `${config.apiGateway.URL}/fantasy/sl_contest/save_invite_for_game`,
    CHECK_ELIGIBILITY: `${config.apiGateway.URL}/fantasy/sl_contest/check_eligibility_for_contest`,
    GET_LEAGUE_USERS: `${config.apiGateway.URL}/fantasy/sl_contest/get_league_users`,
    DELETE_LEAGUE: `${config.apiGateway.URL}/fantasy/sl_contest/delete_contest`,
    GET_PLAYERS_STASTICS: `${config.apiGateway.URL}/fantasy/sl_lineup/get_players_stastics`,
    REMOVE_LEAGUE_USER: `${config.apiGateway.URL}/fantasy/sl_lineup/remove_league_user`,
    GET_USER_LEADERBOARD_LIST: `${config.apiGateway.URL}/fantasy/sl_leaderboard/get_user_leaderboard_list`,
    GET_USER_LEADERBOARD_DETAILS: `${config.apiGateway.URL}/fantasy/sl_leaderboard/get_user_leaderboard_details`,
    
    GET_NOTIFICATION: `${config.apiGateway.URL}/user/notification/get_notification`,
    UPDATE_READ_ALL: `${config.apiGateway.URL}/user/notification/update_read_all`,
    
    // Profile
    GET_MY_PROFILE: `${config.apiGateway.URL}/user/my_profile/get_my_profile`,
    DO_UPLOAD: `${config.apiGateway.URL}/user/my_profile/do_upload`,
    UPDATE_PROFILE: `${config.apiGateway.URL}/user/my_profile/update_profile`,
    GET_ALL_COUNTRY: `${config.apiGateway.URL}/user/my_profile/get_country_list`,
    GET_ALL_STATE: `${config.apiGateway.URL}/user/my_profile/get_state_list`,
    S3_GET_ALL_STATE: `state_list`,
    
    GET_ALL_WEEK: `${config.apiGateway.URL}/fantasy/sl_contest/get_all_week`,
    GET_FIXTURE_LIST: `${config.apiGateway.URL}/fantasy/sl_contest/get_fixture_list`,
    LEAVE_LEAGUE: `${config.apiGateway.URL}/fantasy/sl_lineup/leave_league`,
    GET_PRIZE_DATA: `${config.apiGateway.URL}/user/common/get_prize_data`,
    ACTIVATE_ACCOUNT: `${config.apiGateway.URL}/user/auth/activate_account`,
    S3_GET_ALL_WEEK: `week_list`,
    S3_GET_FIXTURE_LIST: `fixture_list`,

    // May 2019
    GET_MATCH_SUMMARY: `${config.apiGateway.URL}/fantasy/sl_contest/get_match_summary`,
    GET_LEAGUE_DETAIL: `${config.apiGateway.URL}/fantasy/common/get_league_detail`,
    S3_GET_LEAGUE_DETAIL: `league_details`,

    GET_LINEUP_SUBSTITUTE_PLAYER: `${config.apiGateway.URL}/fantasy/sl_lineup/get_lineup_substitute_player`,

    GET_ALL_MONTHLY_LEAGUE_LIST: `${config.apiGateway.URL}/fantasy/sl_new_leaderboard/get_all_monthly_league_list`,
    GET_USER_LEADERBOARD_LIST_GLOBAL: `${config.apiGateway.URL}/fantasy/sl_new_leaderboard/get_user_leaderboard_list_global`,
    GET_USER_LEADERBOARD_LIST_PRIVATE: `${config.apiGateway.URL}/fantasy/sl_new_leaderboard/get_user_leaderboard_list_private`,

    // BANNER SERVICE 
    GET_BANNER: `${config.apiGateway.URL}/fantasy/advertisements/advertisement_list`,
    S3_GET_BANNER: `advertisement_list`,
    SPORTS_LEAG_LIST : `${config.apiGateway.URL}/fantasy/sl_lobby/get_sport_leagues`,
    
    //Trivia
    GET_QUESTION_LIST : `${config.apiGateway.URL}/fantasy/trivia/get_question_list`,
    USER_ANSWER : `${config.apiGateway.URL}/fantasy/trivia/user_answer`,
    GET_TRIVIA_LEADERBOARD_USERS : `${config.apiGateway.URL}/fantasy/trivia/get_trivia_leaderboard_users`,
    GET_USER_LEADERBOARD_INFO : `${config.apiGateway.URL}/fantasy/trivia/get_user_leaderboard_info`,
    GET_SPINTHEWHEEL : `${config.apiGateway.URL}/user/spinthewheel/get_spinthewheel`,
    WIN_SPINTHEWHEEL : `${config.apiGateway.URL}/user/spinthewheel/win_spinthewheel`,
}

export default {
    ...API
};

// advertisement_list, top_scoring_players, 