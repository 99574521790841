/* eslint-disable eqeqeq */
import React from 'react'
import { Modal, Nav, NavItem } from 'react-bootstrap';
import { MyContext } from '../context';
import _ from "lodash";

export default class FreehitModal extends React.Component {
    render() {
        const { LineupsList } = this.props;
        return (
            <MyContext.Consumer>
                {(context) => (

                    <Modal
                        show={this.props.TransferUpdateModalShow}
                        
                        dialogClassName="custom-modal"
                    >
                        <Modal.Body className='transfer-update-modal'>
                            <div className="title">{context.state.locale.transfer_update_modal.title}</div>
                            <div className="sub-title">{context.state.locale.transfer_update_modal.sub_title}</div>

                            <ul className="transfer-playerlist">
                                {
                                    _.map(LineupsList, (item, idx) => {
                                        return (
                                            item.is_rule_violate != 0 &&
                                            <li key={idx}>
                                                <figure className="player-tshirt">
                                                    <img src={item.jersey} alt="" />
                                                </figure>
                                                <div className="overflow">
                                                    <div className="player-name">{item.full_name}</div>
                                                    <ul className="player-details-list">
                                                        <li>{item.team_abbr_lang}</li>
                                                        <li>{item.position}</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>



                            <div className="small-text-bottom">{context.state.locale.transfer_update_modal.small_text_bottom}</div>

                            <Nav className='player-card-footer'>
                                <NavItem onClick={() => this.props.modalHide('TransferUpdateModalShow')}>{context.state.locale.transfer_update_modal.ok_got_it}</NavItem>
                            </Nav>
                        </Modal.Body>
                    </Modal>

                )}
            </MyContext.Consumer>
        );
    }
}