/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React from 'react';
import Images from '../components/images';
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";

import { MyContext } from '../context';

const PlayerCardList = ({ list, position, is_bench_player, FieldviewThis}) => (
    <div className="list-view">
    {
            _.map(list, (item, index) => {
                    let EmptyListItem = 
                        <div className="player-list-row default" key={index} onClick={() => FieldviewThis.props.PositionSelectChange(item)}>
                                <div className="cell lg player-info-item">
                                    <img src={Images.CLUB_FLAG_DEFAULT} alt="" className='club-flag' />
                                    <figure className="thumbnail">
                                        <img src={Images.DEFAULT_USER} alt="" />
                                    </figure>
                                    <div className="player-title">
                                        <a>Player Name</a>
                                    </div>
                                    <div className="player-subtitle">Team Name</div>
                                </div>
                                <div className="cell link-color">--</div>
                                <div className="cell">--</div>
                            </div>
                    let FillListItem = 
                        <div className="player-list-row" key={index}>
                                <div className="cell lg player-info-item">
                                    <img src={item.flag} alt="" className='club-flag' />
                                    <figure className="thumbnail">
                                        <img src={item.player_image} alt="" onError={() => FieldviewThis.ImageCallback(index)}/>
                                    </figure>
                                    <div className="player-title">
                                        <a onClick={() => FieldviewThis.props.playerCardModalInit('PlayerCardModalShow', item, true)}>{FieldviewThis.props.playerNameSort(item.full_name)}</a>
                                        {
                                            item.player_role == 1 &&
                                            <img src={Images.CAPTAIN_IC} alt="" className='player-role-icon'/>
                                        }
                                        {
                                            item.player_role == 2 &&
                                            <img src={Images.VICE_CAPTAIN_IC} alt="" className='player-role-icon'/>
                                        }
                                            
                                        {
                                            item.is_rule_violate == 1 
                                            ?
                                                <img src={Images.transferError} className = 'player-role-icon' alt='' />
                                            :
                                                (item.is_club_remove == 1 || item.is_club_change == 1)
                                                ?
                                                    <img src={Images.transferWarning} className = 'player-role-icon' alt='' />
                                                :
                                                    null
                                        }
                                        
                                        {
                                            (!_.isEmpty(FieldviewThis.props.team_info) && !_.isUndefined(FieldviewThis.props.SelectedWeek))  && FieldviewThis.props.team_info.captain_booster_status.applied_week >= FieldviewThis.props.SelectedWeek && (FieldviewThis.props.team_info.captain_booster_status.is_applied == 1 && FieldviewThis.props.SelectedWeek == Number(FieldviewThis.props.team_info.captain_booster_status.applied_week)) && item.player_role == 1 &&
                                            <img src={Images.CAPTAIN_BOOSTER} alt="" className='player-role-icon two-x'/>
                                        }
                                    </div>
                                    <div className="player-subtitle">
                                        {item.team_abbr_lang}
                                        {
                                            item.is_bench_player == '1' ?
                                            <span> | {item.position}</span>
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="cell link-color">{item.salary}{NC.SALARY_UNIT}</div>
                                <div className="cell">{FieldviewThis.props.isPointScreen ? (item.score  || '--'): (item.fantasy_score  || '--')}</div>
                            </div>
                let Card =
                        <div className="list-view-item" key={index}>
                            {/* {index == 0 && header} */}
                            {item.player_uid ? FillListItem : EmptyListItem}
                        </div>
                
                if (item.is_bench_player == is_bench_player && !FieldviewThis.props.isFullFieldViewMode) {
                    return (
                        position != undefined ? (item.position == position ? Card : '') : Card
                    )
                }
                if (FieldviewThis.props.isFullFieldViewMode) {
                    return (
                        position != undefined ? (item.position == position ? Card : '') : Card
                    )
                }
            })
    }
    </div>
);

const PlayerListHeader = ({ position, FieldviewThis, is_bench_player, context }) => {
    return <header className='list-view-header'>
        
        <div className="cell lg">
            {is_bench_player ? NC.BENCH_PLAYERS : FieldviewThis.props.displayPosition(position, 'NAME', context.state.locale.displayPosition)}
            {
                is_bench_player && (!_.isEmpty(FieldviewThis.props.team_info) && !_.isUndefined(FieldviewThis.props.SelectedWeek)) && FieldviewThis.props.team_info.bench_booster_status.applied_week >= FieldviewThis.props.SelectedWeek && FieldviewThis.props.team_info.bench_booster_status.is_applied == 1 &&
                <img src={Images.BENCH_BOOSTER} alt="" className='player-role-icon'/>
            }
        </div>
        <div className="cell">{context.state.locale.FieldView.currency_label}</div>
        <div className="cell"> {FieldviewThis.props.isPointScreen ? context.state.locale.FieldView.gwp :  context.state.locale.FieldView.tp} </div>
    </header>
}

export default class ListViewField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    ImageCallback = (idx) => {
        let lineups = this.props.lineups
        lineups[idx].player_image = Images.PLAYER_IMAGE
    }

    render() {
        const {
            lineups,
            isFullFieldViewMode,
            // is_editable,
            // SelectedFormation,
            // show_transfer,
            // user_join_count,
            // SelectedWeek
        } = this.props;

        return (
            
            <MyContext.Consumer>
                {(context) => (
                    <div className='m-b-md'>
                        <PlayerListHeader position={NC.GOALKEEPER} FieldviewThis={this} context={context}/>
                        <PlayerCardList list={lineups} is_bench_player={0} position={NC.GOALKEEPER} FieldviewThis={this}/>

                        <PlayerListHeader position={NC.DEFENDER} FieldviewThis={this} context={context}/>
                        <PlayerCardList list={lineups} is_bench_player={0} position={NC.DEFENDER} FieldviewThis={this}/>

                        <PlayerListHeader position={NC.MIDFIELDER} FieldviewThis={this} context={context}/>
                        <PlayerCardList list={lineups} is_bench_player={0} position={NC.MIDFIELDER} FieldviewThis={this}/>

                        <PlayerListHeader position={NC.FORWARDER} FieldviewThis={this} context={context}/>
                        <PlayerCardList list={lineups} is_bench_player={0} position={NC.FORWARDER} FieldviewThis={this}/>
                        {
                            !isFullFieldViewMode &&
                            <div>
                                <PlayerListHeader position={NC.FORWARDER} FieldviewThis={this} is_bench_player={true}  context={context}/>
                                <PlayerCardList list={lineups} is_bench_player={1} FieldviewThis={this}/>
                            </div>
                        }

                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}