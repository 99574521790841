/* eslint-disable eqeqeq, no-unused-vars */
import React from 'react';
import { MyContext } from '../context';
import moment from 'moment';
import Moment from 'react-moment';
import _ from "lodash";
import WSManager from "../helper/WSManager";

let momentLib;
export default class DeadlineClock extends React.Component {

    // Methods
    // ------------------------------
    getUtcToLocal = (date) => {
        return moment.utc(date).local().format();
    }

    // Lifecycle
    // ------------------------------
    constructor(props) {
        super(props);
        this.state = {
            date: this.getUtcToLocal(this.props.date)
        }
    }
    componentDidMount() {
        if(!_.isUndefined(this.props.state) && this.props.state.language == 'ar') momentLib = require('moment/locale/ar');
    }
    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className={`deadline-clock ${this.props.position ? this.props.position : ''}`}>
                        <i className="icon-hourglass"></i>
                        <div className="overflow">
                            {/* {this.props.gameweek} */}
                            <div className="clock-title">{WSManager.getWeekLabel( WSManager.getMultiLegData().week_label[this.props.gameweek])}  {context.state.locale.DeadlineClock.deadline}</div>
                            <div className="time-value">
                                <Moment date={this.state.date} format="DD MMM H:mm" />
                            </div>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}