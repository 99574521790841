/* eslint-disable eqeqeq */
import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import Img from '../components/images'
import WSManager from '../helper/WSManager'
import { API } from '../helper'
import * as NC from '../helper/NetworkingConstants'
import _ from 'lodash'
import { MyContext } from '../context'
import Slider from 'react-slick'
import { Helmet } from 'react-helmet'
import AdsContainer from '../components/AdsContainer'
import SimpleImageSlider from "ac-react-simple-image-slider";


var durationForBanner = 10;

export default class Statistics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statsData: '',
      mostPickedCaptain: [],
      topScoringPlayer: [],
      transferINPlayer: [],
      transferOUTPlayer: [],
    }
  }
  componentWillMount() {
    this.getSTASTICSList()
  }
  componentDidMount(){
    this.callAdvBanner(WSManager.getAdBanner())
  }
  callAdvBanner = (data) => {
    let bannerData = data.advertisements
    let lowerBanner = [];
            let squareBanner = [];
            let topPtcBanner = [];
            let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
            for (let k = 0; k < bannerData.length; k++) {
                if (bannerData[k].ad_position_id == 16 && bannerData[k].league_id == sportsLg) {
                    bannerData[k]["src"] = bannerData[k].image_adsense
                    bannerData[k]["id"] = bannerData[k].ads_unique_id
                    topPtcBanner.push(bannerData[k])
                }
                // if (bannerData[k].ad_position_id == 10 && bannerData[k].league_id == sportsLg) {
                //     bannerData[k]["src"] = bannerData[k].image_adsense
                //     bannerData[k]["id"] = bannerData[k].ads_unique_id
                //     lowerBanner.push(bannerData[k])
                // }
                // if (bannerData[k].ad_position_id == 11 && bannerData[k].league_id == sportsLg) {
                //     bannerData[k]["src"] = bannerData[k].image_adsense
                //     bannerData[k]["id"] = bannerData[k].ads_unique_id
                //     squareBanner.push(bannerData[k])
                // }
            }
            this.setState({
                // lowerBanner: lowerBanner,
                // squareBanner:squareBanner,
                topPtcBanner:topPtcBanner
            }, () => {
                this.getBannerIndex();
                // console.log(this.state.squareBanner,'sdssdfsquareBanner')
            })
}
 /**
 * BANNER CLICK FOR LOWER BANNER 
 */
getBannerIndex = (e) => {
    for (let i = 0; i <= this.state.topPtcBanner.length - 1; i++) {
        this.task(this.state.topPtcBanner[i], i);
    }
}
task = (data, i) => {
    let a = this.state.topPtcBanner.length - 1;
    setTimeout(function () {
        this.setState({
            currentBanner: data.target_url
        })
        if (a == i) {
            setTimeout(function () {
                this.getBannerIndex();
            }.bind(this), durationForBanner * 1000)

        }
    }.bind(this), durationForBanner * 1000 * i);

}

getBannerClick = (from) => {
    if (from == 2) {
        window.open(this.state.currentBanner);
    }

}
  // API FATCH
  getSTASTICSList = () => {
    let param = {
      // "sports_id": NC.sportsId,
      // "league_id": NC.leagueId,
      sports_id: WSManager.getMultiLegData().sports_id,
      league_id: WSManager.getMultiLegData().league_id,
    }

    WSManager.Rest(API.GET_PLAYERS_STASTICS, param).then((responseJson) => {
      if (responseJson.response_code === NC.successCode) {
        responseJson = responseJson.data
        this.setState({
          statsData: responseJson,
          mostPickedCaptain: responseJson.most_picked_captains,
          topScoringPlayer: responseJson.top_scoring_players,
          transferINPlayer: responseJson.in_players,
          transferOUTPlayer: responseJson.out_players,
        })
      }
    })
  }

  render() {
    var settings = {
      rtl: false,
      infinite: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      centerMode: false,
      variableWidth: false,
      centerPadding: '0px',
      initialSlide: 0,
      arrows: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            arrows: false,
          },
        },
      ],
    }

    const {
      statsData,
      mostPickedCaptain,
      topScoringPlayer,
      transferINPlayer,
      transferOUTPlayer,
    } = this.state
    return (
      <MyContext.Consumer>
        {(context) => (
          <div className="container main-container statistics-style">
          <Row>
              <Col md={12}> {
                this.state.topPtcBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }}>
                  <SimpleImageSlider
                    showArrows={false}
                    showDots={false}
                    data={this.state.topPtcBanner}
                    duration={durationForBanner}
                  />
                </div>
              }</Col>
            </Row>
            <AdsContainer />
            <Helmet
              titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}
            >
              <title>{context.state.locale.MetaTag.statistics_title}</title>
              <meta
                name="description"
                content={context.state.locale.MetaTag.statistics_description}
              />
            </Helmet>
            {context.state.language == 'ar' ? (settings.rtl = true) : null}
            <Row className="">
              <Col lg={12} className="auto-margin">
                <div className="statistics-heading m">
                  <div className="title">
                    {context.state.locale.Statistics.hero_title}
                  </div>
                  <div className="subtitle">
                    {context.state.locale.Statistics.sub_title}
                  </div>
                </div>
                <div className="top-statistics-container">
                  <div className="content-header">
                    {context.state.locale.Statistics.top_scoring_players}
                  </div>

                  <Slider className="scoringPlayer_slider" {...settings}>
                    {_.map(topScoringPlayer, (item, idx) => {
                      return (
                        <div
                          key={idx}
                          className="scoringPlayerCard"
                          onClick={() =>
                            context.playerCardModalInit(
                              'PlayerCardModalShow',
                              item,
                            )
                          }
                        >
                          <div alt="" className="rectangle">
                            <div className="positionName-update">
                              {item.position}
                            </div>
                          </div>
                          <img src={item.flag} alt="" className="imgFlag" />
                          {/* <span className="positionName" >{item.position}</span> */}
                          <img
                            src={item.jersey}
                            alt=""
                            className="playerImage"
                          />
                          <div className="playerScoreDetail">
                            <div className="playerFNAme">{item.full_name}</div>
                            {/* <div className="playerLNAme">{item.last_name}</div> */}
                            <div className="points-value">{item.top_score}</div>
                            <div className="points-label">
                              {context.state.locale.Dashboard.points}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                </div>
                <div className="top-statistics-container">
                  <div className="content-header">
                    {context.state.locale.Statistics.most_picked_captain}
                  </div>
                  <Slider className="scoringPlayer_slider" {...settings}>
                    {
                      // _.map(mostPickedCaptain, (item, idx) => {
                      //     return (
                      //         <div key={idx} className="statistics-playercard" onClick={() => context.playerCardModalInit('PlayerCardModalShow', item)}>
                      //             <img src={Img.SHAPPLAYERPOSITION} alt="" className="imgShap" />
                      //             <img src={item.flag} alt="logo" className="imgFlag" />
                      //             <span className="positionName" >{item.position}</span>
                      //             <img src={item.player_image} alt="" className="playerImage" />
                      //             <div className="playerScoreDetail">
                      //                 <div className="playerFNAme">{item.full_name}</div>
                      //                 {/* <div className="playerLNAme">{item.last_name}</div> */}
                      //                 <div className="points-value center-laye">{parseFloat(item.captain_percentage).toFixed()}<span className="points-label">%</span></div>

                      //             </div>
                      //         </div>
                      //     )
                      // })
                      _.map(mostPickedCaptain, (item, idx) => {
                        return (
                          <div
                            key={idx}
                            className="scoringPlayerCard"
                            onClick={() =>
                              context.playerCardModalInit(
                                'PlayerCardModalShow',
                                item,
                              )
                            }
                          >
                            <div alt="" className="rectangle">
                              <div className="positionName-update">
                                {item.position}
                              </div>
                            </div>
                            <img src={item.flag} alt="" className="imgFlag" />
                            {/* <span className="positionName" >{item.position}</span> */}
                            <img
                              src={item.jersey}
                              alt=""
                              className="playerImage"
                            />
                            <div className="playerScoreDetail">
                              <div className="playerFNAme">
                                {item.full_name}
                              </div>
                              {/* <div className="playerLNAme">{item.last_name}</div> */}
                              <div className="points-value">
                                {item.top_score}
                              </div>
                              <div className="points-value center-laye">
                                {parseFloat(item.captain_percentage).toFixed()}
                                <span className="points-label">%</span>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </Slider>
                </div>
                <div className="top-statistics-container">
                  <div className="content-header">
                    <img src={Img.ARROW_IN} alt="" className="arrow-inout" />
                    {context.state.locale.Statistics.transferred_in} (
                    {context.state.locale.Statistics.gameweek}{' '}
                    {statsData.next_week})
                  </div>
                  <div className="statistics-list-view">
                    {_.map(transferINPlayer, (item, index) => {
                      return (
                        <div
                          className="statistics-player-list-row"
                          key={index}
                          onClick={() =>
                            context.playerCardModalInit(
                              'PlayerCardModalShow',
                              item,
                            )
                          }
                        >
                          <div className="cell lg player-info-item col-sm-8 col-md-8 col-xs-7">
                            <img src={item.flag} alt="" className="club-flag" />
                            <figure className="thumbnail">
                              <img src={item.jersey} alt="" />
                            </figure>
                            <div className="player-title">
                              <span>{item.full_name}</span>
                            </div>
                            <div className="player-subtitle">
                              {item.team_abbr_lang}
                            </div>
                          </div>
                          <div className="cell col-sm-2 col-md-2 col-xs-2">
                            {item.position || '--'}
                          </div>
                          <div className="cell link-color col-sm-2 col-md-2 col-xs-3">
                            {item.salary}
                            {NC.SALARY_UNIT}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="top-statistics-container">
                  <div className="content-header">
                    <img src={Img.ARROW_OUT} alt="" className="arrow-inout" />
                    {context.state.locale.Statistics.transferred_out} (
                    {context.state.locale.Statistics.gameweek}{' '}
                    {statsData.next_week})
                  </div>
                  <div className="statistics-list-view">
                    {_.map(transferOUTPlayer, (item, index) => {
                      return (
                        <div
                          className="statistics-player-list-row"
                          key={index}
                          onClick={() =>
                            context.playerCardModalInit(
                              'PlayerCardModalShow',
                              item,
                            )
                          }
                        >
                          <div className="cell lg player-info-item col-sm-8 col-md-8 col-xs-7">
                            <img src={item.flag} alt="" className="club-flag" />
                            <figure className="thumbnail">
                              <img src={item.jersey} alt="" />
                            </figure>
                            <div className="player-title">
                              <span>{item.full_name}</span>
                            </div>
                            <div className="player-subtitle">
                              {item.team_abbr_lang}
                            </div>
                          </div>
                          <div className="cell col-sm-2 col-md-2 col-xs-2">
                            {item.position || '--'}
                          </div>
                          <div className="cell link-color col-sm-2 col-md-2 col-xs-3">
                            {item.salary}
                            {NC.SALARY_UNIT}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="view-all-stats text-center">
                  <Button
                    type="button"
                    bsStyle="primary"
                    onClick={() =>
                      this.props.history.push({ pathname: '/player-stats' })
                    }
                  >
                    {context.state.locale.Statistics.view_player_stats}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </MyContext.Consumer>
    )
  }
}
