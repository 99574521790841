/* eslint-disable eqeqeq */
import React, { Fragment } from 'react'
import { Modal } from 'react-bootstrap';
import Images from '../components/images'
import { MyContext } from '../context';

export default class BannerModal extends React.Component {
    render() {
        const { isOpen, toggle, BannerType } = this.props;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={isOpen}
                        onHide={() => toggle()}
                        dialogClassName="custom-modal">
                        <div className="modal-header no-padding border-0">
                            <button className="close player-card-close" onClick={() => toggle()}><span aria-hidden="true">×</span></button>
                        </div>

                        {
                            BannerType == 1 && // Special Leagues
                            <Fragment>
                                <div className="modal-banner-header">
                                    <img src={context.state.language == 'ar' ? Images.BANNER_POPUP_AR_1 : Images.BANNER_POPUP_1} alt="" />
                                </div>
                                <Modal.Body className="modal-banner-body">
                                    {
                                        context.state.language == 'ar' ?
                                            <Fragment>
                                                <p>هي دوريات مميزة لعدد محدد من الجولات. هذه الدوريات لها جوائزها الخاصة والتي سيعلن عنها من خلال حساب الحريف الرسمي على تويتر Goal@.
            في نهاية كل دوري مميز , سيتم منح اللاعبين الحاصلين على أعلى المراكز جوائز خاصة يعلن عنها من خلال حساب التويتر.
            تعتمد المراكز والنقاط في أي دوري مميز بعد انتهاء الدوري باربعة أيام.
لست بحاجة الى رمز للانضمام الى الدوريات المميزة وسينضم إليها جميع اللاعبين تلقائيًا.</p>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <p>Special Leagues are created by Goal Team for a specific number of gameweeks. These leagues have their own prizes that will be announced through @Goal official twitter account. At the end of each Special League, players with the highest ranks will be granted a prize. The ranking and points of any special league is considered final 4 days after the end of the last gameweek. You don’t need to have a code to join the special leagues and all players will join it automatically.</p>
                                                <p>When a special league is  completed, you will be able to see it under Completed Special Leagues tab in Leagues section.</p>
                                            </Fragment>
                                    }

                                </Modal.Body>
                            </Fragment>
                        }
                        {
                            BannerType == 2 && // Auto Substitution
                            <Fragment>
                                <div className="modal-banner-header">
                                    <img src={context.state.language == 'ar' ? Images.BANNER_POPUP_AR_2 : Images.BANNER_POPUP_2} alt="" />
                                </div>
                                <Modal.Body className="modal-banner-body">
                                    {
                                        context.state.language == 'ar' ?
                                            <Fragment>
                                                <p>يتم التبديل التلقائي للاعبين الاساسيين الذين لم يشاركوا خلال الجولة على الشكل التالي:
    اي لاعب من تشكيلتك الاساسية لم يشارك خلال الجولة سيتم استبداله بأحد لاعبي الإحتياط الذين شاركوا في الجولة بحسب الأفضلية وبدون ان يكسر في اي وقت القواعد الأساسية لتأسيس الفرق. (حارس مرمى، ثلاثة مدافعين، مهاجم)
    بهذه الطريقة ستضاف النقاط التي أحرزتها عن طريق لاعبي دكة البدلاء في الجولة إلى مجموع نقاطك.
يتم الإستبدال التلقائي بعد نهاية كل جولة.</p>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <p>Goal will automatically replace your players who didn’t play in a gameweek. Auto substitution is processed at the end of each gameweek.</p>
                                                <p>If any of your players plays 0 minutes in the gameweek, he will be substituted by the highest priority substitute bench player who played in that gameweek and does not break the original formation rule of creating teams.</p>
                                                <p>In this way the points scored by your bench players during that gameweek will be added to your score.</p>
                                            </Fragment>
                                    }

                                </Modal.Body>
                            </Fragment>
                        }
                    </Modal>
                )}
            </MyContext.Consumer>
        );
    }
}