/* eslint-disable eqeqeq, no-sequences, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import { Dropdown, MenuItem } from 'react-bootstrap';
import Drawer from 'react-motion-drawer';

import Images from '../components/images'
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import Moment from 'react-moment';
import * as qs from 'query-string';
import { MyContext } from './../context';
import { API } from '../helper';

// const pathToRegexp = require('path-to-regexp');

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            NotificationList: [],
            unread_count: 0,
            DrawerMenuOpen: false,
            user_join_count: _.isUndefined(localStorage.getItem('user_join_count')) ? 0 : localStorage.getItem('user_join_count'),
            updateHeader: true,
            isMobile: false
        }
    }
    GetNotification = (ByPass) => {
        if (!ByPass) this.setState({ updateHeader: false });
        let param = {
            "limit": 5,
            "offset": 0
        }
        WSManager.Rest(API.GET_NOTIFICATION, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                setTimeout(() => {
                    this.setState((state, props) => {
                        return {
                            NotificationList: responseJson.data.notification_list,
                            unread_count: responseJson.data.unread_count,
                            updateHeader: true
                        }
                    });
                }, 500);
            }
        })
    }

    readAllNotification = () => {
        WSManager.RestGet(API.UPDATE_READ_ALL).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.GetNotification(true);
            }
        })
    }
    _renderTagMessage = (rowData) => {
        var msg = rowData.message
        //console.log(rowData,"rowData");
        if(rowData.notification_type == '51'){
            var msg = rowData.message || '';

            var content = JSON.parse(rowData.content);
           
           
            msg = msg.replace("{{result_text}}",content.result_text);
            
            return msg;
        }
        else{
            var arrySplit = msg.split(/ /)
            for (var i = 0; i < arrySplit.length; i++) {
                arrySplit[i] = arrySplit[i] + ' '
                for (var obj in JSON.parse(rowData.content)) {
                     if (arrySplit[i] == "{{" + obj + "}} ") {
                       
                        arrySplit[i] = <span key={obj} >{JSON.parse(rowData.content)[obj]} </span>
                    }
                    if (arrySplit[i] == "{{contest_name}}, ") {
                        
                        arrySplit[i] = <span key={obj} >{JSON.parse(rowData.content)[obj]}, </span>
                    }
                    
                }
            }
            return arrySplit;
        }
        
       
    }
    UserInfoUpdate = (info) => {
        this.setState({ userInfo: info })
    }

    refreshPage() {
        window.location.reload();
    }

    // LOGOUT
    UserLogout = () => {
        WSManager.logout();
        WSManager.setMultiLeg(false)
        WSManager.setMultiLegData(null);
        this.props.history.push('/');
        this.refreshPage();
        // const { location } = this.props
        // const re = pathToRegexp('/')
        // const path_result = re.exec(location.pathname)
        // if (path_result) {
        //     this.refreshPage();
        // }
    }

    DrawerMenuTrigger = (DrawerMenuOpen) => {
        this.setState({
            DrawerMenuOpen: !DrawerMenuOpen
        })
    }
    componentDidMount = () => {
        if (WSManager.loggedIn()) {
            this.GetNotification();
        }

        const { location } = this.props;
        const parsed = qs.parse(location.search);
        this.setState((state, props) => {
            return {
                isMobile: parsed.isMobile
            }
        });
    }


    render() {
        const {
            NotificationList,
            unread_count,
            DrawerMenuOpen,
            // user_join_count,
            updateHeader,
            isMobile
        } = this.state;

        const drawerProps = {
            overlayColor: "rgba(0,0,0,0.8)",
            right: false,
            fadeOut: true,
            open: DrawerMenuOpen
        };
        if (isMobile) { return null; }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <header className="header">
                        {
                            context.state.language == 'ar' ? drawerProps.right = true : drawerProps.right = false
                        }
                        <div className='d-block d-sm-block d-md-none'>
                            <Drawer className='drawer-menu-wrapper'
                                {...drawerProps}
                                onChange={open => this.setState({ DrawerMenuOpen: open })}>
                                <div className="drawer-menu-wrapper">
                                    {
                                        context.state.loggedInStatus ?
                                            <div className="drawer-profile-content">

                                                <span className='drawer-profile-link' onClick={() => (this.props.history.push({ pathname: '/profile' }), this.DrawerMenuTrigger(DrawerMenuOpen))}>
                                                    <span className="profile-edit-btn"></span>
                                                    <span> {context.state.locale.MetaTag.profile_title_lower}</span>
                                                </span>

                                                <div className="profile-thumbnail">
                                                    <img alt='' src={context.state.userInfo.image} />
                                                </div>
                                                <div className="m-t">
                                                    <span className="user-full-name">{context.state.userInfo.first_name !== '' || context.state.userInfo.last_name !== '' ? context.state.userInfo.first_name + ' ' + context.state.userInfo.last_name : context.state.userInfo.user_name}</span>
                                                </div>
                                                <div className="drawer-lang-switch">
                                                    <span className={`${context.state.language == 'en' && 'selected'}`} onClick={() => context.changeLanguage('en')}>english</span>
                                                    <span className={`arabic ${context.state.language == 'ar' && 'selected'}`} onClick={() => context.changeLanguage('ar')}>عربى</span>
                                                </div>
                                            </div>
                                            :
                                            <div className="drawer-profile-content">
                                                <div className="profile-thumbnail">
                                                    <img src={Images.DEFAULT_USER} alt="" />
                                                </div>
                                                <div className="m-t">
                                                    <a className="login-btn" onClick={() => (context.modalShow('LoginModalShow'), this.DrawerMenuTrigger(DrawerMenuOpen))}>Login</a>
                                                </div>

                                                <div className="drawer-lang-switch">
                                                    <span className={`${context.state.language == 'en' && 'selected'}`} onClick={() => context.changeLanguage('en')}>english</span>
                                                    <span className={`arabic ${context.state.language == 'ar' && 'selected'}`} onClick={() => context.changeLanguage('ar')}>عربى</span>
                                                </div>
                                            </div>
                                    }
                                    <ul className="drawer-menu-list">
                                       {
                                           !WSManager.getMultiLeg() && context.state.loggedInStatus &&<li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                           <NavLink exact to="/home">
                                               <i className="ic nav-home-ic"></i>
                                               {context.state.locale.nav.home}
                                           </NavLink>
                                       </li>
                                       }
                                        {
                                           !WSManager.getMultiLeg() && context.state.loggedInStatus && context.state.user_join_count == 1 &&
                                            <li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                                <NavLink exact to="/my-team">
                                                    <i className="ic nav-my-team-ic"></i>
                                                    {context.state.locale.nav.my_team}
                                                </NavLink>
                                            </li>
                                        }
                                        {
                                           !WSManager.getMultiLeg() &&  context.state.loggedInStatus && context.state.user_join_count == 1 && Number(context.state.week_info.active_week) >= Number(context.state.joined_week) &&
                                            <li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                                <NavLink exact to="/points">
                                                    <i className="ic nav-points-ic"></i>
                                                    {context.state.locale.nav.points}
                                                </NavLink>
                                            </li>
                                        }
                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus && context.state.user_join_count == 1 &&
                                            <li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                                <NavLink exact to="/transfers">
                                                    <i className="ic nav-transfers-ic"></i>
                                                    {context.state.locale.nav.transfers}
                                                </NavLink>
                                            </li>
                                        }
                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus && context.state.user_join_count == 1 &&
                                            <li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                                <NavLink exact to="/leagues">
                                                    <i className="ic nav-leagues-ic"></i>
                                                    {context.state.locale.nav.leagues}
                                                </NavLink>
                                            </li>
                                        }

                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus && <li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                                                                    <NavLink exact to="/fixtures">
                                                                                        <i className="ic nav-fixtures-ic"></i>
                                                                                        {context.state.locale.nav.fixtures}
                                                                                    </NavLink>
                                                                                </li>
                                        }
                                       
                                       {
                                           !WSManager.getMultiLeg() && context.state.loggedInStatus && <li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                            <NavLink exact to="/statistics">
                                                <i className="ic nav-statistics-ic"></i>
                                                {context.state.locale.nav.statistics}
                                            </NavLink>
                                        </li>
                                       }
                                        
                                       
                                        

                                        {/* <li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                            <NavLink exact to="/prizes">
                                                <i className="ic nav-prizes-ic"></i>
                                                {context.state.locale.nav.prizes}
                                            </NavLink>
                                        </li> */}

                                        <li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                            <NavLink exact to="/how-to-play">
                                                <i className="ic nav-howtoplay-ic"></i>
                                                {context.state.locale.nav.how_to_play}
                                            </NavLink>
                                        </li>
                                        {
                                            !WSManager.getMultiLeg() &&context.state.loggedInStatus && context.state.user_join_count == 1 &&
                                            <li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                                <NavLink exact to="/notification">
                                                    <i className="ic nav-notifications-ic"></i>
                                                    {context.state.locale.nav.notification}
                                                </NavLink>
                                            </li>
                                        }
                                        {
                                           !WSManager.getMultiLeg() && context.state.loggedInStatus && <li className="drawer-menu-item switch-leg-bg" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                            <NavLink exact to="/select-league">
                                                <i className="ic nav-switch-ic"></i>
                                                {
                                                    context.state.locale.nav.switch_leg
                                                }
                                            </NavLink>
                                        </li>
                                       }
                                        <li className="drawer-menu-item" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}>
                                            <NavLink exact to="/faq">
                                                <i className="ic nav-faq-ic"></i>
                                                {context.state.locale.nav.faq}
                                            </NavLink>
                                        </li>
                                    </ul>
                                    {
                                        context.state.loggedInStatus &&
                                        <div className="drawer-footer">
                                            <a className="league-code-btn" onClick={() => (context.modalShow('JoinLeagueCodeShow'), this.DrawerMenuTrigger(DrawerMenuOpen))}>
                                                <img src={Images.LEAGUE_CODE_IC} alt="" />
                                                {context.state.locale.header.league_code_label}
                                            </a>
                                            <a className="logout-btn" onClick={this.UserLogout}>
                                                <img src={Images.LOGOUT_IC} alt="" />
                                                {context.state.locale.header.logout_label}
                                            </a>
                                        </div>
                                    }
                                </div>
                            </Drawer>
                        </div>

                        <div className="header-top">

                            <a className="drawer-menu-trigger icon-menu d-block d-sm-block d-md-none" onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}></a>
                            <a className="logo" href='/'>
                                <img style={{ objectFit: 'contain', width: 92, height: 62 }} src={context.state.language == 'en' ? Images.KUWAIT_LEAGUE_LOGO : Images.KUWAIT_LEAGUE_LOGO} alt="" />
                            </a>

                            <div className="right-navbar d-none d-sm-none d-md-block">
                                {

                                    context.state.loggedInStatus &&
                                    <Dropdown id="NotificationDropDown" className="notification-dropdown">
                                        <Dropdown.Toggle>
                                            <i className="icon-notificationIco"></i>
                                            {
                                                unread_count > 0 &&
                                                <span className="badge notification-badge">{unread_count}</span>
                                            }
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-right">
                                            <MenuItem header className='notification-header'>
                                                {context.state.locale.header.notifications_label}
                                                {unread_count > 0 && ' (' + unread_count + ')'}
                                            </MenuItem>
                                            {
                                                _.map(NotificationList, (item, idx) => {
                                                    return (
                                                        <MenuItem className='notification-item' key={idx}>
                                                            {this._renderTagMessage(item)}
                                                            <small>
                                                                <Moment date={item.added_date} format="ddd, MMM DD - hh:mm A" />
                                                            </small>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                            <MenuItem className="notification-footer" onClick={e => (this.props.history.push("/notification"), this.readAllNotification())}>
                                                {context.state.locale.header.view_all_label}
                                            </MenuItem>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                {
                                    context.state.loggedInStatus ?
                                        <Dropdown id="UserInfo" className="user-info">
                                            <Dropdown.Toggle>
                                                <span className='user-name'>
                                                    {context.state.userInfo.first_name !== '' || context.state.userInfo.last_name !== '' ? context.state.userInfo.first_name + ' ' + context.state.userInfo.last_name : context.state.userInfo.user_name}
                                                </span>
                                                <figure className="thumbanil">
                                                    <img alt='' src={context.state.userInfo.image} />
                                                </figure>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-right">
                                                <MenuItem eventKey="1" onClick={() => this.props.history.push('/profile')}>{context.state.locale.header.my_profile_label}</MenuItem>
                                                <MenuItem eventKey="2" onClick={this.UserLogout}>{context.state.locale.header.logout_label}</MenuItem>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        :
                                        <div className='guest-user-nav'>
                                            <span onClick={() => context.modalShow('LoginModalShow', { ...this.props })}>{context.state.locale.header.login_label}</span>
                                            <span onClick={() => context.modalShow('SignupModalShow')}>{context.state.locale.header.signup_label}</span>
                                        </div>
                                }
                            </div>

                            <div className="navigation-bar d-none d-sm-none d-md-block">
                                {
                                    updateHeader &&
                                    <nav className='nav-ul nav'>
                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus && <NavLink exact to="/home">
                                                {context.state.locale.nav.home}
                                            </NavLink>
                                        }

                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus && context.state.user_join_count == 1 &&
                                            <NavLink exact to="/my-team">
                                                {context.state.locale.nav.my_team}
                                            </NavLink>
                                        }

                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus &&
                                            context.state.user_join_count == 1 &&
                                            context.state.week_info.active_week >= Number(context.state.joined_week) &&
                                            // (context.state.week_info.active_week == context.state.week_info.next_week == context.state.week_info.last_week) &&

                                            <NavLink exact to="/points">
                                                {context.state.locale.nav.points}
                                            </NavLink>
                                        }
                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus && context.state.user_join_count == 1 &&
                                            <NavLink exact to="/transfers">
                                                {context.state.locale.nav.transfers}
                                            </NavLink>
                                        }
                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus && context.state.user_join_count == 1 &&
                                            <NavLink exact to="/leagues">
                                                {context.state.locale.nav.leagues}
                                            </NavLink>
                                        }
                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus && <NavLink exact to="/fixtures">
                                                {context.state.locale.nav.fixtures}
                                            </NavLink>
                                        }
                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus && <NavLink exact to="/statistics">
                                                {context.state.locale.nav.statistics}
                                            </NavLink>
                                        }
                                        {
                                            !WSManager.getMultiLeg() && context.state.loggedInStatus && <NavLink exact to="/select-league" className='switch'>
                                                <div className='switch-inner'>
                                                    <div className='inside-inner'>
                                                        <text className='current-league'>{context.state.locale.multi_league.current_leg}</text><br></br>
                                                        <text className='english-premier-leag'>{WSManager.getMultiLegData().league_name}</text>

                                                    </div>
                                                    <div>
                                                        <img src={Images.LEG_SWITCH} alt='' />
                                                    </div>
                                                </div>

                                            </NavLink>
                                        }



                                        {/* <NavLink exact to="/prizes">
                                            {context.state.locale.nav.prizes}
                                        </NavLink> */}
                                    </nav>
                                }
                            </div>
                        </div>
                    </header>
                )}
            </MyContext.Consumer>
        );
    }
}
export default withRouter(Header, { withRef: true })