import React, { Component } from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
//import { claimSpinTheWheel } from '../WSHelper/WSCallings';
import { notify } from 'react-notify-toast';
import { API } from "../helper";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
//import { setValue } from '../helper/Constants';
import _ from "lodash";
import Images from '../components/images';

class CongratsWheelModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {

  }







  render() {
    const { showModal, toggle } = this.props;

    return (
      <Modal
        show={showModal}
        className="congrats-modal"

      >
        <div className="modal-header no-padding border-0">
          <button className="close player-card-close" onClick={() => toggle()}><span aria-hidden="true">×</span></button>
        </div>
        <Modal.Body>
          <h4>Congratulations!</h4>
          <p>{WSManager.getWheelData()}</p>
          <img src={Images.CAS_ADS} className="cas-ads" alt="" />
        </Modal.Body>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => toggle()}>OK</button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CongratsWheelModal;