/* eslint-disable no-sequences, eqeqeq */
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";

import LeagueModal from '../Modals/league-modal';
import { notify } from 'react-notify-toast';
import { MyContext } from '../context';
import { isUndefined } from 'util';


export default class LineupInfo extends React.Component {
    // Methods
    // ------------------------------

    // GET_USER_LEADERBOARD_LIST
    getUserLeaderboardList = (type) => {
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "user_id":this.props.user_id ? this.props.user_id : '' // For Point screen
        }
        let _API;
        let BoardState;
        switch (type) {
            case 1:
                _API = API.GET_USER_LEADERBOARD_LIST_PRIVATE;
                BoardState = 'PrivateLeaderboardList';
                break;

            case 2:
                _API = API.GET_USER_LEADERBOARD_LIST_GLOBAL;
                BoardState = 'GlobalLeaderboardList';
                break;

            case 3:
                _API = API.GET_ALL_MONTHLY_LEAGUE_LIST;
                BoardState = 'MonthlyLeaderboardList';
                break;

            default:
                _API = API.GET_USER_LEADERBOARD_LIST_PRIVATE;
                BoardState = 'PrivateLeaderboardList';
                break;
        }

        WSManager.Rest(_API, param).then((responseJson) => {
            try {
                if (responseJson.response_code === NC.successCode) {
                    responseJson = responseJson.data;
                    if(type == 3) {
                        this.setState({
                            [BoardState]: responseJson.monthly_standing
                        }, () => {
                            const { PrivateLeaderboardList, GlobalLeaderboardList, MonthlyLeaderboardList} = this.state
                            this.setState({
                                LeaderboardList: {
                                    ...GlobalLeaderboardList,
                                    ...PrivateLeaderboardList,
                                    ...MonthlyLeaderboardList
                                }
                            })
                        })
                    } else {
                        this.setState({
                            [BoardState]: responseJson
                        }, () => {
                            const { PrivateLeaderboardList, GlobalLeaderboardList, MonthlyLeaderboardList} = this.state
                            this.setState({
                                LeaderboardList: {
                                    ...GlobalLeaderboardList,
                                    ...PrivateLeaderboardList,
                                    ...MonthlyLeaderboardList
                                }
                            })
                        })
                    }
                    
                }
            } catch (error) {
                console.error(error)
            }
        })
    }

    // Utility
    getLeagueCategoryName = (key, context) => {
        switch (key) {
            case 'global_league':
                key = context.global_league
                break;
            case 'other_league':
                key = context.other_league
                break;
            case 'active_monthly_league':
                key = context.active_monthly_league
                break;
            case 'completed_monthly_league':
                key = context.completed_monthly_league
                break;
            default:
                key = context.global_league
                break;
        }
        return key;
    }
    // gotoDetails = (data, context) => {
    //     data.oops_msz = context.state.locale.Leagues.oops_msz
    //     //data.LeaderboardList = this.state.LeaderboardList;
    //     setTimeout(() => {
    //         if (data.contest_info.joined_week <= context.state.week_info.active_week) {
    //             this.props.history.push({ pathname: '/leagues/details', state: data })
    //         } else {
    //             notify.show(context.state.locale.Leagues.oops_msz, "success", 1500);
    //         }
    //     }, 100);
    // }

    gotoDetails = (data, context) => {
        const {LeaderboardList} = this.state
        try {
            setTimeout(() => {
                console.log(LeaderboardList)
                if (Number(data.contest_info.joined_week) <= context.state.week_info.active_week) {
                    this.props.history.push({ pathname: '/leagues/details', state: {
                        ...data, 
                        oops_msz: context.state.locale.Leagues.oops_msz,
                        LeaderboardList:LeaderboardList
                    } })
                } else {
                    notify.show(context.state.locale.Leagues.oops_msz, "success", 1500);
                }
            }, 100);
        } catch (error) {
            console.log(error, 'error');
        }
    }

    modalShow = (Modal) => {
        this.setState({ [Modal]: true });

    }
    modalHide = (Modal) => {
        this.setState({ [Modal]: false });
    }

    // Lifecycle
    // ------------------------------
    constructor(props) {
        super(props);
        this.state = {
            LeagueModalShow: false,
            UserInfo: JSON.parse(localStorage.getItem('profile')),
            week_info:  JSON.parse(localStorage.getItem('week_info'))
        }
    }
    componentWillMount() {
        var i = 1;
        do {
            this.getUserLeaderboardList(i);
            i++;
        }
        while (i < 4);
    }

    render() {
        const {
            LeaderboardList,
            LeagueModalShow,
            UserInfo
        } = this.state;
        const {
            my_performance,
            team_info,
            team_name,
            DrawerRosterTrigger,
            // user_id,
            user_name,
            getSelectedWeekObj,
            isPointScreen
        } = this.props;
        const LeagueModalProps = {
            ...this.props,
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            LeagueModalShow: LeagueModalShow
        }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="lineup-info-wrapper scoll-view">
                        <header className="lineup-info-header">
                            <div className="national-flag">
                            {
                                UserInfo &&
                                <img src={team_info.country_flag_img} alt="" />
                            }
                            </div>
                            <span className="close" onClick={() => DrawerRosterTrigger(true)}>×</span>
                            <div className="content">
                                <div className="label">
                                    {
                                        user_name ?
                                        (user_name == WSManager.getProfile().user_name ?
                                            <span className='m-r-xs'>{context.state.locale.LineupInfo.your}</span>
                                            :
                                            <span className='teal-color m-r-xs'>{user_name}</span>
                                        )
                                        :
                                        <span className='m-r-xs'>{context.state.locale.LineupInfo.your}</span>
                                    }
                                    {context.state.locale.LineupInfo.team_name}
                                </div>
                                <div className="name">{team_name || '--'}</div>
                            </div>
                        </header>
                        <div className="lineup-info-scoll" id="scrollbar">

                            {/* May 2019 */}
                            {
                                isPointScreen &&
                                <div className="lineup-info-stats">
                                    <div className="final-point">
                                        <div>{context.state.locale.LeagueDetails.final_points}</div> 
                                        <div className="value teal-color">{getSelectedWeekObj().total_score}</div>
                                    </div>
                                    <div className="stats">
                                        <Row>
                                            <Col xs={6} className='light'>{context.state.locale.MyTeam.average} {context.state.locale.PlayerCard.pts}</Col>
                                            <Col xs={6} className='text-bold'>{getSelectedWeekObj().avg_score}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} className='light'>{context.state.locale.MyTeam.highest} {context.state.locale.PlayerCard.pts}</Col>
                                            <Col xs={6} className='text-bold teal-color'>{getSelectedWeekObj().high_score}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} className='light'>{context.state.locale.LeagueDetails.gw_rank}</Col>
                                            <Col xs={6} className='text-bold'>{getSelectedWeekObj().week_rank}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} className='light'>{context.state.locale.nav.transfers}</Col>
                                            <Col xs={6} className='text-bold'>{team_info.total_transfer}</Col>
                                        </Row>
                                    </div>
                                </div>
                            }
                            {/* End */}

                            <div className="lineup-point-rank">
                                <div className="title">{context.state.locale.LineupInfo.points_rankings}</div>
                                <div className="content">
                                    <div className="cell">
                                        <div className="value">
                                            {
                                                !_.isEmpty(my_performance.overall_score) ? my_performance.overall_score : '--'
                                            }
                                        </div>
                                        <div className="label">{context.state.locale.LineupInfo.overall_points}</div>
                                    </div>
                                    <div className="cell">
                                        <div className="value">
                                            {
                                                !_.isEmpty(my_performance.overall_rank) ? my_performance.overall_rank : '--'
                                            }
                                        </div>
                                        <div className="label">{context.state.locale.LineupInfo.overall_rank}</div>
                                    </div>
                                    {/* <div className="cell">
                                        <div className="value">
                                            {
                                                !_.isEmpty(my_performance.total_user_joined) ? my_performance.total_user_joined : '--'
                                            }
                                        </div>
                                        <div className="label">{context.state.locale.LineupInfo.total_players}</div>
                                    </div> */}
                                    <div className="cell full-width">
                                        <div className="value">
                                            {
                                                !_.isEmpty(my_performance.week_score) ? my_performance.week_score : '--'
                                            }
                                        </div>
                                        <div className="label">{context.state.locale.LineupInfo.gameweek_points}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="lineup-info-leaderboard">
                                {
                                    _.map(LeaderboardList, (Leaderboard, idx) => {
                                        return (
                                            !_.isEmpty(Leaderboard) ?
                                            <div className="lineup-leaderboard" key={idx}>
                                                <div className="leaderboard-list-title">
                                                    <span>{this.getLeagueCategoryName(idx, context.state.locale.LineupInfo)}</span>
                                                </div>
                                                <ul className="lineup-leaderboard-list">
                                                    <li className='lineup-leaderboard-item title'>
                                                        <div className="cell">{context.state.locale.LineupInfo.rank}</div>
                                                        <div className="cell lg">{context.state.locale.LineupInfo.league}</div>
                                                        <div className="cell"></div>
                                                    </li>
                                                    {
                                                        !_.isEmpty(Leaderboard) ?
                                                        <div>
                                                            {
                                                                _.map(Leaderboard, (item, index) => {
                                                                    return (
                                                                        !_.isEmpty(item) &&
                                                                        <li key={index} className={`lineup-leaderboard-item tbl-row ${item.is_country_set == 0 || item.is_club_set == 0 ? 'country-not-set pointer' : ''}`} data-line={item.is_country_set == 0 ? context.state.locale.Leagues.see_your_country_rank : (item.is_club_set == 0 ? context.state.locale.Leagues.see_your_club_rank : '')}
                                                                        onClick={() => (item.is_country_set == 0 || item.is_club_set == 0) ? this.props.history.push({ pathname: '/profile', state: item }) : null}>
                                                                            <div className="cell">{item.rank || '--'}</div>
                                                                            <div className="cell lg">{!_.isUndefined(item.contest_info) ? (item.category_name || item.contest_info.contest_name) : '--'}</div>
                                                                            <div className="cell">
                                                                                <span className='standing-ic' onClick={() => this.gotoDetails(item,context)}></span>
                                                                            </div>
                                                                            <span className='hide'>
                                                                                {item.league_type = idx}
                                                                                {item.module = index}
                                                                            </span>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        <h5 className='no-data-league'>{context.state.locale.LineupInfo.oops_msz}</h5>
                                                    }
                                                </ul>
                                            </div>
                                            : ''
                                        )
                                    })
                                }
                                
                                {/* May 2019 */}
                                {
                                    !_.isUndefined(team_info.my_transafer) &&
                                    <div className="lineup-leaderboard">
                                        <div className="leaderboard-list-title">
                                            <span>{context.state.locale.LineupInfo.transfer_finance}</span>
                                        </div>
                                        <ul className="lineup-leaderboard-list">
                                            <li className={`lineup-leaderboard-item two-col`}>
                                                <div className="cell">{context.state.locale.LineupInfo.gameweek_transfer}</div>
                                                <div className="cell lg">{team_info.my_transafer.game_week_transfer}</div>
                                            </li>
                                            <li className={`lineup-leaderboard-item two-col`}>
                                                <div className="cell">{context.state.locale.LineupInfo.total_transfe}</div>
                                                <div className="cell lg">{team_info.my_transafer.total_transfer}</div>
                                            </li>
                                            {/* <li className={`lineup-leaderboard-item two-col`}>
                                                <div className="cell">{context.state.locale.LineupInfo.squad_value}</div>
                                                <div className="cell lg">{team_info.my_transafer.week}</div>
                                            </li> */}
                                        </ul>
                                    </div>
                                }
                                {/* End */}

                                {/* {(isUndefined(this.props.user_id) || this.props.user_id == '' || this.props.user_id == WSManager.getProfile().user_id) && <div className="lineup-leaderboard-footer">
                                    <Button bsStyle="primary" block onClick={() => (DrawerRosterTrigger(true), this.modalShow('LeagueModalShow'))} >{context.state.locale.LineupInfo.create_btn}</Button>
                                </div>} */}
                            </div>
                            

                        </div>
                        {(isUndefined(this.props.user_id) || this.props.user_id == '' || this.props.user_id == WSManager.getProfile().user_id) && <div className="lineup-leaderboard-footer">
                            <Button bsStyle="primary" block onClick={() => (DrawerRosterTrigger(true), this.modalShow('LeagueModalShow'))} >{context.state.locale.LineupInfo.create_btn}</Button>
                        </div>}
                        <LeagueModal {...LeagueModalProps} />
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}