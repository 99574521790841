/* eslint-disable eqeqeq */
import React from 'react'
import { Modal } from 'react-bootstrap';
import Images from '../components/images'
import { MyContext } from '../context';

export default class FreehitModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false
        };
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleHide = () => {
        this.setState({ show: false });
    }

    getValidationState() {
        const length = this.state.FirstName.length;
        if (length > 10) return 'success';
        else if (length > 5) return 'warning';
        else if (length > 0) return 'error';
        return null;
    }

    handleChange = (e) => {
        this.setState({ value: e.target.value });
    }

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (

                    <Modal
                        show={this.props.FreehitModalShow}
                        onHide={() => this.props.modalHide('FreehitModalShow')}
                        dialogClassName="custom-modal"
                    >
                        <Modal.Body>
                            <div className="thank-content">
                                <figure className="graphic m-t-md m-b">
                                    <img style={{width:100,height:80,objectFit:'contain'}} src={context.state.language == 'en' ? Images.DARK_KSAF_EN_LOGO : Images.DARK_KSAF_AR_LOGO} alt="" />
                                </figure>
                                <div className="title">{context.state.locale.Freehit.hero_title}</div>
                                <div className="freehit-footer-text m-t">
                                    <div className="m-b-sm">
                                    {context.state.locale.Freehit.p1}
                                </div>
                                    <div className="m-b-sm">
                                    {context.state.locale.Freehit.p2}
                                </div>
                                    <div className="m-b-sm">
                                    {context.state.locale.Freehit.p3}
                                </div>
                                </div>

                            </div>
                        </Modal.Body>
                    </Modal>

                )}
            </MyContext.Consumer>
        );
    }
}