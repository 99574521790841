/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { DebounceInput } from 'react-debounce-input';
import { Range } from 'rc-slider';
import { Trans } from 'react-i18next';
import Images from '../components/images';
import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";

import RosterLoader from '../views/RosterLoader';

import { MyContext } from '../context';
let PriceRangData = [
    { label: 'All', value: '' },
    { label: 'Affordable', value: 'Affordable' },
    { label: "USD 13.5", value: 13.5 },
    { label: "USD 13", value: 13 },
    { label: "USD 12.5", value: 12.5 },
    { label: "USD 12", value: 12 },
    { label: "USD 11.5", value: 11.5 },
    { label: "USD 11", value: 11 },
    { label: "USD 10.5", value: 10.5 },
    { label: "USD 10", value: 10 },
    { label: "USD 9.5", value: 9.5 },
    { label: "USD 9", value: 9 },
    { label: "USD 8.5", value: 8.5 },
    { label: "USD 8", value: 8 },
    { label: "USD 7.5", value: 7.5 },
    { label: "USD 7", value: 7 },
    { label: "USD 6.5", value: 6.5 },
    { label: "USD 6", value: 6 },
    { label: "USD 5.5", value: 5.5 },
    { label: "USD 5", value: 5 },
    { label: "USD 4.5", value: 4.5 },
    { label: "USD 4", value: 4 },
    { label: "USD 3.5", value: 3.5 },
    { label: "USD 3", value: 3 },
]
let PriceRangDataAr = [
    { label: 'الكل', value: '' },
    { label: 'سعر مقبول', value: 'Affordable' },
    { label: "USD 13.5", value: 13.5 },
    { label: "USD 13", value: 13 },
    { label: "USD 12.5", value: 12.5 },
    { label: "USD 12", value: 12 },
    { label: "USD 11.5", value: 11.5 },
    { label: "USD 11", value: 11 },
    { label: "USD 10.5", value: 10.5 },
    { label: "USD 10", value: 10 },
    { label: "USD 9.5", value: 9.5 },
    { label: "USD 9", value: 9 },
    { label: "USD 8.5", value: 8.5 },
    { label: "USD 8", value: 8 },
    { label: "USD 7.5", value: 7.5 },
    { label: "USD 7", value: 7 },
    { label: "USD 6.5", value: 6.5 },
    { label: "USD 6", value: 6 },
    { label: "USD 5.5", value: 5.5 },
    { label: "USD 5", value: 5 },
    { label: "USD 4.5", value: 4.5 },
    { label: "USD 4", value: 4 },
    { label: "USD 3.5", value: 3.5 },
    { label: "USD 3", value: 3 },
]



export default class Roster extends React.Component {
    componentWillMount = (e)=>{

        console.log('===++=',this.props);
    }
    

    // Methods
    // ------------------------------

    // GET_ALL_ROSTER/GET_ROSTER_FOR_TRANSFER
    getAllRoster = (SelectedPlayerPosition, SelectedTeam, PlayerSearch, SelectedMinSalary, SelectedMaxSalary, RosterCurrentPage, SelectedSorting) => {
        this.setState({ rosterLoader: true });
        if (RosterCurrentPage == NC.CURRENT_PAGE) {
            this.setState({
                RosterCurrentPage: NC.CURRENT_PAGE
            });
        }
        let param = {
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "items_perpage": NC.ITEMS_PERPAGE,
            "current_page": RosterCurrentPage,
            "sort_order": "DESC",
            "sort_field": !_.isEmpty(SelectedSorting) ? SelectedSorting.value : '',
            "team_league_id": SelectedTeam != undefined ? SelectedTeam.team_league_id : "",
            "position": SelectedPlayerPosition != undefined ? SelectedPlayerPosition.position : "all",
            "min_salary": SelectedMinSalary,
            "max_salary": SelectedMaxSalary,
            "player_name": PlayerSearch || "",
           // "in_position_group" : 1,
            "lineup_master_id": this.props.isMakeTransferActive ? this.props.ManageTeamData.lineup_master_id : '',
            is_eliminate:"0"
        }


        let ROSTER_URL = this.props.isMakeTransferActive ? API.GET_ROSTER_FOR_TRANSFER : API.GET_ALL_ROSTER;
        

        WSManager.Rest(ROSTER_URL, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                var AllRoster = this.state.AllRoster;
                if (RosterCurrentPage == NC.CURRENT_PAGE) {
                    AllRoster = [];
                    this.setState({
                        AllRoster: AllRoster
                    });
                }
                if (this.props.isMakeTransferActive) {
                    _.map(responseJson.data.result, (item) => {
                        item.is_transfer_player = true;
                    })
                }
                this.setState({
                    IsAllPlayerRoster: true,
                    AllRoster: _.concat(AllRoster, responseJson.data.result),
                    allow_wildcard: responseJson.data.allow_wildcard,
                    totalRoster: responseJson.data.total
                });
                setTimeout(() => {
                    this.setState({ rosterLoader: false });
                }, 700);
            } else {
                setTimeout(() => {
                    this.setState({ rosterLoader: false });
                }, 700);
            }
        })
    }

    GetRosterByPosition = (SelectedPlayerPosition, SelectedTeam, PlayerSearch) => {
        let param = {
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "team_league_id": SelectedTeam.team_league_id || "",
            "position": SelectedPlayerPosition.position || "all",
            "min_salary": NC.MIN_SALARY,
            "max_salary": NC.MAX_SALARY,
            "player_name": PlayerSearch || "",
            "in_position_group" : 1,
            is_eliminate:"0"

        };

        WSManager.Rest(API.GET_ALL_ROSTER, param).then((response) => {

            this.setState({
                AllRosterArr: response.data.result,
            }, this.updateDimensions)
            setTimeout(() => {
                this.setState({
                    RosterPosting: false
                })
            }, 400)

        }).catch(error => {
            this.setState({
                RosterPosting: false
            })
        });
    }
    loadMoreRoster() {
        let RosterCurrentPage = this.state.RosterCurrentPage + 1;
        this.setState({ RosterCurrentPage: RosterCurrentPage });
        this.getAllRoster(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch, this.state.SelectedMinSalary, this.state.SelectedMaxSalary, RosterCurrentPage, this.state.SelectedSorting);
    }

    handleSelectChange = (type, e) => {
        const name = !type ? e.target.name : type;
        let value = !type ? e.target.value : e;
        // console.log("name", name + "      " + "value" + " " + JSON.stringify(value))

        this.setState({ [name]: value }, () => {
            const { SelectedPlayerPosition, SelectedTeam, SelectedSorting } = this.state;
            // console.log("SelectedPlayerPosition", JSON.stringify(SelectedPlayerPosition)
            //     + "      " + "SelectedTeam" + " " + JSON.stringify(SelectedTeam)
            //     + "      " + "SelectedSorting" + " " + JSON.stringify(SelectedSorting))

            if (SelectedPlayerPosition.label == 'All Players' && SelectedTeam.label == 'All Teams' && _.isEmpty(SelectedSorting)) {
                this.setState({
                    IsAllPlayerRoster: false,
                    AllRoster: [],
                    AllRosterArr: [],
                    hasMore: false,
                    RosterArrEmpty: false,
                    totalItemsCount: 0,
                    CurrentPage: 1
                }, () => this.GetRosterByPosition(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch));
            } else {
                if (name == "SelectedPlayerPosition" && value.label)
                    if (name == 'SelectedPrice') {
                        let SelectedPriceData = _.cloneDeep(value);

                        let filterLineup = _.filter(this.props.LineupsList, function (item) {
                            return item.player_uid
                        })
                        let remainingPlayer = NC.MAX_PLAYER - filterLineup.length == 0 ? 1 : NC.MAX_PLAYER - filterLineup.length
                        this.setState({
                            SelectedMinSalary: SelectedPriceData.label == 'Affordable' ? 3 : SelectedPriceData.value,
                            SelectedMaxSalary: SelectedPriceData.label == 'Affordable' ? ((this.props.availableBudget / remainingPlayer) || '') : SelectedPriceData.value,
                        });
                    }
                setTimeout(() => {
                    this.getAllRoster(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch, this.state.SelectedMinSalary, this.state.SelectedMaxSalary, NC.CURRENT_PAGE, this.state.SelectedSorting);
                }, 200);
            }


            // if(name == "SelectedPlayerPosition" && value.label)
            //     if (name == 'SelectedPrice') {
            //         let SelectedPriceData = _.cloneDeep(value);

            //         let filterLineup = _.filter(this.props.LineupsList, function (item) {
            //             return item.player_uid
            //         })
            //         let remainingPlayer = NC.MAX_PLAYER - filterLineup.length == 0 ? 1 : NC.MAX_PLAYER - filterLineup.length
            //         this.setState({
            //             SelectedMinSalary: SelectedPriceData.label == 'Affordable' ? 3 : SelectedPriceData.value,
            //             SelectedMaxSalary: SelectedPriceData.label == 'Affordable' ? ((this.props.availableBudget / remainingPlayer) || '') : SelectedPriceData.value,
            //         });
            //     }
            //     setTimeout(() => {
            //         this.getAllRoster(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch, this.state.SelectedMinSalary, this.state.SelectedMaxSalary, NC.CURRENT_PAGE, this.state.SelectedSorting);
            //     }, 200);
        });




    };
    handleSearchChange = (e) => {
        const val = e.target.value;
        this.setState({ PlayerSearch: val }, () => {
            this.getAllRoster(this.state.SelectedPlayerPosition, this.state.SelectedTeam, e.target.value, this.state.SelectedMinSalary, this.state.SelectedMaxSalary, NC.CURRENT_PAGE, this.state.SelectedSorting)
        })
    }
    handleRangeAfterChange = (Salary) => {
        this.setState({
            SelectedMinSalary: Salary[0],
            SelectedMaxSalary: Salary[1],
        });
        this.getAllRoster(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch, Salary[0], Salary[1], NC.CURRENT_PAGE, this.state.SelectedSorting)
    }
    // SelectedPositionCount = (data, val) => {
    //     var result = [];
    //     if (val.position == undefined) {
    //         result = data.filter(function (obj) {
    //             return obj.player_uid != undefined
    //         })
    //     } else {
    //         result = data.filter(function (obj) {
    //             return obj.position == val.position && obj.player_uid != undefined
    //         })
    //     }
    //     return result.length;
    // }
    // Lifecycle
    // ------------------------------
    constructor(props) {
        super(props);
        this.state = {
            // Flags
            isSearchable: false,
            filterShow: false,
            isLoading: false,
            isRtl: false,
            rosterLoader: false,
            AllRosterArr: [],
            IsAllPlayerRoster: false,
            FilterToggle: false,






            // Asyn data
            AllRoster: [],
            // ...Filter
            RosterCurrentPage: NC.CURRENT_PAGE,
            SelectedPlayerPosition: props.LineupMasterData.all_position[0],
            SelectedTeam: props.TeamList[0],
            PlayerSearch: "",
            SelectedMinSalary: NC.MIN_SALARY,
            SelectedMaxSalary: NC.MAX_SALARY,
            SelectedSorting: [],
            SelectedPrice: [],
            PriceRangData: props.language == 'ar' ? PriceRangDataAr : PriceRangData,
        }
    }
    componentDidMount() {
        //     this.getAllRoster(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch, this.state.SelectedMinSalary, this.state.SelectedMaxSalary, NC.CURRENT_PAGE, this.state.SelectedSorting);
            // this.GetRosterByPosition(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch)
        if (this.props.isMakeTransferActive)
        {
            this.getAllRoster(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch, this.state.SelectedMinSalary, this.state.SelectedMaxSalary, NC.CURRENT_PAGE, this.state.SelectedSorting);
        }
        else
        {
            this.GetRosterByPosition(this.state.SelectedPlayerPosition, this.state.SelectedTeam, this.state.PlayerSearch)
        }
    }
    filterToggle = () => {
        this.setState({ FilterToggle: !this.state.FilterToggle }, this.updateDimensions)
    }


    z
    ImageCallback = (idx) => {
        let AllRoster = this.state.AllRoster
        AllRoster[idx].player_image = Images.PLAYER_IMAGE
    }

    rosterFilterUpdate = (position) => {
        if (!position) return;
        console.log("position=", position);
        let AllPositionList = this.props.AllPositionList;
        let flt = _.filter(AllPositionList, (item) => {
            return item.position === position
        })
        console.log("flt===", flt);
        this.setState({
            selected_position: flt[0],
            CurrentPage: 1
        }, () => {
            this.getAllRoster()
        })
    }


    getPositionObj = (abbr) => {
        let AllPositionArr = this.props.AllPositionList;
        let fltr = _.filter(AllPositionArr, (item) => item.position_name == abbr)[0]
        return fltr ? {
            "position": fltr.position,
            "position_display_name": fltr.position_display_name,
            "max_player_per_position": fltr.max_player_per_position
        } : {
                "position": "--",
                "position_display_name": '--',
                "max_player_per_position": "--"
            }
    }

    SelectedPositionCount = (data, val) => {
        let result = _.filter(data, (o) => { return val.position != '' ? (o.position == val.position && o.player_uid) : o.player_uid != null })
        return result.length;
    }

    IsPlayerExist = (obj, list) => {
        var x;
        for (x in list) {
            if (list[x].player_uid == obj.player_uid) {
                return true;
            }
        }
        return false;
    }



    render() {
        const PlayerPosGroup = ({ position, max }) => {
            return (
                <Fragment>
                    {this.state.AllRosterArr && this.state.AllRosterArr[position] && <div className={`player-list height-auto ${position != "GK" ? 'm-t-16' : ''}`}>
                        <Row className="player-list-labels gutters-5px">
                            <Col className='label-player pointer ml10'
                                onClick={() => this.rosterFilterUpdate(position)}>{`${this.getPositionObj(position).position_display_name} (${_.isEmpty(this.state.AllRosterArr) ? '--' : this.SelectedPositionCount(this.state.LineupsList, this.getPositionObj(position))}/${this.getPositionObj(position).max_player_per_position})`}</Col>
                            <Col className='price-tag' title={'Price'}><Trans>Price</Trans></Col>
                            <Col className='price-tag-left' title={'Points'}><Trans>Points</Trans></Col>

                        </Row>
                    </div>}

                    <div className="player-list">
                        {
                            this.state.AllRosterArr && _.map(this.state.AllRosterArr[position], (item, idx) => {
                                return (
                                    idx < max &&
                                    <PlayerItemRow item={item} key={idx} is_group />
                                )
                            })
                        }
                    </div>
                </Fragment>
            )
        }

        const PlayerItemRow = ({ item, is_group = false }) => {
            return (
                <div className="player-item-row gutters-5px">
                    <Col className='player-name'>
                        <div className="player-picture">
                            <img src={item.jersey} alt="" className='thumb' />
                            <img src={item.flag} alt="" className='flag' />
                        </div>
                        <div className="overflow-hidden">

                            <b className="playername" title={item.full_name} onClick={() => playerCardModalInit('PlayerCardModalShow', item, true)}>

                                {item.full_name}
                            </b>
                            <small title={item.team_abbreviation}>{item.team_abbreviation}</small>
                        </div>
                    </Col>
                    <Col className='price-tag-c'><span className="align-middle ml10">${item.salary}</span></Col>
                    <Col className='price-tag-c'>
                        {
                            is_group ?
                                <span className="align-middle ml10 roster-score">{item.fantasy_score || '--'}</span>
                                :
                                <span className="align-middle ml10 ">
                                    {
                                        SelectedSorting.value != '' && SelectedSorting.value != 'salary' ? item[SelectedSorting.value] : item.fantasy_score
                                        // selected_sorting.value != '' && (selected_sorting.value == '' || selected_sorting.value == 'salary') ? item.fantasy_score : item[selected_sorting.value]
                                        // selected_sorting.value != '' ? item[selected_sorting.value] : item.fantasy_score
                                    }
                                </span>
                        }
                    </Col>
                    <Col className='action'>
                        {
                            this.IsPlayerExist(item, this.state.LineupsList) ?
                                <span className="remove-player-btn" onClick={() => RemovePlayerFromLineup(item)}></span>
                                //   <a className="player-action-toggle remove icon-check" onClick={() => RemovePlayerFromLineup(item)} />
                                :
                                <span className="add-player-btn" onClick={() => AddPlayerToLineup(item)}></span>
                            //<a className="player-action-toggle icon-plus margin-flot-right"  />
                        }
                        {/* {
                    Utils.IsPlayerExist(item, LineupList) ?
                      <a className="player-action-toggle remove icon-check" onClick={() => [this.RemovePlayer(item), is_drawer ? toggleDrawer() : null]} />
                      :
                      <a className="player-action-toggle icon-plus" onClick={() => [this.AddPlayer(item), is_drawer ? toggleDrawer() : null]} />
                  } */}
                    </Col>
                </div>
            )
        }

        


        const {
            // Flags
            isSearchable,
            isLoading,
            isRtl,
            filterShow,
            rosterLoader,

            // Asyn data
            AllRoster,
            totalRoster,
            //...Filters
            // RosterCurrentPage,
            SelectedPlayerPosition,
            SelectedTeam,
            // PlayerSearch,
            // SelectedMinSalary,
            // SelectedMaxSalary,
            SelectedSorting,
            // SelectedPrice

        } = this.state;

        const {
            // Flags
            apply_wildcard,
            // Asyn data
            TeamList,
            LineupMasterData,
            LineupsList,
            is_editable,
            AddPlayerToLineup,
            RemovePlayerFromLineup,
            rosterContainsObject,
            playerCardModalInit
        } = this.props;
        // <Range step={0.5}  min={Number(NC.MIN_SALARY)} max={Number(NC.MAX_SALARY)} defaultValue={[Number(NC.MIN_SALARY), Number(NC.MAX_SALARY)]} onAfterChange={this.handleRangeAfterChange} />


        // const RangeProps =  {
        //   //  reverse: i18n.language === 'ar',
        //     min:NC.MIN_SALARY,
        //     max:NC.MAX_SALARY,
        //     step: 0.5,
        //    defaultValue: [NC.MIN_SALARY,NC.MAX_SALARY],
        //     marks: {
        //       [Number(NC.MIN_SALARY)]: `$${Number(NC.MIN_SALARY)}`,
        //       [Number(NC.MAX_SALARY)]: `$${Number(NC.MAX_SALARY)}`
        //     },
        //     onAfterChange: this.handleRangeAfterChange
        //   } 
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="roster-wrapper">
                        <div className="roster-filter-wrapper">
                            <Row>
                                <Col xs={5}>
                                    <div className="field-label">{context.state.locale.Roster.add_players}</div>
                                    <Select
                                        className="basic-select-field"
                                        classNamePrefix="select"
                                        isLoading={isLoading}
                                        isRtl={isRtl}
                                        isSearchable={isSearchable}
                                        options={this.props.AllPositionList}
                                        value={SelectedPlayerPosition}
                                        onChange={event => this.handleSelectChange('SelectedPlayerPosition', event)}
                                    />
                                </Col>
                                <Col xs={5}>
                                    <div className="field-label no-padding-r">{context.state.locale.Roster.by_team}</div>
                                    <Select
                                        className="basic-select-field no-padding-r"
                                        classNamePrefix="select"
                                        placeholder={context.state.locale.Roster.select_placeholder}
                                        isLoading={isLoading}
                                        isRtl={isRtl}
                                        isSearchable={isSearchable}
                                        options={TeamList}
                                        value={SelectedTeam}
                                        onChange={event => this.handleSelectChange('SelectedTeam', event)}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <div className="field-label">
                                    </div>
                                    <span className={`roster-filter-btn icon-filter-alt ${filterShow ? 'atctive' : ''} `} onClick={() => this.setState({ filterShow: !filterShow })}></span>
                                </Col>
                            </Row>
                            <Row className={filterShow ? null : 'hide'}>
                                <Col xs={12}>
                                    <div className="field-label m-t-sm">{context.state.locale.Roster.sort_by}</div>
                                    <Select
                                        className="basic-select-field no-padding-r"
                                        classNamePrefix="select"
                                        placeholder={context.state.locale.Roster.select_placeholder}
                                        isLoading={isLoading}
                                        isRtl={isRtl}
                                        isSearchable={isSearchable}
                                        options={LineupMasterData.sort_by_list}
                                        value={SelectedSorting}
                                        onChange={event => this.handleSelectChange('SelectedSorting', event)}
                                    />
                                </Col>
                            </Row>

                            <Row className={filterShow ? null : 'hide'}>
                                {/* <Col xs={5}>
                                    <div className="field-label m-t-sm">{context.state.locale.Roster.price}</div>
                                    <Select
                                        className="basic-select-field"
                                        classNamePrefix="select"
                                        placeholder={context.state.locale.Roster.select_placeholder}
                                        isLoading={isLoading}
                                        isRtl={isRtl}
                                        isSearchable={isSearchable}
                                        options={this.props.language == 'ar' ? PriceRangDataAr : PriceRangData}
                                        value={SelectedPrice}
                                        onChange={event => this.handleSelectChange('SelectedPrice', event)}
                                    />
                                </Col> */}
                                <Col xs={7}>
                                    <div className="field-label m-t-sm no-padding-r">{context.state.locale.Roster.search}</div>
                                    <div className='pos-r'>
                                        <DebounceInput
                                            className='debounce-input'
                                            placeholder={context.state.locale.Roster.search}
                                            minLength={2}
                                            debounceTimeout={300}
                                            onChange={this.handleSearchChange}
                                        />
                                        <i className="icon-search"></i>
                                    </div>
                                </Col>
                            </Row>

                            {/* <Row className='gutters-5px price-range'>
                                            <Col>
                                                <div className="light-label"><Trans>Price</Trans></div>
                                                <div className="range-slider-wrap">
                                                    <Range {...RangeProps} />
                                                </div>
                                            </Col>
                                        </Row> */}
                            <Row className={filterShow ? null : 'hide'}>
                                <Col xs={12}>
                                    <div className="price-range">
                                        <div className="cell">
                                            <div className="pull-left">{context.state.locale.Roster.price}:</div>
                                            {/* <div className="pull-right">{NC.MIN_SALARY}{NC.SALARY_UNIT}</div> */}
                                        </div>
                                       
                                         <div className="cell slider">
                                            <Range step={0.5}  min={Number(NC.MIN_SALARY)} max={Number(NC.MAX_SALARY)} defaultValue={[Number(NC.MIN_SALARY), Number(NC.MAX_SALARY)]} onAfterChange={this.handleRangeAfterChange} />
                                        </div> 
                                        {/* <div className="cell last">
                                            {NC.MAX_SALARY}{NC.SALARY_UNIT}
                                        </div> */}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <header className="roster-header">
                            <span className="cell lg">{SelectedPlayerPosition.label} ({this.SelectedPositionCount(LineupsList, SelectedPlayerPosition)}/{SelectedPlayerPosition.max_player_per_position})</span>
                            <span className="cell">{context.state.locale.Roster.currency_label}</span>
                            <span className="cell">{context.state.locale.Roster.tp}</span>
                            <span className="cell"></span>
                        </header>
                        <div className={`roster-filter-loader fade ${rosterLoader ? 'in' : null}`}><RosterLoader /></div>
                        <ul className="roster-list scroll-view">
                            {
                                this.state.IsAllPlayerRoster ? _.map(AllRoster, (item, idx) => {
                                    return (
                                        <li className={`roster-item ${item.readonly == 1 && is_editable == 0 && apply_wildcard == false ? 'readonly' : ''}`} key={idx}>
                                            <div className="cell lg player-info-item">
                                                <img src={item.flag} alt="" className='club-flag' />
                                                <figure className="thumbnail">
                                                    <img src={item.player_image} alt="" onError={() => this.ImageCallback(idx)} />
                                                </figure>
                                                <div className="player-title">
                                                    <a title={item.full_name} onClick={() => playerCardModalInit('PlayerCardModalShow', item, true)}>{item.full_name}</a>
                                                </div>
                                                <div className="player-subtitle">{item.team_abbr_lang}</div>
                                            </div>
                                            <div className="cell link-color">{item.salary}{NC.SALARY_UNIT}</div>
                                            <div className="cell">{item.fantasy_score}</div>
                                            <div className="cell action">
                                                {
                                                    !rosterContainsObject(item, LineupsList) &&
                                                    <span className="add-player-btn" onClick={() => AddPlayerToLineup(item)}></span>
                                                }
                                                {
                                                    rosterContainsObject(item, LineupsList) &&
                                                    <span className="remove-player-btn" onClick={() => RemovePlayerFromLineup(item)}></span>
                                                }
                                            </div>
                                        </li>
                                    )
                                }) : <div className={`player-list-wrap ${this.state.FilterToggle ? 'filter-open' : ''}`}>
                                        {
                                            _.isEmpty(this.props.AllPositionList) ?
                                                ""
                                                :
                                                <Fragment>
                                                    <PlayerPosGroup position={"GK"} max={2} />
                                                    <PlayerPosGroup position={"DF"} max={5} />
                                                    <PlayerPosGroup position={"MF"} max={5} />
                                                    <PlayerPosGroup position={"FW"} max={3} />
                                                </Fragment>
                                        }


                                    </div>

                            }
                            {
                                this.state.IsAllPlayerRoster.length == 0 && !rosterLoader &&
                                <li className="roster-no-data">
                                    {context.state.locale.Roster.msz_no_roster_data}
                                </li>
                            }


                        </ul>
                        {
                            AllRoster.length > 0 && totalRoster != AllRoster.length &&
                            <ul className="roster-list roster-list-footer">
                                <li className="roster-list-footer">
                                    <span className='view-more' onClick={() => this.loadMoreRoster()}>
                                        {context.state.locale.Roster.view_more}
                                        <i className="icon-nextArrow"></i>
                                    </span>
                                </li>
                            </ul>
                        }
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}