/* eslint-disable eqeqeq */
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import Images from '../components/images';
import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import { MyContext } from '../context';
import Slider from "react-slick";
import { notify } from 'react-notify-toast';
import * as qs from 'query-string';

// import { Helmet } from "react-helmet";
import ReactGA from 'react-ga'; 
import AdsContainer from '../components/AdsContainer';
import SimpleImageSlider from "ac-react-simple-image-slider";
import Signup from '../Modals/signup';
import Login from '../Modals/login';

ReactGA.initialize('UA-177410945-1');
ReactGA.pageview(window.location.pathname + window.location.search);

var durationForBanner = 10;
var durationForBannerUpper = 8;

var settings = {
    rtl: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    centerPadding: '0px',
    initialSlide: 0,
};

export default class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstPrizeDetail: '',
            secondPrizeDetail: '',
            thirdPrizeDetail: '',
            fixtures: [],
            app_download_url: '',
            SignupModalShow: true,
            loginModalShow:false

        }
    }
    componentWillMount() {
        if (WSManager.getMultiLegData() != null && WSManager.getMultiLegData().league_id != undefined) {
            WSManager.Rest(API.GET_LIVE_FIXTURE, { league_id: WSManager.getMultiLegData().league_id }).then((responseJson) => {
                if (responseJson.response_code === NC.successCode) {
                    if (responseJson.data != null) {
                        this.setState({
                            fixtures: responseJson.data.match_list
                        })
                    }


                }
            })
        }


        const { location, history } = this.props;
        const parsed = qs.parse(location.search);
        if (!_.isEmpty(parsed) && !_.isUndefined(parsed.activation_key)) {
            WSManager.Rest(API.ACTIVATE_ACCOUNT, {
                "key": parsed.activation_key
            }).then((responseJson) => {
                if (responseJson.response_code == NC.successCode) {
                    notify.show(responseJson.message, "success", 1500);
                }
                history.push({ pathname: '/' });
            })
        }

        this.getAdvBanner();

    }


    modalShow = () => {
        this.setState({
            loginModalShow: true,
        });
    }
    modalShowSg = () => {
        this.setState({
            SignupModalShow: true,
        });
    }
    loggedInStatusUpdate = () => {
        this.setState({
            loggedInStatus: WSManager.loggedIn(),
            userInfo: WSManager.getProfile()
        })
        // if (this.state.join_code != '') {
        //     this.checkEligibility(this.state.join_code);
        // }
    }
    componentDidMount = (e) => {
        if (WSManager.getMultiLeg() === true) {
            this.props.history.push({ pathname: '/select-league' });
        }
    }


    
        modalHide = (mName) => {
            this.setState({ [mName]: false })
        }
    /**
     * ADVERSTIGMENT BANNER SERVICE
     */
    getAdvBanner = (e) => {

        let s3_param = [
            API.S3_GET_BANNER
        ]
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_BANNER, {}).then((responseJson) => {
 
            if (responseJson.response_code === NC.successCode) {
                WSManager.setAdBanner(responseJson.data);
                let bannerData = responseJson.data.advertisements;
                let Landing1 = [];
                let Landing2 = [];
                let Landing3 = [];
                for (let k = 0; k < bannerData.length; k++) {
                    if (bannerData[k].ad_position_id == 1) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        Landing1.push(bannerData[k])
                    }
                    if (bannerData[k].ad_position_id == 2) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        Landing2.push(bannerData[k])
                    }
                    if (bannerData[k].ad_position_id == 3) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        Landing3.push(bannerData[k])
                    }
                }
                this.setState({
                    advertisements: responseJson.data.advertisements,
                    advertisementsPosition: responseJson.data.positions,
                    // upperBanner: upperBanner,
                    // lowerBanner: lowerBanner,
                    // smallBanner:smallBanner
                    Landing1: Landing1,
                    Landing2: Landing2,
                    Landing3: Landing3,
                }, () => {
                    this.getBannerIndex();
                    this.getBannerIndexUpper();
                })
            }
        })
    }

    /**
     * BANNER CLICK FOR LOWER BANNER 
     */
    getBannerIndex = (e) => {
        for (let i = 0; i <= this.state.Landing1.length - 1; i++) {
            this.task(this.state.Landing1[i], i);
        }
    }
    task = (data, i) => {
        let a = this.state.Landing1.length - 1;
        setTimeout(function () {
            this.setState({
                currentBanner: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndex();
                }.bind(this), durationForBanner* 1000)

            }
        }.bind(this), durationForBanner * 1000 * i);

    }


    /**
  * BANNER CLICK FOR UPPER BANNER 
  */
    getBannerIndexUpper = (e) => {
        for (let i = 0; i <= this.state.Landing1.length - 1; i++) {
            this.taskUpper(this.state.Landing1[i], i);
        }
    }
    
    taskUpper = (data, i) => {
        let a = this.state.Landing1.length - 1;
        setTimeout(function () {
            this.setState({
                currentBannerUpper: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndexUpper();
                }.bind(this), durationForBannerUpper* 1000)

            }
        }.bind(this), durationForBannerUpper * 1000 * i);

    }

    /***
     * BANNER CLICK 
     */

    getBannerClick = (from) => {
        if (from == '1') {
            window.open(this.state.currentBannerUpper);
        }
        if (from == '2') {
            window.open(this.state.currentBanner);
        }

    }


    render() {

        const {
            // firstPrizeDetail,
            // secondPrizeDetail,
            // thirdPrizeDetail,
            // app_download_url,
            fixtures,
        } = this.state


        return (
            <MyContext.Consumer>
                {(context) => (
                    <div style = {{backgroundColor: '#000000'}}>
                       
                        <div id = {'Leaderboard'} className='sponsor-logo-bg container'>
                        {
                            this.state.Landing1  && <div className='sponsor-logo-bg image-ht' onClick={(e) => { this.getBannerClick('2') }} id="slider">
                                <SimpleImageSlider
                                    showArrows={false}
                                    showDots={false}
                                    // data={this.state.lowerBanner}
                                    data={this.state.Landing1 }
                                    duration={durationForBanner}
                                />
                            </div>
                            }
                        </div>
                        <AdsContainer style={{marginTop :'10px'}}/>
                        <div className='parallax-content stadium-block'>
                            {/* <a href="#" target="_blank" rel="noopener noreferrer"> <span style={context.state.language == 'en' ? { right: 30 } : { left: 30 }} className="store-btn"><img src={Images.APP_STORE} alt="" /></span></a>
                            <a href="#" target="_blank" rel="noopener noreferrer"><span style={context.state.language == 'en' ? { right: 30 } : { left: 30 }} className="store-btn google-play"><img src={Images.GOOGLE_PLAY} alt="" /></span></a> */}
                             {/* <span style={context.state.language == 'en' ? { right: 30 } : { left: 30 }} className="store-btn"><img src={Images.APP_STORE} alt="" /></span>
                            <span style={context.state.language == 'en' ? { right: 30 } : { left: 30 }} className="store-btn google-play"><img src={Images.GOOGLE_PLAY} alt="" /></span> */}
                            <div className="container">
                                <Row>
                                    <Col className="roster-container-list">
                                        <div className="roster-list-box" style={context.state.language == 'en' ? { left: -160 } : { right: -160 }} ></div>
                                    </Col>
                                    <Col className="field-container-list">
                                        <div className="hero-logo">
                                            <img src={context.state.language == 'en' ? Images.KUWAIT_LEAGUE_LOGO : Images.KUWAIT_LEAGUE_LOGO} alt="" className='logo-img' />
                                            <div className='is-line'></div>
                                            <div className="hero-logo-content">
                                                <div className="large-text-subtitle">{context.state.locale.LandingPage.League_sub_title_2}</div>
                                                <div className="large-text">{context.state.locale.LandingPage.League_title}</div>
                                                {/* <div className="large-text">{context.state.locale.LandingPage.Euro_cup_title}  {context.state.locale.LandingPage.And} {context.state.locale.LandingPage.Win}</div> */}
                                                {/* <div className="large-text-subtitle f-25">{context.state.locale.LandingPage.Euro_cup_title}</div> */}
                                            </div>
                                           
                                            { !context.state.loggedInStatus &&
                                                <div className={context.state.language == 'en'?"store-btn-big-play play-btn-landing" :"store-btn-big-play_ar play-btn-landing"} onClick={() => context.modalShow('LoginModalShow', { ...this.props })}>
                                                    {/* <img src={context.state.language == 'en'? Images.PLAY_BTN :Images.PLAY_BTN_AR} alt='' /> */}{context.state.locale.LandingPage.Play_Now}
                                                </div>
                                            }

                                        </div>
                                        <div className="player-field"></div>
                                    </Col>
                                </Row>
                            </div>
                            {
                                fixtures.length > 0 && <div className="fixture-view-shap">
                                    <Slider fade={context.state.language == 'en'} className="fixture-slide-home" ref={slider => (this.slider = slider)} {...settings}>
                                        {
                                            _.map(fixtures, (item, index) => {
                                                return (
                                                    <div key={index} style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div className="fixtures-vs" >
                                                            <img src={item.flag_home} width={28} height={28} alt="" />
                                                            <span style={{ marginLeft: 5, maxWidth: 40, overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.home}</span><span style={{ marginLeft: 5, marginRight: 5 }}>{'vs'}</span><span style={{ marginRight: 5, maxWidth: 40, overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.away}</span>
                                                            <img src={item.flag_away} width={28} height={28} alt="" />
                                                        </div>
                                                        <div style={{ borderBottom: '1px solid #eaeaea', marginRight: 24, marginLeft: 24, paddingBottom: 10, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                            <span className="live-status">Live</span>
                                                        </div>
                                                        <div className="fixtures-score-vs" >
                                                            <div>
                                                                <div>{item.home_team_goal}</div>
                                                                <div className="team-text">{item.home}</div>
                                                            </div>
                                                            <div>
                                                                <div>{item.away_team_goal}</div>
                                                                <div className="team-text">{item.away}</div>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '100%' }}>
                                                            <Button onClick={() => this.props.history.push('/fixtures')} bsStyle="primary" className='btn-style-home'>Scoreboard</Button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </div>
                            }
                        </div>
                        <div id = {'Leaderboard'} className='sponsor-logo-bg container mb50'>
                        {
                            this.state.Landing2 && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }} id="slider">
                                <SimpleImageSlider
                                    showArrows={false}
                                    showDots={false}
                                    // data={this.state.Landing1}
                                    data={this.state.Landing2}
                                    duration={durationForBanner}
                                />
                            </div>
                            }
                        </div>
                        {
                            fixtures.length > 0 && <div className="fixture-view-list">
                                <Slider slidesToScroll={2} slidesToShow={2} arrows={false} fade={false} {...settings}>
                                    {
                                        _.map(fixtures, (item, index) => {
                                            return (
                                                <div key={index} className="fixture-list-item">
                                                    <div style={{ float: 'left', display: 'inline-flex', marginTop: 8, marginLeft: '2%', alignItems: 'center' }}>
                                                        <img src={item.flag_home} style={{ marginLeft: 0, marginRight: 5, width: 28, height: 28 }} alt="" />
                                                        <span className="score-span">{item.home_team_goal}</span>
                                                        <span className="team-span" >{item.home}</span>
                                                        <span className="live-span">Live</span>
                                                        <span className="team-span"  >{item.away}</span>
                                                        <span className="score-span">{item.away_team_goal}</span>
                                                        <img src={item.flag_away} style={{ marginLeft: 5, marginRight: 5, width: 28, height: 28 }} alt="" />
                                                    </div>
                                                    <div style={{ display: 'inline-flex', float: 'right', marginTop: 5, marginBottom: 5, marginRight: '4%' }}>
                                                        <Button onClick={() => this.props.history.push('/fixtures')} bsStyle="primary" className='btn-style-home'>Scoreboard</Button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        }
                        <div className='how-to-play-container'>
                            <div className="container">
                                <div className="htp-text">{context.state.locale.LandingPage.How_To_Play}</div>
                                <Row>
                                    <Col md={4} className="ovel-shap-container">
                                        <img src={Images.OVEL_HOME} alt="" className='img-ovel' />
                                        <div className="jersy-logo-content">
                                            <img src={Images.W1_HOME} alt="" />
                                            <div className="ovel-text">{context.state.locale.LandingPage.Pick_Player}</div>
                                        </div>
                                    </Col>
                                    <Col md={4} className="ovel-shap-container">
                                        <img src={Images.OVEL_HOME} alt="" className='img-ovel' />
                                        <div className="jersy-logo-content">
                                            <img src={Images.W2_HOME} alt="" />
                                            <div className="ovel-text">{context.state.locale.LandingPage.Pick_Score}</div>
                                        </div>
                                    </Col>
                                    <Col md={4} className="ovel-shap-container">
                                        <img src={Images.OVEL_HOME} alt="" className='img-ovel' />
                                        <div className="jersy-logo-content">
                                            <img src={Images.W3_HOME} alt="" />
                                            <div className="ovel-text">{context.state.locale.LandingPage.Join_Screte}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className='landig-btm-banner'>
                        {
                                this.state.Landing3 && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }} id="slider">
                                    <SimpleImageSlider
                                        showArrows={false}
                                        showDots={false}
                                        data={this.state.Landing3}
                                        duration={durationForBanner}
                                    />
                                </div>
                            }
                            </div>
                        {/*                         
                        <div className='home-prize-container'>
                            <div className="container">
                                <Row>
                                    <Col sm={5} className="cash-prize">
                                        <img src={context.state.language == 'en' ? Images.COINS_HOME : Images.COINS_HOME_ARABIC} alt="" />
                                    </Col>
                                    <Col sm={7} className="winners-rank">
                                        <div className="img-first">
                                            <img src={Images.FIRSTPRIZE_LARGE} alt="" style={{ maxWidth: '100%' }} />
                                            <div className="prize-detail">
                                                <div className="sar">{context.state.locale.Dashboard.usd_currency_label}</div>
                                                <div className="money"><span style={{ position: 'absolute', left: '-12%', top: 0, fontSize: '60%' }}>{context.state.locale.Dashboard.usd_currency_label == 'USD' ? '$' : ''}</span>{firstPrizeDetail.prize_money}</div>
                                            </div>
                                        </div>
                                        <div className="img-second">
                                            <img src={Images.SECONDPRIZE_LARGE} alt="" style={{ maxWidth: '100%' }} />
                                            <div className="prize-detail">
                                                <div className="sar">{context.state.locale.Dashboard.usd_currency_label}</div>
                                                <div className="money"><span style={{ position: 'absolute', left: '-12%', top: 0, fontSize: '60%' }}>{context.state.locale.Dashboard.usd_currency_label == 'USD' ? '$' : ''}</span>{secondPrizeDetail.prize_money}</div>
                                            </div>
                                        </div>
                                        <div className="img-third">
                                            <img src={Images.THIRDPRIZE_LARGE} alt="" style={{ maxWidth: '100%' }} />
                                            <div className="prize-detail">
                                                <div className="sar">{context.state.locale.Dashboard.usd_currency_label}</div>
                                                <div className="money"><span style={{ position: 'absolute', left: '-12%', top: 0, fontSize: '60%' }}>{context.state.locale.Dashboard.usd_currency_label == 'USD' ? '$' : ''}</span>{thirdPrizeDetail.prize_money}</div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="strip-div">
                                <img src={Images.STRIP_HOME} alt="" className='img-strip' />
                                <div className="strip-content">
                                    <div className="small-text col-xs-6">{context.state.locale.LandingPage.Winner_Announce}</div>
                                    <div className="date-text col-xs-6">{context.state.locale.LandingPage.coming_soon}</div>
                                </div>
                            </div>
                        </div>
                         */}
                        {/* <div className='download-container'>
                            <div className="container">
                                <div className="download-text">{context.state.locale.LandingPage.Download_App}</div>
                                <div className="download-small-text">{context.state.locale.LandingPage.Download_Subtitle}</div>
                                <img src={Images.MOBILE_FIELD} alt="" className="mobile_field" />
                            </div>
                            <div className="store-btn-big">
                                <a href="" rel="noopener noreferrer" target="_blank"><img src={Images.APP_STORE_BIG} alt="" /></a>
                                <a href="" rel="noopener noreferrer" target="_blank"><img src={Images.PLAY_STORE_BIG} alt="" /></a>
                                <img src={Images.APP_STORE_BIG} alt="" />
                                <img src={Images.PLAY_STORE_BIG} alt="" />
                            </div>
                        </div> */}
                        {!localStorage.getItem('auth') && <Signup SignupModalShow={this.state.SignupModalShow} modalHide={()=>this.modalHide('SignupModalShow')} modalShow={this.modalShow}/>}
                        {!localStorage.getItem('auth') && <Login LoginModalShow={this.state.loginModalShow}  modalHide={()=>this.modalHide('loginModalShow')} modalShow={this.modalShowSg} loggedInStatusUpdate={this.loggedInStatusUpdate}/>}
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}