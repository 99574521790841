import config from '../config';




export const SocialLoginDisable = false;
export const deviceType = "3";
export const deviceID = "";
export const leagueId = "111";
export const sportsId = "5";
export const SALARY_UNIT = "";

export const successCode = 200;
export const verifyError = 500;
export const AUTHENTICATE_REQUIRE_CODE = 401;
export const sessionExpireCode = 401;
export const ITEMS_PERPAGE = 8;
export const ITEMS_PERPAGE_LG = 20;
export const CURRENT_PAGE = 1;
export const TOTAL_SALARY = 100;
export const MIN_SALARY = 3;
export const MAX_SALARY = 18;
export const MAX_PLAYER = 15;
export const DEFAULT_FORMATION = "4-4-2";

export const sessionKey = "";
export const IS_CLUB_TEAM = true;
export const IS_FILTER_TEAM = true;

export const CURRENT_OFFSET = 0;
export const BENCH_PLAYERS = 'Bench Players';

export const GK = "2";
export const DF = "5";
export const MF = "5";
export const FW = "3";

export var GOALKEEPER = "GK";
export var DEFENDER = "DF";
export var MIDFIELDER = "MF";
export var FORWARDER = "FW";

export var DISPLAYNAME_GOALKEEPER = "GK";
export var DISPLAYNAME_DEFENDER = "DF";
export var DISPLAYNAME_MIDFIELDER = "MF";
export var DISPLAYNAME_FORWARDER = "FW";

export var DISPLAYNAME_GOALKEEPER_FULL = "Goalkeepers";
export var DISPLAYNAME_DEFENDER_FULL = "Defenders";
export var DISPLAYNAME_MIDFIELDER_FULL = "Midfielders";
export var DISPLAYNAME_FORWARDER_FULL = "Forwards";

// Message(s)
export var MSZ_MAX_PLAYER_PER_TEAM = "Maximum players per team selected";
export var MSZ_LINEUP_FULL = "Maximum players in the lineup are selected";
export var MSZ_MAX_POSITION_SELECTED = "Maximum position in the lineup are filled";
export var MSZ_BUDGET_INSUFFICIENT = "Budget is insufficient";
export var MSZ_AUTHENTICATE_USER = "Please login or create a new account";
export var MSZ_CREATED_TEAM = "Your team has been updated";
export var MSZ_COPY_LINK = "Link has been copied!";
export var MSZ_COPY_CODE = "Code has been copied!";

export var CREATE_LEAGUE_WARN = "Minimum value cannot exceed maximum value";
export var MSZ_TEAM_COUNT = "Please enter correct value for team";
export var MSZ_TEAM_COUNT_MAX = "Minimum value cannot exceed maximum value";
export var MSZ_NO_ROSTER_DATA = "Oops... There's nothing 'ere yet.";
export var MSZ_NO_LEAGUE_CODE = "Please enter league code.";

export var Locale = "";
export class Language {
    static update(data) {
        Locale = data;
        DISPLAYNAME_GOALKEEPER_FULL = data.message.DISPLAYNAME_GOALKEEPER_FULL;
        DISPLAYNAME_DEFENDER_FULL = data.message.DISPLAYNAME_DEFENDER_FULL;
        DISPLAYNAME_MIDFIELDER_FULL = data.message.DISPLAYNAME_MIDFIELDER_FULL;
        DISPLAYNAME_FORWARDER_FULL = data.message.DISPLAYNAME_FORWARDER_FULL;
        
        MSZ_MAX_PLAYER_PER_TEAM = data.message.MSZ_MAX_PLAYER_PER_TEAM;
        MSZ_LINEUP_FULL = data.message.MSZ_LINEUP_FULL;
        MSZ_MAX_POSITION_SELECTED = data.message.MSZ_MAX_POSITION_SELECTED;
        MSZ_BUDGET_INSUFFICIENT = data.message.MSZ_BUDGET_INSUFFICIENT;
        MSZ_AUTHENTICATE_USER = data.message.MSZ_AUTHENTICATE_USER;
        MSZ_CREATED_TEAM = data.message.MSZ_CREATED_TEAM;
        MSZ_COPY_LINK = data.message.MSZ_COPY_LINK;
        MSZ_COPY_CODE = data.message.MSZ_COPY_CODE;

        CREATE_LEAGUE_WARN = data.message.CREATE_LEAGUE_WARN;
        MSZ_TEAM_COUNT = data.message.MSZ_TEAM_COUNT;
        MSZ_TEAM_COUNT_MAX = data.message.MSZ_TEAM_COUNT_MAX;
        MSZ_NO_ROSTER_DATA = data.message.MSZ_NO_ROSTER_DATA;
    }
}

// Social
// export const FB_APP_ID = "1726484474278929"; // Local App ID
export const GPLUS_ID = config.cognito.GOOGLE_CLIENT_ID;
export const FB_APP_ID = config.cognito.FB_APP_ID;


//App download urls
export const PLAY_STORE_URL = config.apiGateway.URL;
export const APP_STORE_URL = config.apiGateway.URL;
