/* eslint-disable jsx-a11y/anchor-is-valid, no-unreachable */
import React, { Component } from 'react';
import SocialButton from './SocialButton';
import config from '../../config'
// import { notify } from 'react-notify-toast';

class SocialLogin extends Component {
  constructor (props) {
    super(props)
    this.nodes = {}
    this.state = {
      posting: false,
      logged: false,
      currentProvider: ''
    }
  }

  setNodeRef (provider, node) {
    if (node) {
      this.nodes[ provider ] = node
    }
  }
  
  logout = () => {
    const { logged, currentProvider } = this.state
    if (logged && currentProvider) {
      this.nodes[currentProvider].props.triggerLogout()
    }
  }
  onLogoutSuccess = () => {
    this.setState({
      logged: false,
      currentProvider: ''
    })
  }
  onLogoutFailure = (err) => {
    // console.error(err)
  }
  handleSocialLogin = (user) => {
    this.setState({
      logged: true,
      currentProvider: user._provider
    }, () => this.props.onFacebookSuccess(user))
  }

  handleSocialLoginFailure = (err) => {
    this.setState({
      logged: false,
      currentProvider: ''
    })
  }

  render() {
    return (
        <SocialButton
          provider='facebook'
          appId={config.cognito.FB_APP_ID}
          onLoginSuccess={this.handleSocialLogin}
          onLoginFailure={this.handleSocialLoginFailure}
          onLogoutSuccess={this.onLogoutSuccess}
          className='login_fb'
          getInstance={this.setNodeRef.bind(this, 'facebook')}
        />
    )
  }
}

SocialLogin.propTypes = {}

export default SocialLogin
