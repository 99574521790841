export const config = {
  apiKey: "AIzaSyDvdJTRO-zGlOFUeCmfeYCAk788VZmCWPs",
  authDomain: "kooora-fantasy.firebaseapp.com",
  databaseURL: "https://kooora-fantasy.firebaseio.com",
  projectId: "kooora-fantasy",
  storageBucket: "kooora-fantasy.appspot.com",
  messagingSenderId: "962625101419",
  appId: "1:962625101419:web:b9bdd10a191c35e0381b2e",
  measurementId: "G-MZFRHTML4Q"
  };


// export const config = {
//   apiKey: "AIzaSyDd35DpgEQ7KDfTTSPYrsp3J4edkAIbpd8",
//   authDomain: "al-harrif-3d529.firebaseapp.com",
//   databaseURL: "https://al-harrif-3d529.firebaseio.com",
//   projectId: "al-harrif-3d529",
//   storageBucket: "al-harrif-3d529.appspot.com",
//   messagingSenderId: "603113924409",
//   appId: "1:603113924409:web:f833fbdf938667ec45aae8",
//   measurementId: "G-ZB8KYMWKPC"
//   };